import React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="Capa_1"
      enableBackground="new 0 0 512 512"
      height="512"
      viewBox="0 0 512 512"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <g>
          <path
            d="m399.057 504.5h-286.114c-13.972 0-25.298-11.326-25.298-25.298v-386.4c0-13.972 11.326-25.298 25.298-25.298h286.114c13.972 0 25.298 11.326 25.298 25.298v386.4c0 13.972-11.326 25.298-25.298 25.298z"
            fill="#8d9ca8"
          />
        </g>
        <g>
          <path
            d="m424.36 92.8v386.4c0 13.97-11.33 25.3-25.3 25.3h-20c13.97 0 25.3-11.33 25.3-25.3v-386.4c0-13.97-11.33-25.3-25.3-25.3h20c13.97 0 25.3 11.33 25.3 25.3z"
            fill="#7a8c98"
          />
        </g>
        <g>
          <path
            d="m164.162 472.216h223.325c2.299 0 4.164-1.85 4.164-4.131v-364.167c0-2.282-1.864-4.131-4.164-4.131h-262.974c-2.299 0-4.164 1.85-4.164 4.131v326.086z"
            fill="#f9f3f1"
          />
        </g>
        <g>
          <path
            d="m391.65 103.92v364.16c0 2.29-1.86 4.14-4.16 4.14h-20c2.3 0 4.16-1.85 4.16-4.14v-364.16c0-2.28-1.86-4.13-4.16-4.13h20c2.3 0 4.16 1.85 4.16 4.13z"
            fill="#efe2dd"
          />
        </g>
        <g>
          <path
            d="m164.162 472.216-.102-37.046c-.008-2.917-2.398-5.276-5.338-5.268l-38.373.102z"
            fill="#cdbfba"
          />
        </g>
        <g>
          <path
            d="m278.349 33.304v-3.629c0-12.247-10.006-22.175-22.349-22.175-12.343 0-22.349 9.928-22.349 22.175v3.628c0 4.242-3.465 7.68-7.74 7.68h-12.702c-10.299 0-18.649 8.284-18.649 18.504v10.801c0 2.951 2.411 5.344 5.386 5.344h112.109c2.974 0 5.386-2.393 5.386-5.344v-10.801c0-10.219-8.349-18.504-18.649-18.504h-12.702c-4.276.001-7.741-3.438-7.741-7.679z"
            fill="#b6c4cf"
          />
        </g>
        <g>
          <path
            d="m281.32 39.34c-1.8-.52-3.71-.8-5.69-.8h-13.91c-4.68 0-8.48-3.76-8.48-8.41v-3.97c0-6.42-2.52-12.27-6.63-16.61 2.85-1.32 6.04-2.05 9.39-2.05 12.34 0 22.35 9.93 22.35 22.18v3.62c0 2.46 1.16 4.64 2.97 6.04z"
            fill="#9facba"
          />
        </g>
        <g>
          <path
            d="m317.44 59.49v10.8c0 2.95-2.41 5.34-5.39 5.34h-18.82c1.7-1.02 2.83-2.88 2.83-4.99v-11.83c0-7.74-4.37-14.47-10.8-17.87.27.03.55.04.83.04h12.7c10.3 0 18.65 8.29 18.65 18.51z"
            fill="#9facba"
          />
        </g>
        <g>
          <g>
            <path
              d="m301.54 179.225h-21.081c-4.15 0-7.514-3.338-7.514-7.455v-20.918c0-4.117-3.364-7.455-7.514-7.455h-18.862c-4.15 0-7.514 3.338-7.514 7.455v20.918c0 4.117-3.364 7.455-7.514 7.455h-21.081c-4.15 0-7.514 3.338-7.514 7.455v18.715c0 4.117 3.364 7.455 7.514 7.455h21.081c4.15 0 7.514 3.338 7.514 7.455v20.918c0 4.117 3.364 7.455 7.514 7.455h18.862c4.15 0 7.514-3.338 7.514-7.455v-20.918c0-4.118 3.364-7.455 7.514-7.455h21.081c4.15 0 7.514-3.338 7.514-7.455v-18.715c0-4.118-3.364-7.455-7.514-7.455z"
              fill="#ee6161"
            />
          </g>
          <g>
            <path
              d="m260.46 212.85h20c-4.15 0-7.52 3.34-7.52 7.46v20.91c0 4.12-3.36 7.46-7.51 7.46h-18.86c-.19 0-.38-.01-.57-.03 3.88-.28 6.94-3.5 6.94-7.43v-20.91c0-4.12 3.37-7.46 7.52-7.46z"
              fill="#e94444"
            />
          </g>
          <g>
            <path
              d="m280.46 179.22h-20c-4.15 0-7.52-3.33-7.52-7.45v-20.92c0-3.93-3.06-7.14-6.94-7.42.19-.02.38-.03.57-.03h18.86c4.15 0 7.51 3.33 7.51 7.45v20.92c0 4.12 3.37 7.45 7.52 7.45z"
              fill="#e94444"
            />
          </g>
          <g>
            <path
              d="m309.05 186.68v18.72c0 4.11-3.36 7.45-7.51 7.45h-20c4.15 0 7.51-3.34 7.51-7.45v-18.72c0-4.12-3.36-7.46-7.51-7.46h20c4.15 0 7.51 3.34 7.51 7.46z"
              fill="#e94444"
            />
          </g>
        </g>
        <g>
          <path d="m120.349 296.765c4.143 0 7.5-3.357 7.5-7.5v-181.978h193.606c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5h-196.942c-6.432 0-11.664 5.218-11.664 11.631v185.347c0 4.142 3.358 7.5 7.5 7.5z" />
          <g>
            <path d="m387.488 92.287h-30.973c-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5h27.636v357.429h-212.509l-.082-29.566c-.019-7.036-5.768-12.747-12.823-12.747h-.034l-30.854.082v-98.117c0-4.143-3.357-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v105.637c0 2.037.829 3.987 2.296 5.401l43.813 42.212c1.398 1.347 3.264 2.099 5.204 2.099h223.325c6.431 0 11.663-5.218 11.663-11.631v-364.168c.001-6.413-5.232-11.631-11.662-11.631zm-248.595 345.168 17.673-.047.048 17.12z" />
            <path d="m399.057 60.003h-74.117v-.516c0-14.339-11.73-26.004-26.148-26.004h-12.702c-.156 0-.24-.104-.24-.18v-3.628c-.001-16.362-13.391-29.675-29.85-29.675s-29.85 13.313-29.85 29.676v3.628c0 .075-.083.18-.24.18h-12.702c-14.418 0-26.148 11.665-26.148 26.004v.516h-74.117c-18.085 0-32.798 14.713-32.798 32.798v386.4c0 18.085 14.713 32.799 32.798 32.799h286.114c18.085 0 32.798-14.714 32.798-32.799v-386.4c0-18.086-14.713-32.799-32.798-32.799zm-196.997-.516c0-6.067 5.001-11.004 11.148-11.004h12.702c8.403 0 15.24-6.81 15.24-15.18v-3.628c.001-8.091 6.662-14.675 14.85-14.675s14.849 6.584 14.849 14.676v3.628c0 8.37 6.837 15.18 15.24 15.18h12.702c6.147 0 11.148 4.937 11.148 11.004v8.645h-107.879zm214.795 419.714c0 9.814-7.984 17.799-17.798 17.799h-286.114c-9.813 0-17.798-7.984-17.798-17.799v-386.4c0-9.813 7.984-17.798 17.798-17.798h75.029c1.891 4.754 6.54 8.129 11.974 8.129h112.108c5.435 0 10.083-3.375 11.975-8.129h75.028c9.813 0 17.798 7.984 17.798 17.798z" />
            <path d="m210.46 220.351 21.095-.045v20.918c0 8.246 6.735 14.955 15.014 14.955h18.862c8.278 0 15.014-6.709 15.014-14.955l.014-20.873h21.081c8.279 0 15.015-6.709 15.015-14.955v-18.716c0-8.246-6.735-14.955-15.015-14.955l-21.095.045v-20.918c0-8.246-6.735-14.955-15.014-14.955h-18.862c-8.278 0-15.014 6.709-15.014 14.955l-.014 20.873h-21.081c-8.278 0-15.014 6.709-15.014 14.955v18.716c.001 8.246 6.736 14.955 15.014 14.955zm0-33.626h21.081c8.278 0 15.014-6.709 15.014-14.955l.014-20.873 18.876-.045v20.918c0 8.246 6.735 14.955 15.014 14.955h21.081l.015-.045-.015 18.671h-21.081c-8.278 0-15.014 6.709-15.014 14.955l-.014 20.873-18.876.045v-20.918c0-8.246-6.735-14.955-15.014-14.955l-21.095.045z" />
            <g>
              <path d="m309.051 312.46h45.318c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5h-45.318c-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5z" />
              <path d="m157.626 312.46h113.693c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5h-113.693c-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5z" />
              <path d="m354.369 336.756h-196.743c-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5h196.743c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5z" />
              <path d="m317.652 376.053h-160.026c-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5h160.026c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
