import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    height={512}
    width={512}
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M444.029 159.517H67.971c-14.457 0-26.177 11.72-26.177 26.177v171.194h428.41V185.694c.001-14.457-11.718-26.177-26.175-26.177z"
      fill="#ff9a00"
    />
    <path
      d="M108 7.502c-18.445 0-33.397 14.953-33.397 33.397V471.1c0 18.445 14.953 33.397 33.397 33.397h296c18.445 0 33.397-14.953 33.397-33.397V110.487c0-6.71-2.666-13.146-7.41-17.89l-77.684-77.684a25.3 25.3 0 0 0-17.89-7.41H108z"
      fill="#f5f5f5"
    />
    <path
      d="m429.987 92.597-51.494-51.494-.183 86.377c-.012 5.422 4.381 9.824 9.803 9.824 5.414 0 9.803 4.389 9.803 9.803V471.1c0 18.445-14.953 33.398-33.397 33.398H404c18.445 0 33.397-14.953 33.397-33.397V110.487a25.3 25.3 0 0 0-7.41-17.89z"
      fill="#eaeaea"
    />
    <path
      d="m429.987 92.597-77.684-77.684a25.28 25.28 0 0 0-7.41-5.131V66.61c0 18.445 14.953 33.398 33.397 33.398h56.829a25.295 25.295 0 0 0-5.132-7.411z"
      fill="#a8d0d5"
    />
    <path
      d="m191.89 255.521-6.904-15.625a26.176 26.176 0 0 0-23.944-15.597H67.971c-14.457 0-26.177 11.72-26.177 26.177v227.846c0 14.457 11.72 26.177 26.177 26.177h376.057c14.457 0 26.177-11.72 26.177-26.177V297.295c0-14.457-11.72-26.177-26.177-26.177H215.833a26.176 26.176 0 0 1-23.943-15.597z"
      fill="#ffb541"
    />
    <g fill="#ffa812">
      <path d="m145.157 239.896 6.904 15.625a26.176 26.176 0 0 0 23.944 15.597h39.827a26.178 26.178 0 0 1-23.942-15.597l-6.904-15.625a26.176 26.176 0 0 0-23.944-15.597h-39.827a26.178 26.178 0 0 1 23.942 15.597zM444.029 271.118h-39.827c14.456.001 26.174 11.72 26.174 26.177v181.027c0 14.457-11.72 26.177-26.177 26.177h39.829c14.457 0 26.177-11.72 26.177-26.177V297.295c0-14.457-11.719-26.177-26.176-26.177z" />
    </g>
    <path d="M195.203 62.48h-74.185c-4.143 0-7.502-3.358-7.502-7.502s3.359-7.502 7.502-7.502h74.185c4.143 0 7.502 3.358 7.502 7.502s-3.359 7.502-7.502 7.502zM195.203 107.593h-74.185c-4.143 0-7.502-3.358-7.502-7.502s3.359-7.502 7.502-7.502h74.185c4.143 0 7.502 3.358 7.502 7.502s-3.359 7.502-7.502 7.502z" />
    <path d="M444.899 152.038v-41.55c0-8.762-3.412-16.999-9.608-23.196L357.607 9.609C351.411 3.412 343.174 0 334.412 0H108C85.448 0 67.101 18.347 67.101 40.9v111.138c-18.168.465-32.808 15.378-32.808 33.656v292.627c0 18.571 15.108 33.679 33.678 33.679h376.058c18.57 0 33.678-15.108 33.678-33.679V185.694c0-18.278-14.64-33.192-32.808-33.656zm-25.613-59.533H378.29c-14.279 0-25.896-11.616-25.896-25.895V25.614zM67.101 167.063v22.163c0 4.144 3.359 7.502 7.502 7.502s7.502-3.358 7.502-7.502V40.9c0-14.279 11.617-25.896 25.895-25.896h226.412c1.01 0 2.002.101 2.978.265V66.61c0 22.551 18.348 40.899 40.9 40.899h51.341c.164.977.265 1.969.265 2.979v125.557c0 4.144 3.359 7.502 7.502 7.502s7.502-3.358 7.502-7.502v-68.982c9.892.459 17.804 8.627 17.804 18.631v83.591a33.475 33.475 0 0 0-18.674-5.668H215.833a18.684 18.684 0 0 1-17.082-11.127c-3.97-5.812-8.655-35.692-37.709-35.692H67.971a33.483 33.483 0 0 0-18.674 5.668v-36.772c0-10.004 7.912-18.171 17.804-18.631zm395.602 311.258c0 10.297-8.378 18.675-18.674 18.675H67.971c-10.297 0-18.674-8.378-18.674-18.675V250.475c0-10.297 8.378-18.674 18.674-18.674h93.071c17.403 0 19.288 19.181 23.985 26.753a33.696 33.696 0 0 0 30.806 20.066h228.195c10.297 0 18.674 8.378 18.674 18.675v181.026z" />
  </svg>
);

export default SvgComponent;
