import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={20} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M1.25 0a.75.75 0 100 1.5h13.5a.75.75 0 100-1.5H1.25zm6.74 2.5a.75.75 0 00-.52.22L4.72 5.47a.75.75 0 101.06 1.06l1.47-1.47v9.88l-1.47-1.47a.75.75 0 10-1.06 1.06l2.75 2.75a.75.75 0 001.06 0l2.75-2.75a.748.748 0 00-.236-1.235.75.75 0 00-.824.175l-1.47 1.47V5.06l1.47 1.47a.75.75 0 101.06-1.06L8.53 2.72a.75.75 0 00-.54-.22zM1.25 3a.75.75 0 100 1.5.75.75 0 000-1.5zm13.5 0a.75.75 0 100 1.5.75.75 0 000-1.5zM1.25 5.5a.75.75 0 100 1.5.75.75 0 000-1.5zm13.5 0a.75.75 0 100 1.5.75.75 0 000-1.5zM1.25 8a.75.75 0 100 1.5.75.75 0 000-1.5zm13.5 0a.75.75 0 100 1.5.75.75 0 000-1.5zm-13.5 2.5a.75.75 0 100 1.5.75.75 0 000-1.5zm13.5 0a.75.75 0 100 1.5.75.75 0 000-1.5zM1.25 13a.75.75 0 100 1.5.75.75 0 000-1.5zm13.5 0a.75.75 0 100 1.5.75.75 0 000-1.5zm-13.5 2.5a.75.75 0 100 1.5.75.75 0 000-1.5zm13.5 0a.75.75 0 100 1.5.75.75 0 000-1.5zm-13.5 3a.751.751 0 100 1.5h13.5a.751.751 0 100-1.5H1.25z" />
    </svg>
  );
}

export default SvgComponent;
