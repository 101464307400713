import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import {
  CreditCard,
  CreditCardForm,
  InvoiceView,
  Payment,
  PaymentMethod,
  ValidateVoucherBy,
  VoucherValidateStatus,
  InvoiceVoucher,
} from "@udok/lib/api/models";
import {
  fetchCreditCards,
  fetchCreditCard,
  deleteCreditCard,
  createCreditCard,
  createPayment,
  PaymentForm,
  fetchPaymentMethods,
  fetchPayment,
  createInvoiceVoucher,
  voucherVerification,
  refundPayment,
} from "@udok/lib/api/billing";
import { getToken, UNAUTHORIZED } from "./auth";
// @ts-ignore
import pagarme from "pagarme";
import moment from "moment";
import { format } from "@udok/lib/internal/util";
import { getUserMe } from "./user";

export type InitialState = {
  creditCardByID: { [crcaID: string]: CreditCard | undefined };
  invoicesByID: { [invoID: string]: InvoiceView | undefined };
  paymentsByID: { [paymID: string]: Payment | undefined };
  paymentMethods: PaymentMethod[];
};

// Reducers
const initialState: InitialState = {
  creditCardByID: {},
  invoicesByID: {},
  paymentsByID: {},
  paymentMethods: [],
};

class CreditCardSlice extends Hen<InitialState> {
  creditCardLoaded(v: CreditCard) {
    this.state.creditCardByID[String(v.crcaID)] = v;
  }
  creditCardsLoaded(v: CreditCard[]) {
    v.forEach((s) => {
      this.state.creditCardByID[String(s.crcaID)] = s;
    });
  }
  creditCardRemoved(v: CreditCard) {
    delete this.state.creditCardByID[String(v.crcaID)];
  }

  invoicesLoaded(v: InvoiceView[]) {
    v.forEach((s) => {
      this.state.invoicesByID[s.invoID] = s;
    });
  }
  invoicePaid(invoID: string, p: Payment) {
    const curr = this.state.invoicesByID[invoID];
    if (!curr) {
      return;
    }
    this.state.invoicesByID[invoID] = {
      ...curr,
      paymID: p.paymID,
      paymentStatus: p.status,
      paymentMethodName: p.methodName,
    };
    this.state.paymentsByID[p.paymID] = p;
  }
  paymentMethodsLoaded(p: PaymentMethod[]) {
    this.state.paymentMethods = p;
  }
  paymentLoaded(p: Payment) {
    this.state.paymentsByID[p.paymID] = p;
  }
  invoiceVoucherLoaded(i: InvoiceVoucher, code: string) {
    const invoID = i.invoID;
    const curr = this.state.invoicesByID[invoID];
    if (!curr) {
      return;
    }
    const newInvoice = {
      ...curr,
      voucherCode: code,
    };
    this.state.invoicesByID[invoID] = newInvoice;
  }
  invoiceRefunded(invoID: string, paymID: string, status: string) {
    const curr = this.state.invoicesByID[invoID];
    if (!curr) {
      return;
    }
    this.state.invoicesByID[invoID] = {
      ...curr,
      paymID,
      paymentStatus: status,
    };
  }
}

export const [Reducer, actions] = hen(new CreditCardSlice(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
const mainSelector = (state: RootState) => state.billing;
export const invoicesByIDSelector = (state: RootState) =>
  state.billing.invoicesByID;

export const creditCardsView = createSelector(mainSelector, (state) => {
  return {
    list: (Object.keys(state.creditCardByID)
      .map((crcaID) => state.creditCardByID[crcaID])
      .filter((cc) => !!cc) ?? []) as CreditCard[],
  };
});

export const invoicePaymentView = createSelector([getUserMe], (profile) => {
  return {
    customerData: {
      name: profile.currentUser?.patient?.name,
      email: profile.currentUser?.patient?.email,
      phones: profile.currentUser?.patient?.info?.phones,
      cpf: profile.currentUser?.patient?.cpf,
      dob: profile.currentUser?.patient?.dateOfBirth,
      address: {
        cep: profile.currentUser?.patient?.info?.address?.cep,
        city: profile.currentUser?.patient?.info?.address?.city,
        street: profile.currentUser?.patient?.info?.address?.street,
        streetNumber: profile.currentUser?.patient?.info?.address?.streetNumber,
        neighborhood: profile.currentUser?.patient?.info?.address?.neighborhood,
        state: profile.currentUser?.patient?.info?.address?.state,
      },
    },
  };
});

export const invoiceView = (state: RootState, props: { invoID: string }) =>
  createSelector([mainSelector, invoicePaymentView], (state, payment) => {
    return {
      invoice: state.invoicesByID[props.invoID],
      customerData: payment.customerData,
    };
  });

export const paymentMethodsView = createSelector([mainSelector], (state) => {
  return {
    list: state.paymentMethods,
  };
});

export const paymentView = (state: RootState, props: { paymID: string }) =>
  createSelector([mainSelector], (state) => {
    return {
      payment: state.paymentsByID[props.paymID],
    };
  });

export const oneCreditCardView = (
  state: RootState,
  props: { crcaID: string }
) =>
  createSelector(mainSelector, (state) => {
    return {
      creditCard: state.creditCardByID[props.crcaID],
    };
  });

// Actions
export function loadCreditCards(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchCreditCards(apiToken)
      .then(async (r) => {
        dispatch(actions.creditCardsLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadOneCreditCard(crcaID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchCreditCard(apiToken, crcaID)
      .then(async (r) => {
        dispatch(actions.creditCardLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function createOneCreditCard(
  creditCard: CreditCardForm
): AppThunk<Promise<CreditCard>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    const card = {
      card_number: creditCard.number,
      card_holder_name: creditCard.name,
      card_expiration_date: creditCard.expiry,
      card_cvv: creditCard.cvv,
    };

    let hash = "";
    const errMsg = "Erro ao registrar cartão no gateway";
    try {
      hash = await pagarme.client
        .connect({ encryption_key: process.env.REACT_APP_PAGARME_CRYPTO_KEY })
        .then((client: any) => client.security.encrypt(card));
    } catch (e) {
      dispatch(
        newNotification("general", {
          status: "error",
          message: errMsg,
        })
      );
      throw new Error(errMsg);
    }
    if (!hash) {
      newNotification("general", {
        status: "error",
        message: errMsg,
      });
      throw new Error(errMsg);
    }

    const cc = {
      name: creditCard.number.replace(/\s/g, "").slice(-4),
      brand: creditCard.brand,
      provider: "pagarme",
      providerCard: hash,
      expiresAt: moment(creditCard.expiry, format.MONYEAR).format(
        format.RFC3349
      ),
      billingAddress: creditCard.billingAddress,
    };

    return createCreditCard(apiToken, cc)
      .then((r) => {
        dispatch(actions.creditCardLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function removeOneCreditCard(crcaID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return deleteCreditCard(apiToken, crcaID)
      .then((r) => {
        dispatch(actions.creditCardRemoved(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function payInvoice(d: PaymentForm): AppThunk<Promise<Payment>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createPayment(apiToken, d)
      .then((r) => {
        dispatch(actions.invoicePaid(d.invoID, r));
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadPaymentMethods(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchPaymentMethods(apiToken)
      .then(async (r) => {
        dispatch(actions.paymentMethodsLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function loadPayment(paymID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchPayment(apiToken, paymID)
      .then(async (r) => {
        dispatch(actions.paymentLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function fetchCachedPayment(paymID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const paymentExist = Boolean(state.billing.paymentsByID[paymID]);
    if (paymentExist) {
      return Promise.resolve();
    }
    return dispatch(loadPayment(paymID));
  };
}

export function createOneInvoiceVoucher(
  invoID: string,
  voucherCode: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createInvoiceVoucher(apiToken, invoID, voucherCode)
      .then((r) => {
        dispatch(actions.invoiceVoucherLoaded(r, voucherCode));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Cupom aplicado com sucesso!",
          })
        );
      })
      .catch((e) => {
        let message: string = e.message;
        if (message?.indexOf?.("invalid cupom")) {
          message = "Cupom inválido";
        }
        dispatch(
          newNotification("general", {
            status: "error",
            message: message,
          })
        );
        throw e;
      });
  };
}

export function validateVoucher(
  code: string,
  checkBy: ValidateVoucherBy
): AppThunk<Promise<VoucherValidateStatus>> {
  return async (dispatch, getState) => {
    return voucherVerification(code, checkBy)
      .then((r) => {
        return r;
      })
      .catch((e) => {
        return e;
      });
  };
}

export function refundInvoicePayment(
  paymID: string
): AppThunk<Promise<Payment>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return refundPayment(apiToken, paymID)
      .then((r) => {
        dispatch(actions.invoiceRefunded(r.invoID, r.paymID, r.status));
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}
