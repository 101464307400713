import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 49 49"
      width={49}
      height={49}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7.573.125C3.483.125.125 3.484.125 7.573v33.854c0 4.09 3.359 7.448 7.448 7.448h33.854c4.09 0 7.448-3.359 7.448-7.448V7.573c0-4.09-3.359-7.448-7.448-7.448H7.573zm0 4.063h33.854a3.356 3.356 0 013.386 3.385v27.7l-8.141-7.877-.003-.003a5.492 5.492 0 00-3.809-1.539 5.485 5.485 0 00-3.808 1.542l-2.775 2.685-8.368-8.096a5.483 5.483 0 00-3.811-1.547 5.493 5.493 0 00-3.812 1.547l-6.098 5.9V7.573a3.356 3.356 0 013.385-3.386zm25.73 5.416c-1.863 0-3.517.772-4.566 1.952-1.05 1.18-1.529 2.675-1.529 4.142 0 1.467.48 2.961 1.53 4.142 1.048 1.18 2.702 1.952 4.564 1.952s3.516-.772 4.565-1.952c1.05-1.18 1.529-2.675 1.529-4.142 0-1.467-.48-2.961-1.529-4.142-1.05-1.18-2.703-1.952-4.565-1.952zm0 4.063c.845 0 1.223.244 1.528.587.305.343.502.88.502 1.444s-.197 1.101-.502 1.444c-.305.343-.683.587-1.529.587-.846 0-1.224-.244-1.529-.587-.305-.343-.502-.88-.502-1.444s.197-1.101.502-1.444c.305-.343.683-.587 1.53-.587zM14.097 24.5a1.393 1.393 0 01.986.405l8.27 8-12.308 11.907H7.573a3.356 3.356 0 01-3.386-3.385v-7.89l8.924-8.632a1.4 1.4 0 01.987-.405zm18.762 5.417c.36 0 .703.125.987.399l10.965 10.609v.502a3.356 3.356 0 01-3.385 3.386H16.891l14.986-14.497c.28-.272.627-.4.983-.4z" />
    </svg>
  );
}

export default SvgComponent;
