import React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="368 115 512 440" width="512pt" height="440pt" {...props}>
      <defs>
        <clipPath id="_clipPath_Jq9GFlPL4gIjyoPziVh1Gd2n1t0zrnRc">
          <rect x="368" y="115" width="512" height="440" />
        </clipPath>
      </defs>
      <g clip-path="url(#_clipPath_Jq9GFlPL4gIjyoPziVh1Gd2n1t0zrnRc)">
        <path
          d=" M 880 195 L 608 195 L 608 235 L 880 235 L 880 195 Z  M 880 435 L 608 435 L 608 475 L 880 475 L 880 435 Z "
          fill="rgb(0,0,0)"
        />
        <g>
          <path
            d=" M 509 315 L 428 315 C 394.914 315 368 288.086 368 255 L 368 175 C 368 141.914 394.914 115 428 115 L 509 115 C 542.086 115 569 141.914 569 175 L 569 255 C 569 288.086 542.086 315 509 315 Z  M 428 155 C 416.973 155 408 163.973 408 175 L 408 255 C 408 266.027 416.973 275 428 275 L 509 275 C 520.027 275 529 266.027 529 255 L 529 175 C 529 163.973 520.027 155 509 155 L 428 155 Z "
            fill="rgb(0,0,0)"
          />
          <path
            d=" M 517 186.251 L 499.964 170.994 L 457.108 226.199 L 435.755 207.879 L 420 224.451 L 458.428 259.006 L 517 186.251 Z "
            fill="rgb(0,0,0)"
          />
        </g>
        <g>
          <path
            d=" M 509 555 L 428 555 C 394.914 555 368 528.086 368 495 L 368 415 C 368 381.914 394.914 355 428 355 L 509 355 C 542.086 355 569 381.914 569 415 L 569 495 C 569 528.086 542.086 555 509 555 Z  M 428 395 C 416.973 395 408 403.973 408 415 L 408 495 C 408 506.027 416.973 515 428 515 L 509 515 C 520.027 515 529 506.027 529 495 L 529 415 C 529 403.973 520.027 395 509 395 L 428 395 Z "
            fill="rgb(0,0,0)"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
