import React from "react";
import { Route, Switch } from "react-router";
import {
  PasswordReset,
  PasswordResetChoose,
  SignoutContainer,
  PasswordResetSucess,
  TokenLogin,
  OnboardingQueryParams,
  ImmediateCareOnboardingQueryParams,
  UserOnboardingQueryParams,
} from "screens/onboarding";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "@udok/lib/themes/paperbase";
import Admin from "screens/admin";
import Notification from "containers/Notification";

const GeneralNotifications = Notification("general");

export default () => {
  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <Route exact path="/password_reset" component={PasswordReset} />
        <Route
          exact
          path="/password_reset/:reset_id/:verification"
          component={PasswordResetChoose}
        />
        <Route
          exact
          path="/password_reset_sucess"
          component={PasswordResetSucess}
        />
        <Route exact path="/signout" component={SignoutContainer} />
        <Route exact path="/token_login" component={TokenLogin} />
        <Route
          path="/onboarding/appointment/:sescID/:markedAt"
          component={OnboardingQueryParams}
        />
        <Route
          path="/onboarding/immediate-care/:imcsID"
          component={ImmediateCareOnboardingQueryParams}
        />
        <Route path="/onboarding" component={UserOnboardingQueryParams} />
        <Route path="/" component={Admin} />
      </Switch>
      <GeneralNotifications />
    </ThemeProvider>
  );
};
