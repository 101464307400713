import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ isolation: "isolate" }}
    viewBox="0 0 512 512"
    width="2em"
    height="2em"
    {...props}
  >
    <g clipPath="url(#a)">
      <circle
        vectorEffect="non-scaling-stroke"
        cx={256}
        cy={256}
        r={256}
        fill="#FFE564"
      />
      <path
        d="M512 256c0-44.291-11.251-85.953-31.046-122.29l-35.035-35.035-195.294 68.473 11.97 11.97-2.972 8.196-77.696-77.696-60.635 60.635 69.892 69.891-115.357 62.542 26.235 26.235-22.067 94.399 61.872 61.872C176.232 502.339 214.985 512 256 512c141.385 0 256-114.615 256-256Z"
        fill="#FFC033"
      />
      <path
        d="M151.927 140.253h-30.635v30h30.635v30.635h16l19.439-45.635-19.439-45.635h-16v30.635Z"
        fill="#554C63"
      />
      <path
        d="M181.927 200.888v-30.635h30.636v-30h-30.636v-30.635h-14v91.27h14Z"
        fill="#373247"
      />
      <path
        d="M442.737 169.473v85.655c0 16.617-13.519 30.137-30.137 30.137H275.73c-16.617 0-30.137-13.52-30.137-30.137v-85.655c0-1.861.748-3.638 2.072-4.939l80.876-79.489c4.306-4.233 9.965-6.352 15.624-6.352 5.659 0 11.318 2.119 15.625 6.352l80.874 79.489a6.914 6.914 0 0 1 2.073 4.939Z"
        fill="#EFEFEF"
      />
      <path
        d="M442.737 169.473v85.655c0 16.617-13.519 30.137-30.137 30.137h-68.435V78.693c5.659 0 11.318 2.119 15.625 6.352l80.874 79.489a6.914 6.914 0 0 1 2.073 4.939Z"
        fill="#E5E5E9"
      />
      <path
        d="M460.347 183.892a6.919 6.919 0 0 1-4.939 2.068 6.89 6.89 0 0 1-4.851-1.985L350.083 85.229a8.414 8.414 0 0 0-5.918-2.405 8.412 8.412 0 0 0-5.917 2.405l-100.474 98.746c-2.724 2.682-7.109 2.641-9.79-.083a6.928 6.928 0 0 1 .087-9.794l100.47-98.747c4.306-4.232 9.965-6.351 15.624-6.351 5.659 0 11.318 2.119 15.625 6.351l100.469 98.747a6.93 6.93 0 0 1 .088 9.794Z"
        fill="#6E6E6E"
      />
      <path
        d="M391.801 184.225v27.635a4.614 4.614 0 0 1-4.616 4.615h-24.584v24.589a4.618 4.618 0 0 1-4.616 4.616H330.35a4.618 4.618 0 0 1-4.616-4.616v-24.589h-24.589a4.614 4.614 0 0 1-4.616-4.615v-27.635a4.615 4.615 0 0 1 4.616-4.616h24.589V155.02a4.617 4.617 0 0 1 4.616-4.615h27.635a4.617 4.617 0 0 1 4.616 4.615v24.589h24.584a4.615 4.615 0 0 1 4.616 4.616Z"
        fill="#FF6769"
      />
      <path
        d="M391.801 184.225v27.635a4.614 4.614 0 0 1-4.616 4.615h-24.584v24.589a4.618 4.618 0 0 1-4.616 4.616h-13.82v-95.275h13.82a4.617 4.617 0 0 1 4.616 4.615v24.589h24.584a4.615 4.615 0 0 1 4.616 4.616Z"
        fill="#FF0C6C"
      />
      <path
        d="M460.347 183.892a6.919 6.919 0 0 1-4.939 2.068 6.89 6.89 0 0 1-4.851-1.985L350.083 85.229a8.414 8.414 0 0 0-5.918-2.405V69c5.659 0 11.318 2.119 15.625 6.351l100.469 98.747a6.93 6.93 0 0 1 .088 9.794Z"
        fill="#5A5A5A"
      />
      <path
        d="M272.768 324.977v91.2c0 17.693-14.395 32.088-32.088 32.088H94.949c-17.692 0-32.088-14.395-32.088-32.088v-91.2c0-1.981.796-3.873 2.207-5.259l86.111-84.635c4.585-4.506 10.61-6.762 16.636-6.762 6.025 0 12.05 2.256 16.636 6.762l86.11 84.635a7.368 7.368 0 0 1 2.207 5.259Z"
        fill="#EFEFEF"
      />
      <path
        d="M272.768 324.977v91.2c0 17.693-14.395 32.088-32.088 32.088h-72.865V228.321c6.025 0 12.05 2.256 16.636 6.762l86.11 84.635a7.368 7.368 0 0 1 2.207 5.259Z"
        fill="#E5E5E9"
      />
      <path
        d="M291.517 340.33a7.367 7.367 0 0 1-5.258 2.202 7.339 7.339 0 0 1-5.166-2.114L174.115 235.28a8.958 8.958 0 0 0-6.3-2.561 8.958 8.958 0 0 0-6.301 2.561L54.536 340.418c-2.9 2.856-7.569 2.812-10.424-.088a7.38 7.38 0 0 1 .093-10.429l106.974-105.138c4.585-4.507 10.61-6.763 16.636-6.763 6.025 0 12.05 2.256 16.636 6.763l106.973 105.138a7.38 7.38 0 0 1 .093 10.429Z"
        fill="#6E6E6E"
      />
      <path
        d="M218.534 340.684v29.424a4.913 4.913 0 0 1-4.915 4.914h-26.175v26.181a4.917 4.917 0 0 1-4.915 4.914h-29.424a4.917 4.917 0 0 1-4.915-4.914v-26.181h-26.18a4.913 4.913 0 0 1-4.915-4.914v-29.424a4.914 4.914 0 0 1 4.915-4.915h26.18v-26.18a4.917 4.917 0 0 1 4.915-4.915h29.424a4.917 4.917 0 0 1 4.915 4.915v26.18h26.176a4.913 4.913 0 0 1 4.914 4.915Z"
        fill="#FF6769"
      />
      <path
        d="M218.534 340.684v29.424a4.913 4.913 0 0 1-4.915 4.914h-26.175v26.181a4.917 4.917 0 0 1-4.915 4.914h-14.714V304.674h14.714a4.917 4.917 0 0 1 4.915 4.915v26.18h26.175a4.914 4.914 0 0 1 4.915 4.915Z"
        fill="#FF0C6C"
      />
      <path
        d="M291.517 340.33a7.367 7.367 0 0 1-5.258 2.202 7.339 7.339 0 0 1-5.166-2.114L174.115 235.28a8.958 8.958 0 0 0-6.3-2.561V218c6.025 0 12.05 2.256 16.636 6.763l106.973 105.138a7.38 7.38 0 0 1 .093 10.429Z"
        fill="#5A5A5A"
      />
    </g>
  </svg>
);

export default SvgComponent;
