import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={16} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M.738.49A.75.75 0 000 1.25v13.5a.75.75 0 101.5 0V1.25A.75.75 0 00.738.49zm18.5 0a.75.75 0 00-.738.76v13.5a.75.75 0 101.5 0V1.25a.75.75 0 00-.762-.76zM3.75.5a.75.75 0 100 1.5.75.75 0 000-1.5zm2.5 0a.75.75 0 100 1.5.75.75 0 000-1.5zm2.5 0a.75.75 0 100 1.5.75.75 0 000-1.5zm2.5 0a.75.75 0 100 1.5.75.75 0 000-1.5zm2.5 0a.75.75 0 100 1.5.75.75 0 000-1.5zm2.5 0a.75.75 0 100 1.5.75.75 0 000-1.5zM5.993 4.49a.75.75 0 00-.523.23L2.72 7.47a.75.75 0 000 1.06l2.75 2.75a.75.75 0 101.06-1.06L5.06 8.75h9.88l-1.47 1.47a.75.75 0 101.06 1.06l2.75-2.75a.75.75 0 000-1.06l-2.75-2.75a.751.751 0 10-1.06 1.06l1.47 1.47H5.06l1.47-1.47a.75.75 0 00-.537-1.29zM3.75 14a.75.75 0 100 1.5.75.75 0 000-1.5zm2.5 0a.75.75 0 100 1.5.75.75 0 000-1.5zm2.5 0a.75.75 0 100 1.5.75.75 0 000-1.5zm2.5 0a.75.75 0 100 1.5.75.75 0 000-1.5zm2.5 0a.75.75 0 100 1.5.75.75 0 000-1.5zm2.5 0a.75.75 0 100 1.5.75.75 0 000-1.5z" />
    </svg>
  );
}

export default SvgComponent;
