import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      isolation: "isolate",
    }}
    viewBox="0 0 512 512"
    width="512pt"
    height="512pt"
    {...props}
  >
    <g>
      <path
        d="M279.169 134.646c12.834-3.734 19.466-16.545 31.246-22.105 47.244-20.352 71.719 38.822 109.804 49.527 17.124 4.534 32.81 1.555 50.119 8.928 21.156 9.009 32.087 32.982 31.789 56.769-1.352 21.965-18.905 36.168-28.878 54.915-11.834 18.878-7.549 27.141-1.582 46.697 10.501 31.608 5.077 65.566-30.893 72.781-40.554 8.48-62.094-20.469-96.649-34.266-44.107-16.947-94.339-13.507-136.936 6.528-59.893 26.92-173.982 48.451-194.944-42.34-7.834-33.691 3.685-57.297 34.623-69.878 11.844-4.615 24.108-11.568 30.794-23.434 9.231-15.845-1.7-39.817-3.756-56.792-13.16-114.143 154.968-30.577 208.043-47.524"
        fill="red"
        opacity={0.2}
      />
      <path
        d="M398.096 368.837c0 3.915-29.298 5.049-32.467 5.049H146.37c-3.165 0-32.467-1.134-32.467-5.049l26.735-40.079c0-3.915 2.567-7.088 5.727-7.088h219.26c3.164 0 5.732 3.173 5.732 7.088l26.734 40.079h.005Z"
        fill="#B3B3B3"
      />
      <g fill="#3E3E3D">
        <path d="M210.914 359.909h-56.353l9.859-23.869h50.969l-4.475 23.869ZM296.614 336.045h50.965l9.863 23.868h-56.353l-4.475-23.868ZM352.343 336.045h14.968l16.237 23.868h-19.845l-11.364-23.868h.004ZM148.3 359.909h-19.845l16.238-23.869h14.967l-11.36 23.869ZM296.135 359.909h-80.267l4.476-23.869h71.316l4.475 23.869Z" />
      </g>
      <g fill="#B3B3B3">
        <path d="M378.653 344.051H133.355v-3.653h245.298v3.653ZM381.311 353.001H130.692v-3.652h250.619v3.652Z" />
      </g>
      <path
        d="M399.596 378.122c0 3.164-.655 5.728-3.815 5.728H116.222c-3.164 0-3.82-2.564-3.82-5.728v-4.914c0-3.164.651-5.727 3.82-5.727h279.559c3.16 0 3.815 2.563 3.815 5.727v4.914Z"
        fill="#3E3E3D"
      />
      <path
        d="M308.399 375.803a2.983 2.983 0 0 1-2.983 2.984h-98.833a2.983 2.983 0 0 1 0-5.967h98.833a2.986 2.986 0 0 1 2.983 2.983Z"
        fill="#6F6F6F"
      />
      <path
        d="M374.227 323.351a5.73 5.73 0 0 1-5.732 5.732H143.508a5.734 5.734 0 0 1-5.732-5.732V176.57a5.73 5.73 0 0 1 5.732-5.732h224.987a5.734 5.734 0 0 1 5.732 5.732v146.781Z"
        fill="#3E3E3D"
      />
      <path
        d="M256.002 178.812c3.078 0 3.078-4.774 0-4.774-3.079 0-3.079 4.774 0 4.774ZM357.673 314.125a4.927 4.927 0 0 1-4.927 4.927H159.253a4.927 4.927 0 0 1-4.927-4.927V187.898a4.93 4.93 0 0 1 4.927-4.927h193.493a4.93 4.93 0 0 1 4.927 4.927v126.227Z"
        fill="#FFF"
      />
      <mask id="b" x="-200%" y="-200%" width="400%" height="400%">
        <rect
          x="-200%"
          y="-200%"
          width="400%"
          height="400%"
          style={{
            fill: "#fff",
          }}
        />
        <path d="m226.401 234.134-16.879 16.875" />
      </mask>
      <path
        mask="url(#b)"
        vectorEffect="non-scaling-stroke"
        strokeWidth={8.951}
        stroke="#425973"
        strokeLinejoin="round"
        strokeLinecap="round"
        d="m226.401 234.134-16.879 16.875"
      />
      <mask id="c" x="-200%" y="-200%" width="400%" height="400%">
        <rect
          x="-200%"
          y="-200%"
          width="400%"
          height="400%"
          style={{
            fill: "#fff",
          }}
        />
        <path d="m226.401 251.014-16.879-16.88" />
      </mask>
      <path
        mask="url(#c)"
        vectorEffect="non-scaling-stroke"
        strokeWidth={8.951}
        stroke="#425973"
        strokeLinejoin="round"
        strokeLinecap="round"
        d="m226.401 251.014-16.879-16.88"
      />
      <mask id="d" x="-200%" y="-200%" width="400%" height="400%">
        <rect
          x="-200%"
          y="-200%"
          width="400%"
          height="400%"
          style={{
            fill: "#fff",
          }}
        />
        <path d="m302.482 234.134-16.88 16.875" />
      </mask>
      <path
        mask="url(#d)"
        vectorEffect="non-scaling-stroke"
        strokeWidth={8.951}
        stroke="#425973"
        strokeLinejoin="round"
        strokeLinecap="round"
        d="m302.482 234.134-16.88 16.875"
      />
      <mask id="e" x="-200%" y="-200%" width="400%" height="400%">
        <rect
          x="-200%"
          y="-200%"
          width="400%"
          height="400%"
          style={{
            fill: "#fff",
          }}
        />
        <path d="m302.482 251.014-16.875-16.88" />
      </mask>
      <path
        mask="url(#e)"
        vectorEffect="non-scaling-stroke"
        strokeWidth={8.951}
        stroke="#425973"
        strokeLinejoin="round"
        strokeLinecap="round"
        d="m302.482 251.014-16.875-16.88"
      />
      <mask id="f" x="-200%" y="-200%" width="400%" height="400%">
        <rect
          x="-200%"
          y="-200%"
          width="400%"
          height="400%"
          style={{
            fill: "#fff",
          }}
        />
        <path d="M205.426 271.361h101.151" />
      </mask>
      <path
        mask="url(#f)"
        vectorEffect="non-scaling-stroke"
        strokeWidth={7.504}
        stroke="#425973"
        strokeLinejoin="round"
        strokeLinecap="round"
        d="M205.426 271.361h101.151"
      />
      <path
        d="M188.872 268.892c9.619 0 9.619-14.917 0-14.917-9.62 0-9.62 14.917 0 14.917ZM323.132 268.892c9.619 0 9.619-14.917 0-14.917-9.62 0-9.62 14.917 0 14.917Z"
        fill="#E9C2D0"
      />
      <path
        d="M343.27 151.291v49.228c0 4.81-7.458 4.81-7.458 0v-49.228c0-4.81 7.458-4.81 7.458 0ZM339.541 219.537c7.215 0 7.215-11.188 0-11.188s-7.215 11.188 0 11.188Z"
        fill="#DB9022"
      />
    </g>
  </svg>
);

export default SvgComponent;
