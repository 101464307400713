import React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="512pt"
      viewBox="0 -36 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m100 200c-55.140625 0-100-44.859375-100-100s44.859375-100 100-100 100 44.859375 100 100-44.859375 100-100 100zm0-160c-33.085938 0-60 26.914062-60 60s26.914062 60 60 60 60-26.914062 60-60-26.914062-60-60-60zm412 40h-272v40h272zm-412 0c-11.027344 0-20 8.972656-20 20s8.972656 20 20 20 20-8.972656 20-20-8.972656-20-20-20zm0 360c-55.140625 0-100-44.859375-100-100s44.859375-100 100-100 100 44.859375 100 100-44.859375 100-100 100zm0-160c-33.085938 0-60 26.914062-60 60s26.914062 60 60 60 60-26.914062 60-60-26.914062-60-60-60zm412 40h-272v40h272zm0 0" />
    </svg>
  );
}

export default SvgComponent;
