import React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 512 512" {...props}>
      <path d="M87.992 188.795c27.469 0 49.82-22.351 49.82-49.815 0-27.469-22.351-49.815-49.82-49.815s-49.818 22.346-49.818 49.815c.001 27.463 22.349 49.815 49.818 49.815zm0-82.225c17.874 0 32.412 14.538 32.412 32.407 0 17.869-14.538 32.407-32.412 32.407-17.871 0-32.41-14.538-32.41-32.407.001-17.869 14.539-32.407 32.41-32.407zM79.288 388.475h17.408v123.341H79.288z" />
      <path d="M27.761 237.202c6.679-6.674 15.552-10.35 24.991-10.35h75.837v-17.408H52.751c-14.088 0-27.333 5.486-37.299 15.452C5.486 234.862 0 248.107 0 262.198v111.488h35.236v138.12h17.408V356.278H17.408v-94.08c0-9.442 3.676-18.315 10.353-24.996z" />
      <path d="M79.288 245.217h17.408v19.351H79.288zM123.333 511.805h17.411V373.686h26.532v-17.408h-43.943zM424.008 188.795c27.469 0 49.82-22.351 49.82-49.815 0-27.469-22.351-49.815-49.82-49.815s-49.815 22.346-49.815 49.815c0 27.463 22.348 49.815 49.815 49.815zm0-82.225c17.869 0 32.412 14.538 32.412 32.407 0 17.869-14.543 32.407-32.412 32.407s-32.407-14.538-32.407-32.407c0-17.869 14.538-32.407 32.407-32.407zM415.309 388.475h17.408v123.341h-17.408z" />
      <path d="M496.553 224.899c-9.966-9.966-23.212-15.452-37.302-15.452h-75.84v17.408h75.837c9.441 0 18.317 3.674 24.991 10.35 6.674 6.679 10.353 15.552 10.353 24.993v94.08h-35.238v155.528h17.408V373.688H512V262.2c0-14.09-5.484-27.335-15.447-37.301z" />
      <path d="M415.309 245.217h17.408v19.351h-17.408zM344.724 373.686h26.532v138.119h17.408V356.278h-43.94zM256 119.634c32.932 0 59.725-26.793 59.725-59.727C315.725 26.978 288.932.185 256 .185s-59.725 26.793-59.725 59.72c0 32.936 26.793 59.729 59.725 59.729zm0-102.042c23.332 0 42.317 18.98 42.317 42.312 0 23.337-18.985 42.322-42.317 42.322s-42.317-18.985-42.317-42.322c0-23.332 18.985-42.312 42.317-42.312zM247.296 358.728h17.408v153.065h-17.408z" />
      <path d="M363.1 338.291V202.038c0-16.924-6.592-32.837-18.56-44.813-11.971-11.968-27.884-18.557-44.808-18.557h-12.521L256 169.882l-31.214-31.214h-12.521c-16.924 0-32.837 6.589-44.805 18.557-11.968 11.976-18.56 27.889-18.56 44.813v136.253h43.74v173.524h17.408V300.436H192.64v20.447h-26.33V202.038c0-12.278 4.78-23.818 13.461-32.504 8.681-8.678 20.224-13.458 32.497-13.458h5.312L256 194.499l38.426-38.423h5.309c12.275 0 23.816 4.78 32.497 13.458 8.681 8.683 13.463 20.227 13.463 32.504v118.845H319.36v-20.447h-17.408v211.379h17.408V338.291h43.74z" />
      <path d="M247.296 209.326h17.408v19.351h-17.408zM247.296 248.008h17.408v19.343h-17.408z" />
    </svg>
  );
}

export default SvgComponent;
