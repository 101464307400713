import React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="Capa_1"
      enableBackground="new 0 0 497 497"
      height="512"
      viewBox="0 0 497 497"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <g>
          <path
            d="m16.567 397.6v66.267c0 18.299 14.834 33.133 33.133 33.133h397.6c18.299 0 33.132-14.834 33.132-33.133v-66.267z"
            fill="#b5dbff"
          />
          <path
            d="m457.433 397.6v66.268c0 18.298-14.834 33.132-33.132 33.132h23c18.299 0 33.132-14.834 33.132-33.132v-66.268z"
            fill="#97d0ff"
          />
          <path
            d="m16.567 132.533v298.2c0 18.298 14.834 33.132 33.132 33.132h397.601c18.299 0 33.132-14.834 33.132-33.132v-298.2z"
            fill="#edf5ff"
          />
          <path
            d="m457.433 132.533v298.2c0 18.298-14.834 33.132-33.132 33.132h23c18.299 0 33.132-14.834 33.132-33.132v-298.2z"
            fill="#d5e8fe"
          />
          <path
            d="m480.433 149.1v-82.834c0-18.299-14.834-33.132-33.132-33.132h-397.601c-18.299 0-33.132 14.834-33.132 33.132v82.834z"
            fill="#ff435b"
          />
          <g>
            <path
              d="m115.967 73.767h-16.567c-4.142 0-7.5-3.358-7.5-7.5s3.358-7.5 7.5-7.5h16.567c4.142 0 7.5 3.358 7.5 7.5s-3.358 7.5-7.5 7.5z"
              fill="#e3374e"
            />
          </g>
          <g>
            <path
              d="m165.667 73.767h-16.567c-4.142 0-7.5-3.358-7.5-7.5s3.358-7.5 7.5-7.5h16.566c4.142 0 7.5 3.358 7.5 7.5s-3.357 7.5-7.499 7.5z"
              fill="#e3374e"
            />
          </g>
          <g>
            <path
              d="m347.9 73.767h-16.566c-4.142 0-7.5-3.358-7.5-7.5s3.358-7.5 7.5-7.5h16.566c4.142 0 7.5 3.358 7.5 7.5s-3.358 7.5-7.5 7.5z"
              fill="#e3374e"
            />
          </g>
          <g>
            <path
              d="m397.6 73.767h-16.567c-4.142 0-7.5-3.358-7.5-7.5s3.358-7.5 7.5-7.5h16.567c4.142 0 7.5 3.358 7.5 7.5s-3.358 7.5-7.5 7.5z"
              fill="#e3374e"
            />
          </g>
          <path
            d="m115.967 66.267c0 9.149 7.417 16.567 16.567 16.567s16.567-7.417 16.567-16.567v-49.7c-.001-9.15-7.418-16.567-16.568-16.567-9.149 0-16.567 7.417-16.567 16.567v49.7z"
            fill="#596c76"
          />
          <path
            d="m347.9 66.267c0 9.149 7.417 16.567 16.567 16.567s16.567-7.417 16.567-16.567v-49.7c0-9.15-7.417-16.567-16.567-16.567s-16.567 7.417-16.567 16.567z"
            fill="#596c76"
          />
          <g fill="#e3374e">
            <path d="m447.3 33.133h-23c18.299 0 33.132 14.834 33.132 33.132v82.835h23v-82.834c.001-18.299-14.833-33.133-33.132-33.133z" />
            <path d="m16.567 108.467h463.866v15h-463.866z" />
          </g>
        </g>
        <g>
          <g>
            <path
              d="m182.233 222.867h-33.133c-4.142 0-7.5-3.358-7.5-7.5s3.358-7.5 7.5-7.5h33.133c4.142 0 7.5 3.358 7.5 7.5s-3.357 7.5-7.5 7.5z"
              fill="#596c76"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="m182.233 305.7h-33.133c-4.142 0-7.5-3.358-7.5-7.5s3.358-7.5 7.5-7.5h33.133c4.142 0 7.5 3.358 7.5 7.5s-3.357 7.5-7.5 7.5z"
              fill="#596c76"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="m265.067 222.867h-33.134c-4.142 0-7.5-3.358-7.5-7.5s3.358-7.5 7.5-7.5h33.134c4.142 0 7.5 3.358 7.5 7.5s-3.358 7.5-7.5 7.5z"
              fill="#596c76"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="m99.4 305.7h-33.133c-4.142 0-7.5-3.358-7.5-7.5s3.358-7.5 7.5-7.5h33.133c4.142 0 7.5 3.358 7.5 7.5s-3.358 7.5-7.5 7.5z"
              fill="#596c76"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="m265.067 305.7h-33.134c-4.142 0-7.5-3.358-7.5-7.5s3.358-7.5 7.5-7.5h33.134c4.142 0 7.5 3.358 7.5 7.5s-3.358 7.5-7.5 7.5z"
              fill="#596c76"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="m347.9 222.867h-33.133c-4.142 0-7.5-3.358-7.5-7.5s3.358-7.5 7.5-7.5h33.133c4.142 0 7.5 3.358 7.5 7.5s-3.358 7.5-7.5 7.5z"
              fill="#596c76"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="m430.733 222.867h-33.133c-4.142 0-7.5-3.358-7.5-7.5s3.358-7.5 7.5-7.5h33.133c4.142 0 7.5 3.358 7.5 7.5s-3.358 7.5-7.5 7.5z"
              fill="#596c76"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="m347.9 305.7h-33.133c-4.142 0-7.5-3.358-7.5-7.5s3.358-7.5 7.5-7.5h33.133c4.142 0 7.5 3.358 7.5 7.5s-3.358 7.5-7.5 7.5z"
              fill="#596c76"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="m430.733 305.7h-33.133c-4.142 0-7.5-3.358-7.5-7.5s3.358-7.5 7.5-7.5h33.133c4.142 0 7.5 3.358 7.5 7.5s-3.358 7.5-7.5 7.5z"
              fill="#596c76"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="m347.9 388.533h-33.133c-4.142 0-7.5-3.358-7.5-7.5s3.358-7.5 7.5-7.5h33.133c4.142 0 7.5 3.358 7.5 7.5s-3.358 7.5-7.5 7.5z"
              fill="#596c76"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="m265.067 388.533h-33.134c-4.142 0-7.5-3.358-7.5-7.5s3.358-7.5 7.5-7.5h33.134c4.142 0 7.5 3.358 7.5 7.5s-3.358 7.5-7.5 7.5z"
              fill="#596c76"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="m182.233 388.533h-33.133c-4.142 0-7.5-3.358-7.5-7.5s3.358-7.5 7.5-7.5h33.133c4.142 0 7.5 3.358 7.5 7.5s-3.357 7.5-7.5 7.5z"
              fill="#596c76"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="m99.4 388.533h-33.133c-4.142 0-7.5-3.358-7.5-7.5s3.358-7.5 7.5-7.5h33.133c4.142 0 7.5 3.358 7.5 7.5s-3.358 7.5-7.5 7.5z"
              fill="#596c76"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
