import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "ducks/state";
import {
  getClientsStatus,
  userStatusView,
  getMessages,
  fetchMessages,
  fetchActivitiesGroup,
  getActivitiesGroupByMegrID,
} from "ducks/socket";
import {
  avatarByUserIDViewCreator,
  fetchCachedProfileForUserID,
} from "ducks/user";

export const useGetClientsStatus = (userID: string) => {
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    const t = setInterval(() => {
      dispatch(getClientsStatus([userID]));
    }, 30000);
    dispatch(fetchCachedProfileForUserID(userID));
    return () => {
      clearInterval(t);
    };
  }, [userID, dispatch]);

  const getStatus = React.useCallback(userStatusView({ userID }), [userID]);
  const getUserByUserID = React.useCallback(
    avatarByUserIDViewCreator({ userID }),
    [userID]
  );

  const { status } = useSelector(getStatus);
  const { src, name } = useSelector(getUserByUserID);

  const memoStatus = React.useMemo(() => status, [status]);
  const memoSrc = React.useMemo(() => src, [src]);
  const memoName = React.useMemo(() => name, [name]);

  return [memoStatus, memoSrc, memoName] as [
    typeof memoStatus,
    typeof memoSrc,
    typeof memoName
  ];
};

export const useListMessages = (userID: string, megrID?: string) => {
  const dispatch: AppDispatch = useDispatch();
  const timeout = React.useRef<NodeJS.Timeout | null>(null);

  const loadMessages = React.useCallback(
    (count = 1) => {
      if (count > 100) {
        return;
      }
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(() => {
        dispatch(fetchMessages(userID, -1, megrID)).then((success: boolean) => {
          if (!success) {
            loadMessages(count + 1);
          }
        });
      }, 1000 * (count * 0.03));
    },
    [dispatch, userID, megrID]
  );

  React.useEffect(() => {
    dispatch(fetchMessages(userID, -1, megrID)).then((success: boolean) => {
      if (!success) {
        loadMessages(1);
      }
    });
  }, [dispatch, loadMessages, userID, megrID]);

  const { listMessages = [], unreadMessages } = useSelector(
    (state: RootState) => getMessages(state, { userID })(state)
  );

  return [listMessages, unreadMessages] as [
    typeof listMessages,
    typeof unreadMessages
  ];
};

export const useGetActivitiesGroupByMegrID = (megrID?: string) => {
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchActivitiesGroup({ megrID }));
  }, [dispatch, megrID]);

  const getActivities = React.useCallback(
    getActivitiesGroupByMegrID({ megrID }),
    [megrID]
  );
  const { activeGroups } = useSelector(getActivities);

  return [activeGroups] as [typeof activeGroups];
};
