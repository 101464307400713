import axios from "axios";
import { deriveErrorMessage, toQueryString } from "@udok/lib/internal/util";
import {
  AppointmentInstruction,
  AppointmentInstructionForm,
  AppointmentInstructionFilter,
} from "@udok/lib/api/models";

export const createAppointmentInstruction = async (token: string, form: AppointmentInstructionForm) => {
  return axios
    .post(`${process.env.REACT_APP_API_PATH}/appointment-instructions`, form, {
      headers: { Authorization: token },
    })
    .then((r) => {
      return r.data.data.item as AppointmentInstruction;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "APIN1");
      return Promise.reject(new Error(err));
    });
};

export const listAppointmentInstructions = (token: string, filter?: AppointmentInstructionFilter) => {
  const f = filter ? toQueryString({ filter }) : "";
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/appointment-instructions${f}`, {
      headers: { Authorization: token },
    })
    .then((r) => {
      return r.data.data.items as AppointmentInstruction[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "APIN2");
      return Promise.reject(new Error(err));
    });
};

export const getAppointmentInstruction = (token: string, apinID: number) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/appointment-instructions/${apinID}`, {
      headers: { Authorization: token },
    })
    .then((r) => {
      return r.data.data.item as AppointmentInstruction;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "APIN3");
      return Promise.reject(new Error(err));
    });
};

export const deleteAppointmentInstruction = async (token: string, apinID: number) => {
  return axios
    .delete(`${process.env.REACT_APP_API_PATH}/appointment-instructions/${apinID}`, {
      headers: { Authorization: token },
    })
    .then((r) => {
      return r.data.data.item as AppointmentInstruction;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "APIN4");
      return Promise.reject(new Error(err));
    });
};

export const updateAppointmentInstruction = async (
  token: string,
  form: AppointmentInstructionForm & { apinID: number }
) => {
  return axios
    .put(`${process.env.REACT_APP_API_PATH}/appointment-instructions/${form.apinID}`, form, {
      headers: { Authorization: token },
    })
    .then((r) => {
      return r.data.data.item as AppointmentInstruction;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "APIN5");
      return Promise.reject(new Error(err));
    });
};

export const searchAppointmentInstruction = (filter?: AppointmentInstructionFilter) => {
  const f = filter ? toQueryString(filter) : "";

  return axios
    .get(`${process.env.REACT_APP_API_PATH}/search/appointment-instructions${f}`)
    .then((r) => {
      return r.data.data.items as AppointmentInstruction[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "APIN6");
      return Promise.reject(new Error(err));
    });
};
