import * as React from "react";
import { Grid, useMediaQuery } from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import { useParams, useLocation } from "react-router";
import {
  HeaderIconTitle,
  HeaderTitle,
  ActiveChatUserContext,
} from "@udok/lib/components/UI/UIContext";

import Loadable from "react-loadable";
import Loader from "@udok/lib/components/PageLoader";
import ItemComponent from "containers/Chat/Conversations/ItemComponent";
import clsx from "clsx";

export const Conversations = Loadable({
  loader: () => import("containers/Chat/Conversations/Conversations"),
  loading: Loader,
});

export const DirectMessage = Loadable({
  loader: () => import("containers/Chat/DirectMessage"),
  loading: Loader,
});

export const DirectMessageSimple = Loadable({
  loader: () => import("containers/Chat/DirectMessage/DirectMessageSimple"),
  loading: Loader,
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: "#eaeff1",
      flex: 1,
      flexDirection: "row" as "row",
      display: "flex",
    },
    menu: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      marginTop: 24,
    },
    closed: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  })
);

export const ConversationsScreen = () => {
  const { setChat } = React.useContext(ActiveChatUserContext);
  const { userID } = useParams<{ userID: string }>();
  const classes = useStyles();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const megrID = query.get("megrID") ?? undefined;
  const apscID = query.get("apscID") ?? undefined;
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  const onOpenChat = React.useCallback(
    (userID: string) => {
      setChat({ userID });
    },
    [setChat]
  );

  const itemComponent = React.useCallback(
    (props: any) => {
      let itemProps = { ...props };
      if (isSmallerScreen) {
        itemProps.onClick = onOpenChat;
      }
      return <ItemComponent {...itemProps} />;
    },
    [onOpenChat, isSmallerScreen]
  );

  return (
    <main className={classes.root}>
      <HeaderTitle>
        <HeaderIconTitle title="Mensagens" icon="Chat" />
      </HeaderTitle>
      <Grid md={3} xs={12}>
        <Conversations
          selectedUserID={userID}
          listItemComponent={itemComponent}
        />
      </Grid>
      <Grid
        md={9}
        xs={12}
        className={clsx({ [classes.closed]: isSmallerScreen })}
      >
        {userID ? (
          <DirectMessage
            key={userID}
            userID={userID}
            megrID={megrID}
            apscID={apscID}
          />
        ) : null}
      </Grid>
    </main>
  );
};

export default React.memo(ConversationsScreen);
