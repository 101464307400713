import axios from "axios";
import { deriveErrorMessage, toQueryString } from "@udok/lib/internal/util";
import { Specialty, ClinicSpecialty, SpecialtyFilter } from "./models";

export const fetchSpecialties = async (filter: SpecialtyFilter = {}) => {
  return axios
    .get(`${process.env.REACT_APP_BASE_PATH}/specialty${toQueryString(filter)}`)
    .then((r) => {
      return r.data.data.items as Specialty[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "SPEC1");
      return Promise.reject(err);
    });
};

export const fetchSpecialty = async (specID: string) => {
  return axios
    .get(`${process.env.REACT_APP_BASE_PATH}/specialty/${specID}`)
    .then((r) => {
      return r.data.data.item as Specialty;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "SPEC2");
      return Promise.reject(err);
    });
};

export const removeSpecialty = async (
  token: string,
  doctID: string,
  specID: string
) => {
  return axios
    .delete(
      `${process.env.REACT_APP_API_PATH}/specialty/${specID}/doctor/${doctID}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as Specialty;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "SPEC3");
      return Promise.reject(err);
    });
};

export const createSpecialty = async (
  token: string,
  doctID: string,
  id: string
) => {
  const specID = Number(id);
  if (isNaN(specID)) {
    return Promise.reject(new Error("invalid specID"));
  }
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/specialty-doctor`,
      { doctID, specID },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as Specialty;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "SPEC4");
      return Promise.reject(new Error(err));
    });
};

export const fetchDoctorSpecialtys = (token: string, doctID: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/specialty/doctor/${doctID}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.items as Specialty[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "SPEC2");
      return Promise.reject(err);
    });
};

export const fetchClinicSpecialties = async (token: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/clinic-specialties`, {
      headers: { Authorization: token },
    })
    .then((r) => {
      return r.data.data.items as ClinicSpecialty[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "cli5");
      return Promise.reject(err);
    });
};

export type CreateClinicSpecialtyRequest = {
  specID: number;
  order: number;
};
export const createClinicSpecialty = async (
  token: string,
  d: CreateClinicSpecialtyRequest
) => {
  return axios
    .post(`${process.env.REACT_APP_API_PATH}/clinic-specialties`, d, {
      headers: { Authorization: token },
    })
    .then((r) => {
      return r.data.data.item as ClinicSpecialty;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "cli6");
      return Promise.reject(new Error(err));
    });
};

export const deleteClinicSpecialty = async (token: string, specID: string) => {
  return axios
    .delete(`${process.env.REACT_APP_API_PATH}/clinic-specialties/${specID}`, {
      headers: { Authorization: token },
    })
    .then((r) => {
      return r.data.data.item as ClinicSpecialty;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "cli7");
      return Promise.reject(err);
    });
};
