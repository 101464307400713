import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import { ExamProcedure, ExamProcedureFilter } from "@udok/lib/api/models";
import { searchProcedures, fetchProcedure } from "@udok/lib/api/procedure";
import { UNAUTHORIZED } from "./auth";

export type InitialState = {
  proceduresByID: { [exprID: string]: ExamProcedure | undefined };
  filteredProcedures: string[];
};

// Reducers
const initialState: InitialState = {
  proceduresByID: {},
  filteredProcedures: [],
};

class Procedures extends Hen<InitialState> {
  listProceduresLoaded(list: ExamProcedure[]) {
    this.state.filteredProcedures = list.map((p) => {
      this.state.proceduresByID[p.exprID] = p;
      return p.exprID;
    });
  }
  procedureLoaded(pr: ExamProcedure) {
    this.state.proceduresByID[pr.exprID] = pr;
  }
}

export const [Reducer, actions] = hen(new Procedures(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
const proceduresSelector = (state: RootState) => state.procedure.proceduresByID;
const filteredProceduresSelector = (state: RootState) =>
  state.procedure.filteredProcedures;

export const searchProceduresListView = createSelector(
  [proceduresSelector, filteredProceduresSelector],
  (proceduresByID, filtered) => {
    const allProcedures = Object.keys(proceduresByID)
      .map((id) => proceduresByID[id])
      .filter((b) => !!b) as ExamProcedure[];
    const filteredProcedures = filtered
      .map((id) => proceduresByID[id])
      .filter((b) => !!b) as ExamProcedure[];
    return {
      allProcedures,
      filteredProcedures,
    };
  }
);

export const getOneProcedureView = (props: { exprID: string }) =>
  createSelector([proceduresSelector], (proceduresByID) => {
    return {
      procedure: proceduresByID[props.exprID],
    };
  });

export const getProceduresByID = createSelector(
  [proceduresSelector],
  (proceduresByID) => {
    return {
      proceduresByID,
    };
  }
);

// Actions
export function searchListProcedures(
  f?: ExamProcedureFilter
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const fil: ExamProcedureFilter = {
      limit: 100,
      ...f,
    };
    return searchProcedures(fil)
      .then((r) => {
        dispatch(actions.listProceduresLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function loadProcedure(exprID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    return fetchProcedure(exprID)
      .then((r) => {
        dispatch(actions.procedureLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function fetchCachedProcedure(exprID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const p = state.procedure.proceduresByID;
    if (Boolean(p[exprID])) {
      return Promise.resolve();
    }

    return dispatch(loadProcedure(exprID));
  };
}
