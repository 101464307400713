import * as React from "react";

function SvgComponent(
  props: React.SVGProps<SVGSVGElement> & {
    variant?:
      | "up-left"
      | "up-right"
      | "up-center"
      | "down-left"
      | "down-right"
      | "down-center"
      | "center-right"
      | "center-left"
      | "center";
  }
) {
  const tranformTR = {
    ["up-left"]: "translate(-4 -2)",
    ["up-right"]: "translate(4 -2)",
    ["up-center"]: "translate(0 -2)",
    ["down-left"]: "translate(-4 2)",
    ["down-right"]: "translate(4 2)",
    ["down-center"]: "translate(0 2)",
    ["center-right"]: "translate(4 0)",
    ["center-left"]: "translate(-4 0)",
    ["center"]: "",
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        isolation: "isolate",
      }}
      viewBox="0 0 18 18"
      width="1em"
      height="1em"
      {...props}
    >
      <g fillRule="evenodd">
        <path d="M2.75 0C1.24 0 0 1.24 0 2.75v12.5C0 16.76 1.24 18 2.75 18h12.5c1.51 0 2.75-1.24 2.75-2.75V2.75C18 1.24 16.76 0 15.25 0H2.75Zm0 1.5h12.5c.699 0 1.25.551 1.25 1.25v12.5a1.24 1.24 0 0 1-1.25 1.25H2.75a1.24 1.24 0 0 1-1.25-1.25V2.75c0-.699.551-1.25 1.25-1.25Z" />
        <path
          d="M7.5 5a.747.747 0 0 0-.53.22.747.747 0 0 0-.22.53v6.5c0 .199.079.39.22.53.14.141.331.22.53.22h3c.199 0 .39-.079.53-.22a.747.747 0 0 0 .22-.53v-6.5a.747.747 0 0 0-.22-.53.747.747 0 0 0-.53-.22h-3Zm.75 1.5h1.5v5h-1.5v-5Z"
          transform={tranformTR[props?.variant ?? "center"]}
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
