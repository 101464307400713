import React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 512 512" {...props}>
      <path
        fill="#ecedf2"
        d="M252.199 27.391h231.056v466.117H28.748V27.391h62.59"
      />
      <path
        fill="#c6cad5"
        d="M252.199 27.391h231.056v47.923H28.748V27.391h62.59"
      />
      <path
        d="M190.254 45.535h313.411A8.335 8.335 0 00512 37.2V17.581a8.335 8.335 0 00-8.335-8.335H8.338a8.335 8.335 0 00-8.335 8.335v19.62a8.335 8.335 0 008.335 8.335h130.638"
        fill="#525c66"
      />
      <circle cx={171.77} cy={104.962} r={94.166} fill="#c6cad5" />
      <circle cx={171.77} cy={94.514} r={94.166} fill="#fff" />
      <path
        d="M226.758 76.028h-32.335a4.169 4.169 0 01-4.169-4.169V39.524a4.169 4.169 0 00-4.169-4.169h-28.637a4.169 4.169 0 00-4.169 4.169V71.86a4.169 4.169 0 01-4.169 4.169h-32.335a4.169 4.169 0 00-4.169 4.169v28.637a4.169 4.169 0 004.169 4.169h32.335a4.169 4.169 0 014.169 4.169v32.335a4.169 4.169 0 004.169 4.169h28.637a4.169 4.169 0 004.169-4.169v-32.335a4.169 4.169 0 014.169-4.169h32.335a4.169 4.169 0 004.169-4.169V80.198a4.17 4.17 0 00-4.169-4.17z"
        fill="#d81616"
      />
      <g fill="#a0d9f2">
        <path d="M314.523 220.287h50.924v92.99h-50.924zM146.556 220.287h50.924v92.99h-50.924zM398.501 220.287h50.924v92.99h-50.924zM230.534 220.287h50.924v92.99h-50.924zM62.581 220.287h50.924v92.99H62.581zM146.556 344.88h50.924v92.99h-50.924zM230.534 344.88h50.924v92.99h-50.924zM62.581 344.88h50.924v92.99H62.581zM314.523 95.684h50.924v92.99h-50.924zM398.501 95.684h50.924v92.99h-50.924z" />
      </g>
      <g fill="#fff">
        <path d="M365.444 319.545H314.52a6.27 6.27 0 01-6.269-6.269v-92.99a6.27 6.27 0 016.269-6.269h50.924a6.27 6.27 0 016.269 6.269v92.99a6.27 6.27 0 01-6.269 6.269zm-44.655-12.539h38.385v-80.451h-38.385v80.451zM197.485 319.545h-50.924a6.27 6.27 0 01-6.269-6.269v-92.99a6.27 6.27 0 016.269-6.269h50.924a6.27 6.27 0 016.269 6.269v92.99a6.27 6.27 0 01-6.269 6.269zm-44.655-12.539h38.385v-80.451H152.83v80.451zM449.423 319.545h-50.924a6.27 6.27 0 01-6.269-6.269v-92.99a6.27 6.27 0 016.269-6.269h50.924a6.27 6.27 0 016.269 6.269v92.99a6.27 6.27 0 01-6.269 6.269zm-44.655-12.539h38.385v-80.451h-38.385v80.451zM281.464 319.545H230.54a6.27 6.27 0 01-6.269-6.269v-92.99a6.27 6.27 0 016.269-6.269h50.924a6.27 6.27 0 016.269 6.269v92.99a6.27 6.27 0 01-6.269 6.269zm-44.655-12.539h38.385v-80.451h-38.385v80.451zM113.504 319.545H62.581a6.27 6.27 0 01-6.269-6.269v-92.99a6.27 6.27 0 016.269-6.269h50.924a6.27 6.27 0 016.269 6.269v92.99a6.27 6.27 0 01-6.27 6.269zM68.85 307.006h38.385v-80.451H68.85v80.451z" />
      </g>
      <path fill="#a0d9f2" d="M449.423 344.882H314.52v130.48l134.903 10.45z" />
      <g fill="#fff">
        <path d="M455.692 480.587h-12.539V351.151H320.789v129.436H308.25V344.882a6.27 6.27 0 016.269-6.269h134.903a6.27 6.27 0 016.269 6.269l.001 135.705zM197.485 444.142h-50.924a6.27 6.27 0 01-6.269-6.269v-92.99a6.27 6.27 0 016.269-6.269h50.924a6.27 6.27 0 016.269 6.269v92.99a6.27 6.27 0 01-6.269 6.269zm-44.655-12.539h38.385v-80.451H152.83v80.451zM281.464 444.142H230.54a6.27 6.27 0 01-6.269-6.269v-92.99a6.27 6.27 0 016.269-6.269h50.924a6.27 6.27 0 016.269 6.269v92.99a6.27 6.27 0 01-6.269 6.269zm-44.655-12.539h38.385v-80.451h-38.385v80.451zM113.504 444.142H62.581a6.27 6.27 0 01-6.269-6.269v-92.99a6.27 6.27 0 016.269-6.269h50.924a6.27 6.27 0 016.269 6.269v92.99a6.27 6.27 0 01-6.27 6.269zM68.85 431.603h38.385v-80.451H68.85v80.451zM365.444 194.949H314.52a6.27 6.27 0 01-6.269-6.269V95.688a6.27 6.27 0 016.269-6.269h50.924a6.27 6.27 0 016.269 6.269v92.99a6.271 6.271 0 01-6.269 6.271zm-44.655-12.539h38.385v-80.452h-38.385v80.452zM449.423 194.949h-50.924a6.27 6.27 0 01-6.269-6.269V95.688a6.27 6.27 0 016.269-6.269h50.924a6.27 6.27 0 016.269 6.269v92.99a6.27 6.27 0 01-6.269 6.271zm-44.655-12.539h38.385v-80.452h-38.385v80.452z" />
        <path d="M314.523 370.48h134.906v12.539H314.523z" />
        <path d="M375.701 376.749h12.539v109.055h-12.539zM146.556 368.39h50.928v12.539h-50.928zM281.466 380.925h-50.928v-12.539h44.659v-23.5h12.539v29.769a6.271 6.271 0 01-6.27 6.27zM62.582 368.39H113.5v12.539H62.582zM449.421 256.333h-50.918v-12.539h44.648v-23.51h12.539v29.779a6.269 6.269 0 01-6.269 6.27zM230.534 243.797h50.928v12.539h-50.928zM62.582 243.797H113.5v12.539H62.582zM146.556 243.797h50.928v12.539h-50.928zM314.512 243.797h50.928v12.539h-50.928zM449.421 131.739h-50.918V119.2h44.648V95.69h12.539v29.779a6.268 6.268 0 01-6.269 6.27zM314.512 119.204h50.928v12.539h-50.928z" />
      </g>
      <path
        d="M496.669 475.363H15.328C6.863 475.363 0 482.226 0 490.69v20.962h511.997V490.69c0-8.464-6.863-15.327-15.328-15.327z"
        fill="#423e3e"
      />
    </svg>
  );
}

export default SvgComponent;
