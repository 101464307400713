import React from "react";
import { connect } from "react-redux";
import { getMessageAlertView } from "ducks/socket";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import {
  Button,
  ButtonProps,
  IconButton,
  useMediaQuery,
} from "@material-ui/core";
import Icons from "components/Icon";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.neutral.main,
    [theme.breakpoints.down("md")]: {
      margin: " 0 10px",
    },
  },
  iconButton: {
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      padding: 0,
    },
  },
}));

export type AlertButtonProps = ReturnType<typeof getMessageAlertView> &
  ButtonProps;

const AlertButton = (props: AlertButtonProps) => {
  const { unreadCount, ...otherProps } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const chatIcon = (
    <Badge
      variant="dot"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      badgeContent={unreadCount}
      color="error"
      max={9}
      showZero={false}
    >
      <Icons.Chat fontSize={18} />
    </Badge>
  );

  if (isSmallerScreen) {
    return (
      <IconButton
        className={clsx(classes.root, classes.iconButton)}
        onClick={otherProps.onClick}
      >
        {chatIcon}
      </IconButton>
    );
  }
  return (
    <Button
      className={classes.root}
      disableRipple
      variant="text"
      startIcon={chatIcon}
      {...otherProps}
    >
      Mensagens
    </Button>
  );
};

export default connect(getMessageAlertView, (dispatch: any) => ({}))(
  AlertButton
);
