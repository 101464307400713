import axios from "axios";
import { deriveErrorMessage } from "@udok/lib/internal/util";
import { Document, DocumentData } from "@udok/lib/api/models";

export const fetchDocumentTemplates = async (token: string) => {
  let filter = "?limit=10000";
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/documents-templates${filter}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.items as Array<Document>;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "dt1");
      return Promise.reject(err);
    });
};

export const fetchDocumentTemplate = async (token: string, doteID: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/documents-templates/${doteID}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as Document;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "dt2");
      return Promise.reject(err);
    });
};

export const deleteDocumentTemplate = async (token: string, doteID: string) => {
  return axios
    .delete(`${process.env.REACT_APP_API_PATH}/documents-templates/${doteID}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as Document;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "dt3");
      return Promise.reject(err);
    });
};

export const createDocumentTemplate = async (
  token: string,
  template: Document
) => {
  return axios
    .post(`${process.env.REACT_APP_API_PATH}/documents-templates`, template, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as Document;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "dt4");
      return Promise.reject(err);
    });
};

export const updateDocumentTemplate = async (
  token: string,
  template: Document
) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_PATH}/documents-templates/${template.doteID}`,
      template,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as Document;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "dt5");
      return Promise.reject(err);
    });
};

export const fetchDocument = (token: string, docuID: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/documents/${docuID}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as DocumentData;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "dt6");
      return Promise.reject(err);
    });
};
