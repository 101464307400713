import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0)">
        <path d="M7.995 3C4.222 3 .898 5.615.015 9.042a.5.5 0 10.97.249C1.744 6.338 4.688 4 7.994 4c3.307 0 6.26 2.339 7.02 5.291a.5.5 0 00.97-.249C15.101 5.614 11.768 3 7.994 3zm.006 2.667a3.229 3.229 0 00-3.222 3.221 3.23 3.23 0 003.222 3.222 3.23 3.23 0 003.222-3.222A3.23 3.23 0 008 5.667z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
