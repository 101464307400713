import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ isolation: "isolate" }}
    viewBox="0 0 730 445"
    width="1em"
    height="1em"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M399.159 43.54c19-5.5 28.7-24.4 46.1-32.6 69.7-30 105.8 57.2 161.9 73 25.3 6.7 48.4 2.3 74 13.2 31.1 13.3 47.3 48.6 46.8 83.7-2 32.4-27.8 53.4-42.6 81-17.4 27.8-11.1 40-2.3 68.9 15.5 46.6 7.5 96.7-45.5 107.3-59.9 12.5-91.6-30.2-142.6-50.5-65-25-139.1-19.9-201.9 9.6-88.4 39.7-256.6 71.5-287.5-62.4-11.6-49.7 5.4-84.5 51-103.1 17.5-6.8 35.6-17.1 45.5-34.6 13.6-23.3-2.5-58.7-5.6-83.7-19.4-168.3 228.6-45.1 306.8-70.1"
        fill="#C7E0EC"
      />
      <path
        d="M574.559 388.94c0 5.7-43.2 7.4-47.9 7.4h-323.3c-4.7 0-47.9-1.7-47.9-7.4l39.4-59.1c0-5.8 3.8-10.5 8.5-10.5h323.3c4.7 0 8.5 4.7 8.5 10.5l39.4 59.1Z"
        fill="#B3B3B3"
      />
      <path
        d="M298.559 375.74h-83.1l14.5-35.2h75.2l-6.6 35.2ZM424.959 340.54h75.1l14.6 35.2h-83.1l-6.6-35.2ZM507.059 340.54h22.1l24 35.2h-29.3l-16.8-35.2ZM206.159 375.74h-29.2l23.9-35.2h22.1l-16.8 35.2ZM424.159 375.74h-118.3l6.6-35.2h105.1l6.6 35.2Z"
        fill="#3E3E3D"
      />
      <path
        d="M545.859 352.34h-361.7v-5.4h361.7v5.4ZM549.859 365.54h-369.6v-5.4h369.6v5.4Z"
        fill="#B3B3B3"
      />
      <path
        d="M576.759 402.64c0 4.6-.9 8.4-5.6 8.4h-412.3c-4.6 0-5.6-3.8-5.6-8.4v-7.3c0-4.7 1-8.4 5.6-8.4h412.3c4.7 0 5.6 3.7 5.6 8.4v7.3Z"
        fill="#3E3E3D"
      />
      <path
        d="M442.259 399.14c0 2.5-1.9 4.4-4.4 4.4h-145.7c-2.4 0-4.4-1.9-4.4-4.4 0-2.4 2-4.4 4.4-4.4h145.7c2.5 0 4.4 2 4.4 4.4Z"
        fill="#6F6F6F"
      />
      <path
        d="M539.359 321.84c0 4.7-3.8 8.4-8.4 8.4h-331.8c-4.7 0-8.5-3.7-8.5-8.4v-216.5c0-4.6 3.8-8.4 8.5-8.4h331.8c4.6 0 8.4 3.8 8.4 8.4v216.5Z"
        fill="#3E3E3D"
      />
      <path
        d="M365.059 108.64c4.5 0 4.5-7 0-7-4.6 0-4.6 7 0 7ZM514.959 308.24c0 4-3.3 7.2-7.3 7.2h-285.3c-4 0-7.3-3.2-7.3-7.2v-186.2c0-4 3.3-7.2 7.3-7.2h285.3c4 0 7.3 3.2 7.3 7.2v186.2Z"
        fill="#FFF"
      />
      <path
        d="M493.759 68.04v72.6c0 7.1-11 7.1-11 0v-72.6c0-7.1 11-7.1 11 0ZM488.259 168.74c10.6 0 10.6-16.5 0-16.5-10.7 0-10.7 16.5 0 16.5Z"
        fill="#DB9022"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={364.659}
        cy={215.566}
        r={76.821}
        fill="#0345B7"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={364.659}
        cy={215.566}
        r={60.927}
        fill="#FFF"
      />
      <path
        d="M364.659 206.495c-6.907 0-12.8 4.429-14.986 10.596l-21.814-.001a5.299 5.299 0 0 0 0 10.595l21.813.001c2.187 6.167 8.079 10.596 14.986 10.596 8.764 0 15.894-7.13 15.894-15.894 0-8.763-7.129-15.893-15.893-15.893Z"
        fill="#EAF6FF"
      />
      <path
        d="M384.838 158.061c16.342 10.927 27.103 29.548 27.103 50.683 0 33.649-27.278 60.927-60.927 60.927a60.834 60.834 0 0 1-20.179-3.422c9.676 6.471 21.309 10.244 33.824 10.244 33.649 0 60.926-27.278 60.926-60.927 0-26.576-17.016-49.178-40.747-57.505Z"
        fill="#EAF6FF"
      />
      <path
        d="M369.957 218.215h-10.596v42.384a5.298 5.298 0 0 0 10.596 0v-42.384Z"
        fill="#004281"
      />
      <path
        d="M359.361 237.374a15.82 15.82 0 0 0 5.298.908c1.857 0 3.64-.32 5.298-.908v-19.159h-10.596v19.159Z"
        fill="#00366D"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={364.659}
        cy={215.566}
        r={5.298}
        fill="#26A6FE"
      />
      <path
        d="M322.275 218.05h-5.298a2.484 2.484 0 1 1 0-4.967h5.298a2.483 2.483 0 1 1 0 4.967Zm2.332 23.508 4.588-2.649a2.483 2.483 0 1 0-2.484-4.301l-4.588 2.649a2.483 2.483 0 1 0 2.484 4.301Zm18.361 16.544 2.649-4.588a2.484 2.484 0 0 0-4.301-2.484l-2.649 4.588a2.484 2.484 0 0 0 4.301 2.484Zm24.174-84.92v-5.298a2.483 2.483 0 1 0-4.967 0v5.298a2.484 2.484 0 1 0 4.967 0Zm22.599 85.829a2.484 2.484 0 0 0 .909-3.393l-2.649-4.588a2.483 2.483 0 1 0-4.301 2.484l2.649 4.588a2.484 2.484 0 0 0 3.392.909Zm18.362-18.362a2.482 2.482 0 0 0-.909-3.392l-4.588-2.649a2.482 2.482 0 1 0-2.483 4.301l4.588 2.649a2.482 2.482 0 0 0 3.392-.909Zm6.721-25.083a2.483 2.483 0 0 0-2.483-2.483h-5.298a2.483 2.483 0 1 0 0 4.967h5.298a2.484 2.484 0 0 0 2.483-2.484Zm-12.218-19.041 4.588-2.649a2.483 2.483 0 1 0-2.483-4.301l-4.588 2.649a2.482 2.482 0 1 0 2.483 4.301Zm-14.605-16.423 2.649-4.588a2.483 2.483 0 1 0-4.301-2.483l-2.649 4.588a2.483 2.483 0 1 0 4.301 2.483Zm-43.292.91a2.484 2.484 0 0 0 .908-3.393l-2.649-4.588a2.483 2.483 0 1 0-4.301 2.484l2.649 4.588a2.484 2.484 0 0 0 3.393.909Zm-14.605 14.604a2.482 2.482 0 0 0-.909-3.392l-4.588-2.649a2.482 2.482 0 1 0-2.483 4.301l4.588 2.649a2.482 2.482 0 0 0 3.392-.909Z"
        fill="#004281"
      />
      <path
        d="M414.824 215.566a2.483 2.483 0 0 0-2.483-2.483h-.552a61.416 61.416 0 0 1-.554 4.967h1.106a2.484 2.484 0 0 0 2.483-2.484ZM407.194 237.257l-1.788-1.033a60.28 60.28 0 0 1-2.423 4.336l1.728.998a2.482 2.482 0 0 0 3.392-.909 2.482 2.482 0 0 0-.909-3.392ZM388.502 259.344a2.484 2.484 0 0 0 2.148-3.726l-.233-.403a61.188 61.188 0 0 1-3.944 3.08 2.483 2.483 0 0 0 2.029 1.049Z"
        fill="#00366D"
      />
      <path
        d="M364.659 199.672c-6.907 0-12.8 4.429-14.986 10.596h-21.814a5.298 5.298 0 1 0 0 10.595l21.813.001c2.187 6.167 8.079 10.596 14.987 10.596 8.764 0 15.894-7.13 15.894-15.894s-7.13-15.894-15.894-15.894Z"
        fill="#0055A3"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={364.659}
        cy={215.566}
        r={5.298}
        fill="#80D261"
      />
    </g>
  </svg>
);

export default SvgComponent;
