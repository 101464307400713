import axios, { CancelTokenSource } from "axios";
import { deriveErrorMessage, toQueryString } from "@udok/lib/internal/util";
import {
  Location,
  CepResponse,
  ViaCepAddress,
  FilterGeoCity,
  GeoCity,
} from "@udok/lib/api/models";

export const createLocation = async (token: string, location: Location) => {
  return axios
    .post(`${process.env.REACT_APP_API_PATH}/location`, location, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as Location;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "L1");
      return Promise.reject(new Error(err));
    });
};

export const fetchLocations = (token: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/location`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.items as Location[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "L2");
      return Promise.reject(new Error(err));
    });
};

export const fetchLocation = (token: string, locaID: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/location/${locaID}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as Location;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "L3");
      return Promise.reject(new Error(err));
    });
};

export const deleteLocation = async (token: string, locaID: string) => {
  return axios
    .delete(`${process.env.REACT_APP_API_PATH}/location/${locaID}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as Location;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "L4");
      return Promise.reject(new Error(err));
    });
};

export const updateLocation = async (token: string, location: Location) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_PATH}/location/${location.locaID}`,
      location,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as Location;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "L5");
      return Promise.reject(new Error(err));
    });
};

export const searchLocation = async (token: string, cep: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/cepaberto/${cep}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r: any) => {
      return r.data as CepResponse;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "L6");
      return Promise.reject(new Error(err));
    });
};

export const searchLocationByViacepAPI = async (cep: string) => {
  return axios
    .get(`${process.env.REACT_APP_VIACEPAPI_PATH}/ws/${cep}/json/`)
    .then((r: any) => {
      return r.data as ViaCepAddress;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "L6");
      return Promise.reject(new Error(err));
    });
};

export const addDoctorHealthPlans = async (
  token: string,
  doctID: string,
  hps: Array<string>
) => {
  return Promise.all(
    hps.map((h) =>
      axios.post(
        `${process.env.REACT_APP_API_PATH}/plan-doctor`,
        { doctID, heplID: h },
        {
          headers: {
            Authorization: token,
          },
        }
      )
    )
  );
};

const makeCanceableGeoLocationsCity = () => {
  let cancel: CancelTokenSource | undefined;

  return async (filter?: FilterGeoCity) => {
    if (cancel) {
      cancel.cancel();
    }
    cancel = axios.CancelToken.source();
    const f = filter ? toQueryString(filter) : "";
    return axios
      .get(`${process.env.REACT_APP_API_PATH}/search/geo-locations/city${f}`, {
        cancelToken: cancel.token,
      })
      .then((r) => {
        return r.data.data.items as GeoCity[];
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return undefined;
        }
        const err = deriveErrorMessage(e, "glc1");
        return Promise.reject(new Error(err));
      });
  };
};

export const geoLocationsCity = makeCanceableGeoLocationsCity();
