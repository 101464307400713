import axios from "axios";
import { deriveErrorMessage } from "@udok/lib/internal/util";
import {
  UserProfile,
  ActiveSubscription,
  User,
  SEOListing,
  ClinicUser,
  FilterClinicUser,
  BillingInformation,
  BillingInformationForm,
  SubscriptionOffer,
  FilterSubscriptionOffer,
} from "./models";
import { toQueryString } from "@udok/lib/internal/util";

export const fetchUserMe = async (token: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/users/profile/me`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.item as UserProfile;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "usr1");
      return Promise.reject(new Error(err));
    });
};

export const updateUserProfile = async (token: string, u: UserProfile) => {
  return axios
    .put(`${process.env.REACT_APP_API_PATH}/users/profile/me`, u, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.item as UserProfile;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "usr2");
      return Promise.reject(new Error(err));
    });
};

export const oneTimeLogin = (validUntil: Date, apiToken: string) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/one-time-login`,
      {
        validUntil: validUntil.toISOString(),
      },
      {
        headers: {
          Authorization: apiToken,
        },
      }
    )
    .then((r) => {
      return r.data.data.item;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "usr3");
      return Promise.reject(new Error(err));
    });
};

export const fetchCurrentSubscription = async (token: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/subscriptions/current`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as ActiveSubscription;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sub1");
      return Promise.reject(new Error(err));
    });
};

export const fetchUserByID = async (token: string, userID: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/users/${userID}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as User;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "usr4");
      return Promise.reject(new Error(err));
    });
};

export type PrivateFileUpload = {
  file: File;
  name: string;
  permittedUsers: string[];
};
export type PrivateFileUploadedResponse = {
  fileID: string;
  userID: string;
  name: string;
  type: string;
  url: string;
  permittedUser: string[];
  metadata: any;
  createdAt: string;
};
export async function uploadPrivateFile(
  apiToken: string,
  { file, name, permittedUsers }: PrivateFileUpload
) {
  const data = new FormData();
  data.append("file", file);
  data.append("name", name);
  permittedUsers.forEach((permittedUser) => {
    data.append("permittedUser", permittedUser);
  });

  return axios
    .post(`${process.env.REACT_APP_API_PATH}/private-files`, data, {
      headers: {
        Authorization: apiToken,
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })
    .then((r: any) => {
      const item = r.data.data.item as PrivateFileUploadedResponse;
      return item;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "usr5");
      return Promise.reject(new Error(err));
    });
}

export type VideoSessionResponse = {
  viseID: string;
  expiresAt: string;
  createAt: string;
  appoID?: string;
  participants: string[];
};
export async function createVideoSession(apiToken: string, users: string[]) {
  let expiresAt = new Date();
  expiresAt.setHours(expiresAt.getHours() + 4);
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/video-session`,
      {
        participants: users,
        expiresAt: expiresAt.toISOString(),
      },
      {
        headers: {
          Authorization: apiToken,
        },
      }
    )
    .then((r) => {
      const session: VideoSessionResponse = r.data.data.item;
      return session;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "usr6");
      return Promise.reject(new Error(err));
    });
}

export const fetchDoctorSEOListing = async (token: string, doctID: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/doctors/${doctID}/seo-listing`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as SEOListing;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "dseo1");
      return Promise.reject(new Error(err));
    });
};

export const updateDoctorSEOListing = async (
  token: string,
  listing: SEOListing
) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_PATH}/doctors/${listing.doctID}/seo-listing`,
      listing,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as SEOListing;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "dseo2");
      return Promise.reject(new Error(err));
    });
};

export const fetchClinicUser = async (token: string, userID: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/clinic-users/${userID}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as ClinicUser;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "clu1");
      return Promise.reject(new Error(err));
    });
};

export const fetchClinicUsers = async (
  token: string,
  filter?: FilterClinicUser
) => {
  const f = filter ? toQueryString(filter) : "";
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/clinic-users${f}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.items as ClinicUser[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "clu2");
      return Promise.reject(new Error(err));
    });
};

export const createClinicUser = async (token: string, u: ClinicUser) => {
  return axios
    .post(`${process.env.REACT_APP_API_PATH}/clinic-users`, u, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as ClinicUser;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "clu3");
      return Promise.reject(new Error(err));
    });
};

export const updateClinicUser = async (token: string, u: ClinicUser) => {
  return axios
    .put(`${process.env.REACT_APP_API_PATH}/clinic-users/${u.userID}`, u, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as ClinicUser;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "clu4");
      return Promise.reject(new Error(err));
    });
};

export const deleteClinicUser = async (token: string, userID: string) => {
  return axios
    .delete(`${process.env.REACT_APP_API_PATH}/clinic-users/${userID}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as ClinicUser;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "clu5");
      return Promise.reject(new Error(err));
    });
};

export const activateSubscription = async (
  token: string,
  form: { suofID: string; crcaID?: string }
) => {
  return axios
    .post(`${process.env.REACT_APP_API_PATH}/subscriptions/activate`, form, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as ActiveSubscription;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sub2");
      return Promise.reject(new Error(err));
    });
};

export const cancelSubscription = async (token: string, subsID: string) => {
  return axios
    .delete(
      `${process.env.REACT_APP_API_PATH}/subscriptions/cancel/${subsID}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as ActiveSubscription;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sub3");
      return Promise.reject(new Error(err));
    });
};

export const fetchSubscriptionOffers = async (
  filter?: FilterSubscriptionOffer
) => {
  const f = filter ? toQueryString(filter) : "";
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/subscriptions/offers${f}`)
    .then((r) => {
      return r.data.data.items as SubscriptionOffer[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sub4");
      return Promise.reject(new Error(err));
    });
};

export const fetchBillingInformation = async (token: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/billing-information`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as BillingInformation;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "bil1");
      return Promise.reject(new Error(err));
    });
};

export const createBillingInformation = async (
  token: string,
  form: BillingInformationForm
) => {
  return axios
    .post(`${process.env.REACT_APP_API_PATH}/billing-information`, form, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as BillingInformation;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "bil2");
      return Promise.reject(new Error(err));
    });
};

export const updateBillingInformation = async (
  token: string,
  form: BillingInformationForm
) => {
  return axios
    .put(`${process.env.REACT_APP_API_PATH}/billing-information`, form, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as BillingInformation;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "bil3");
      return Promise.reject(new Error(err));
    });
};
