import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M2 3.333a.667.667 0 100 1.333h12a.666.666 0 100-1.333H2zm0 4a.667.667 0 100 1.333h12a.666.666 0 100-1.333H2zm0 4a.665.665 0 00-.624.924.666.666 0 00.624.41h12a.666.666 0 100-1.334H2z" />
    </svg>
  );
}

export default SvgComponent;
