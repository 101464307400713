import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 64 64"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 3"
      height="1em"
      fill="currentColor"
      {...props}
    >
      <path d="M5 12h54v20H5z" fill="#c0dafc" />
      <path d="M19 16h6v12h-6z" fill="#f2f2f2" />
      <path d="M2 32h60v10H2z" fill="#5e87ca" />
      <path d="M59 37v-5H2v7h55a2 2 0 002-2z" fill="#78a0d4" />
      <path d="M2 6h60v6H2z" fill="#5e87ca" />
      <path d="M59 8V6H2v3h56a1 1 0 001-1z" fill="#78a0d4" />
      <path d="M29 16h6v12h-6zM39 16h6v12h-6zM49 16h6v12h-6z" fill="#f2f2f2" />
      <g fill="#fff">
        <path d="M49 16h4v10h-4zM39 16h4v10h-4zM29 16h4v10h-4zM19 16h4v10h-4z" />
      </g>
      <path d="M4 42v20h56V42" fill="#c0dafc" />
      <path
        d="M20 58V43.571L22.2 42H24v16zM44 58V43.571L41.8 42H40v16z"
        fill="#5e87ca"
      />
      <path d="M41.8 42H22.2l9.8-7z" fill="#3b5892" />
      <path d="M32 35l14 10 3-3-17-12-17 12 3 3z" fill="#4c6cb5" />
      <circle cx={32} cy={11} fill="#f0ae42" r={9} />
      <path
        d="M39 10a8.991 8.991 0 00-3.977-7.469 9 9 0 00-8.046 15.938A8.981 8.981 0 0039 10z"
        fill="#f9d266"
      />
      <path
        d="M36 9V8a3 3 0 00-3-3V4h-2v1a3 3 0 00-3 3v1a3 3 0 003 3v3a1 1 0 01-1-1v-1h-2v1a3 3 0 003 3v1h2v-1a3 3 0 003-3v-1a3 3 0 00-3-3V7a1 1 0 011 1v1zm-2 4v1a1 1 0 01-1 1v-3a1 1 0 011 1zm-3-3a1 1 0 01-1-1V8a1 1 0 011-1z"
        fill="#d5883e"
      />
      <path d="M9 16h6v12H9z" fill="#f2f2f2" />
      <path
        d="M18 62v-4h8v4M38 62v-4h8v4M29 62V52a3 3 0 013-3 3 3 0 013 3v10"
        fill="#4c6cb5"
      />
      <path d="M48 47h8v9h-8z" fill="#f2f2f2" />
      <path d="M48 47h6v7h-6z" fill="#fff" />
      <path d="M8 47h8v9H8z" fill="#f2f2f2" />
      <path d="M8 47h6v7H8zM9 16h4v10H9z" fill="#fff" />
    </svg>
  );
}

export default SvgComponent;
