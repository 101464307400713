import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 32 32"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      fill="currentColor"
      {...props}
    >
      <path d="M27.7 23H26V12.9h1.6c.4 0 .8-.3 1-.7.1-.4 0-.9-.4-1.1L16.9 3.2c-.2-.1-.5-.2-.7-.2s-.5 0-.7.2L4.2 11c-.3.3-.5.7-.4 1.1s.5.7 1 .7h1.7V23H4.9c-.5 0-1 .5-1 1v3.6c0 .6.5 1 1 1h22.8c.6 0 1-.4 1-1V24c0-.6-.4-1-1-1zM24 23h-2V12.9h2zM12.5 12.9H20V23h-7.5zm3.7-7.7l8.2 5.7H8zm-7.7 7.7h2V23h-2zm18.2 13.6H5.9V25h20.8z" />
    </svg>
  );
}

export default SvgComponent;
