import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import { UserProfile, User } from "@udok/lib/api/models";
import {
  fetchUserMe,
  updateUserProfile,
  oneTimeLogin,
  fetchUserByID,
  uploadPrivateFile,
  PrivateFileUploadedResponse,
  createVideoSession,
} from "@udok/lib/api/user";
import { getToken, getPayload, UNAUTHORIZED } from "./auth";

export type InvalidSubscription = {
  status: string;
  error: string;
};

export type InitialState = {
  myProfile?: UserProfile;
  userByID: { [k: string]: User | undefined };
};

// Reducers
const initialState: InitialState = {
  myProfile: undefined,
  userByID: {},
};

class UserSlice extends Hen<InitialState> {
  profileLoaded(v: UserProfile) {
    this.state.myProfile = v;
  }

  userLoaded(u: User) {
    this.state.userByID[u.userID] = u;
  }
}

export const [Reducer, actions] = hen(new UserSlice(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
const mainSelector = (state: RootState) => state.user;
const profileSelector = (state: RootState) => state.user.myProfile;

export const getUserMe = createSelector(profileSelector, (state) => {
  return {
    currentUser: state,
  };
});

export const getLoggedUser = createSelector(
  [profileSelector, getToken],
  (state, currentToken) => {
    const { token } = currentToken;
    return {
      currentUser: state,
      logged: Boolean(token?.raw?.trim?.()),
    };
  }
);

export const userByUserIDViewCreator = (
  state: RootState,
  props: { userID: string }
) =>
  createSelector(mainSelector, (state) => {
    const usr = state.userByID[props.userID];
    return {
      user: usr,
    };
  });

export const avatarByUserIDViewCreator = (props: { userID: string }) =>
  createSelector(mainSelector, (state) => {
    const usr = state.userByID[props.userID];
    return {
      src: usr?.avatar
        ? `${process.env.REACT_APP_BASE_PATH}/files/${usr?.avatar}`
        : "",
      name:
        usr?.patient?.name ??
        usr?.doctor?.name ??
        usr?.clinic?.name ??
        usr?.email ??
        "",
    };
  });

export const userLoadedView = createSelector(
  [profileSelector, getPayload],
  (myProfile, auth) => {
    return {
      profile: myProfile,
      payload: auth.payload,
    };
  }
);

// Actions
export function loadUserMe(silent?: boolean): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchUserMe(apiToken)
      .then((r) => {
        dispatch(actions.profileLoaded(r));
      })
      .catch((e) => {
        if (silent) {
          console.warn(e);
          return;
        }
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function updateUser(u: UserProfile): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return updateUserProfile(apiToken, u)
      .then((r) => {
        dispatch(actions.profileLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Perfil atualizado",
          })
        );
      })
      .catch((e) => {
        const message = typeof e === "string" ? e : e.message;
        dispatch(
          newNotification("general", {
            status: "error",
            message: message,
          })
        );
        throw e;
      });
  };
}

export function createOnetimeLogin(): AppThunk<Promise<string>> {
  return async (dispatch, getState) => {
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    let validUntil = new Date();
    validUntil.setHours(validUntil.getHours() + 1);
    return oneTimeLogin(validUntil, apiToken)
      .then((r) => {
        const otlogin = r;
        return otlogin.token;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: "Falha ao criar sessão de vídeo",
          }) as any
        );
        throw e;
      });
  };
}

export function fetchProfileForUserID(userID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const apiToken = "Bearer " + getState().auth.token.raw;
    return fetchUserByID(apiToken, userID)
      .then((r) => {
        dispatch(actions.userLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e,
          }) as any
        );
      });
  };
}

export function fetchCachedProfileForUserID(
  userID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const userExist = Boolean(state.user.userByID[userID]);
    if (userExist) {
      return Promise.resolve();
    }
    return dispatch(fetchProfileForUserID(userID));
  };
}

export function savePrivateFile(
  file: File,
  name: string,
  permittedUser: string
): AppThunk<Promise<PrivateFileUploadedResponse>> {
  return async (dispatch, getState) => {
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;

    return uploadPrivateFile(apiToken, {
      file,
      name,
      permittedUsers: [permittedUser],
    }).then((r) => {
      return r;
    });
  };
}

export function newVideoSession(usrs: string[]): AppThunk<Promise<string>> {
  return async (dispatch, getState) => {
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;

    return createVideoSession(apiToken, usrs).then((r) => {
      return r.viseID;
    });
  };
}
