import React from "react";
import { connect } from "react-redux";
import {
  dismissFirstNotificationFrom,
  getNotificationFrom,
  NotificationMessage,
} from "ducks/notification";

import { IconButton, Button, Snackbar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";

export interface NotificationProps {
  notification?: NotificationMessage;
  dismiss?: any;
}

export class Notification extends React.Component<NotificationProps> {
  handleClose = () => {
    this.props.dismiss();
  };

  render() {
    const note = this.props.notification;
    const defaultActions = [
      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={this.handleClose}
      >
        <CloseIcon />
      </IconButton>,
    ];

    const actions = note?.actions?.slice(0, 1).map((action) => (
      <Button component={Link} to={action.url} color="inherit" size="small">
        {action.name}
      </Button>
    ));
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={!!note?.message}
        autoHideDuration={6000}
        onClose={this.handleClose}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{note?.message ?? ""}</span>}
        action={actions ?? defaultActions}
      />
    );
  }
}

export default (section: string) =>
  connect(getNotificationFrom(section), (dispatch: any): any => ({
    dismiss: () => dispatch(dismissFirstNotificationFrom(section)),
  }))(Notification);
