import React from "react";
import Loadable from "react-loadable";
import Loader from "@udok/lib/components/PageLoader";
import { useLocation, useParams } from "react-router";

export const PasswordResetChoose = Loadable({
  loader: () => import("containers/Auth/PasswordResetChoose"),
  loading: Loader,
});

export const SignoutContainer = Loadable({
  loader: () => import("containers/Auth/SignoutContainer"),
  loading: Loader,
});
export const PasswordReset = Loadable({
  loader: () => import("containers/Auth/PasswordReset"),
  loading: Loader,
});

export const Signin = Loadable({
  loader: () => import("containers/Auth/SigninContainer"),
  loading: Loader,
});

export const TokenLogin = Loadable({
  loader: () => import("containers/Auth/FormTokenLogin"),
  loading: Loader,
});

export const PasswordResetSucess = Loadable({
  loader: () => import("containers/Auth/PasswordResetSucess"),
  loading: Loader,
});

const Onboarding = Loadable({
  loader: () => import("containers/Auth/AppointmentOnboarding"),
  loading: Loader,
});

const ImmediateCareOnboarding = Loadable({
  loader: () => import("containers/Auth/ImmediateCareOnboarding"),
  loading: Loader,
});

const UserOnboarding = Loadable({
  loader: () => import("containers/Auth/UserOnboarding"),
  loading: Loader,
});

export const OnboardingQueryParams = (p: any) => {
  const { sescID, markedAt } = useParams<{
    sescID: string;
    markedAt: string;
  }>();

  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const heplID = query.get("heplID") ?? undefined;
  const clinID = query.get("clinID") ?? undefined;
  const voucher = query.get("voucher") ?? undefined;
  const specialtySlug = query.get("specialtySlug") ?? undefined;
  const exprID = query.get("exprID") ?? undefined;
  const aptyID = query.get("aptyID") ?? undefined;

  return (
    <Onboarding
      sescID={sescID}
      markedAt={markedAt}
      heplID={heplID}
      clinID={clinID}
      exprID={exprID}
      aptyID={aptyID}
      voucher={voucher}
      specialtySlug={specialtySlug}
    />
  );
};

export const UserOnboardingQueryParams = () => {
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const clinID = query.get("clinID") ?? undefined;
  const clinic = query.get("clinic") ?? undefined;
  const doctor = query.get("doctor") ?? undefined;

  return <UserOnboarding createdFor={{ clinID, clinic, doctor }} />;
};

export const ImmediateCareOnboardingQueryParams = (p: any) => {
  const { imcsID } = useParams<{ imcsID: string }>();

  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const heplID = query.get("heplID") ?? undefined;
  const voucher = query.get("voucher") ?? undefined;

  return (
    <ImmediateCareOnboarding
      imcsID={imcsID}
      heplID={heplID}
      voucher={voucher}
    />
  );
};
