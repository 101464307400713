import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1.0625em"
      viewBox="0 0 16 17"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 1.345a.727.727 0 00.223.506l6.751 6.752-6.751 6.75a.726.726 0 101.027 1.028l7.265-7.265a.727.727 0 000-1.027L5.25.824a.727.727 0 00-1.25.52z"
        fill="#434952"
      />
    </svg>
  );
}

export default SvgComponent;
