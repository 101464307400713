import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    viewBox="0 0 512 512"
    {...props}
  >
    <path
      d="M387.545 115.24v370.461c0 14.525-11.774 26.298-26.298 26.298H56.111c-14.525 0-26.298-11.774-26.298-26.298V115.24c0-14.525 11.774-26.298 26.298-26.298h305.136c14.525 0 26.298 11.774 26.298 26.298z"
      style={{
        fill: "#3d9ae2",
      }}
    />
    <path
      d="M387.545 115.24v362.498H85.182c-14.525 0-26.298-11.773-26.298-26.298V88.942h302.364c14.524 0 26.297 11.774 26.297 26.298z"
      style={{
        fill: "#5aaae7",
      }}
    />
    <path
      d="m463.513 405.997-291.992 88.561c-13.902 4.215-28.582-3.634-32.798-17.525L31.194 122.508c-4.226-13.892 3.623-28.582 17.525-32.798l5.679-1.723L340.711 1.139c13.902-4.215 28.582 3.634 32.798 17.536l105.1 346.478 2.44 8.046c4.214 13.892-3.635 28.583-17.536 32.798z"
      style={{
        fill: "#96c8ef",
      }}
    />
    <path
      d="M478.608 365.153 192.285 451.99c-13.902 4.215-28.582-3.634-32.798-17.525L54.398 87.987 340.711 1.139c13.902-4.215 28.582 3.634 32.798 17.536l105.099 346.478z"
      style={{
        fill: "#c2dff6",
      }}
    />
    <path
      d="M219.765 177.405c11.798.766 17.589 1.529 20.288 10.429 2.322 7.654-.578 12.427-9.125 15.019-7.948 2.411-11.815.154-15.229-1.84-2.287-1.335-4.879-2.849-8.12-1.866-4.944 1.499-7.212 7.828-5.864 12.273 2.211 7.291 13.478 11.901 25.819 10.878l.942 3.103c1.563 5.151 7.024 8.07 12.175 6.508 5.151-1.563 8.07-7.024 6.508-12.175l-1.004-3.31c12.144-7.29 16.499-19.846 11.949-34.845-6.583-21.702-23.992-22.738-34.393-23.356l-1.216-.071c-10.338-.596-14.986-1.116-16.831-7.196-1.026-3.383-.566-5.834 1.491-7.951 2.153-2.218 5.513-3.565 7.952-4.306 5.895-1.788 9.603-1.11 12.309-.616 1.925.352 3.744.685 5.726.084 5.574-1.691 6.934-8.308 5.742-12.236-2.536-8.361-15.286-7.499-22.115-6.387l-.949-3.13c-1.563-5.151-7.024-8.07-12.174-6.509-5.151 1.563-8.07 7.024-6.508 12.175l1.304 4.299c-10.871 7.15-14.716 18.009-10.828 30.83 5.601 18.47 20.323 19.426 32.151 20.196zM377.748 222.445a7.79 7.79 0 0 0-9.712-5.191l-221.975 67.331a7.788 7.788 0 0 0 4.52 14.904l221.975-67.331a7.79 7.79 0 0 0 5.192-9.713zM390.708 265.173a7.79 7.79 0 0 0-9.712-5.191l-221.975 67.33a7.788 7.788 0 0 0 4.52 14.904l221.975-67.331a7.788 7.788 0 0 0 5.192-9.712zM403.668 307.9a7.79 7.79 0 0 0-9.712-5.191L171.982 370.04a7.788 7.788 0 0 0 4.52 14.904l221.975-67.331a7.789 7.789 0 0 0 5.191-9.713zM416.629 350.628a7.79 7.79 0 0 0-9.712-5.191l-221.975 67.331a7.788 7.788 0 0 0 4.52 14.904l221.975-67.331a7.79 7.79 0 0 0 5.192-9.713z"
      style={{
        fill: "#3d9ae2",
      }}
    />
    <path
      d="m151.483 58.543-14.909 4.516c-4.62-13.736-17.619-23.651-32.891-23.651-19.135 0-34.698 15.563-34.698 34.698v90.015c0 11.182 9.105 20.287 20.287 20.287s20.287-9.105 20.287-20.287V74.303a6.084 6.084 0 0 0-.685-2.834 6.24 6.24 0 0 0-5.565-3.416 6.256 6.256 0 0 0-6.25 6.25v77.255c0 4.298-3.488 7.787-7.787 7.787s-7.787-3.488-7.787-7.787V74.303c0-12.033 9.791-21.824 21.824-21.824 9.448 0 17.505 6.032 20.536 14.442a21.753 21.753 0 0 1 1.287 7.382v89.817c0 19.768-16.093 35.86-35.86 35.86-19.778 0-35.86-16.093-35.86-35.86V74.106c0-27.721 22.55-50.271 50.271-50.271 22.291 0 41.238 14.587 47.8 34.708z"
      style={{
        fill: "#1e81ce",
      }}
    />
  </svg>
);
export default SvgComponent;
