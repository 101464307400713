import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={36.064}
      height={36.073}
      viewBox="0 0 36.064 36.073"
      fill="#ff6e6e"
      {...props}
    >
      <g data-name={992686}>
        <g data-name="Grupo 22">
          <g data-name="Grupo 21">
            <path
              data-name="Caminho 33"
              d="M28.022 3.018A17.964 17.964 0 0015.191.229a1.544 1.544 0 00-.234.023A18.031 18.031 0 00.269 21.1a18.453 18.453 0 00.567 2.339 13.491 13.491 0 01-.688 9.736 1.5 1.5 0 001.755 2.089l6.733-1.84a17.873 17.873 0 009.36 2.649 18.151 18.151 0 003.6-.361 18.033 18.033 0 006.426-32.694zm2.53 23.338A15.024 15.024 0 019.72 30.548a1.5 1.5 0 00-1.229-.2L3.904 31.6a16.533 16.533 0 00-.2-9.056 15.308 15.308 0 01-.468-1.957A15.025 15.025 0 0115.27 3.251a1.737 1.737 0 00.215-.023 15.026 15.026 0 0115.067 23.128z"
            />
          </g>
        </g>
        <g data-name="Grupo 24">
          <g data-name="Grupo 23">
            <path
              data-name="Caminho 34"
              d="M18.038 8.871a1.5 1.5 0 00-1.5 1.5v9.016a1.502 1.502 0 103.005 0v-9.016a1.5 1.5 0 00-1.505-1.5z"
            />
          </g>
        </g>
        <g data-name="Grupo 26">
          <g data-name="Grupo 25">
            <path
              data-name="Caminho 35"
              d="M19.42 24.977a1.4 1.4 0 00-.316-.5 1.43 1.43 0 00-.24-.18 1.628 1.628 0 00-.541-.225 1.5 1.5 0 00-1.351.406 1.4 1.4 0 00-.316.5 1.417 1.417 0 000 1.142 1.355 1.355 0 00.811.811 1.413 1.413 0 001.141 0 1.756 1.756 0 00.5-.316 1.736 1.736 0 00.316-.5 1.417 1.417 0 000-1.142z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
