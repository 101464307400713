import React from "react";
import {
  makeStyles,
  createStyles,
  useTheme,
  Theme,
} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CircularProgress from "@material-ui/core/CircularProgress";
import { LoadingComponentProps } from "react-loadable";
import Icons from "@udok/lib/components/Icon";
import { Button, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%",
      flexGrow: 1,
      justifyContent: "center" as "center",
      alignItems: "center" as "center",
      alignContents: "center" as "center",
    },
    loader: {
      flex: "0 1 auto",
      alignSelf: "center" as "center",
    },
  })
);

type PageLoaderProps = {} & LoadingComponentProps;

const pageMissing = "missing";
const pageError = "error";

const PageLoader = (props: PageLoaderProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const [rc, setRC] = React.useState(0);
  const errorReason = props.error?.type ?? pageError;

  const handleReload = () => {
    // pass true to force cache
    // @ts-ignore
    window.location.reload(true);
  };

  const handleRetry = () => {
    if (rc > 1) {
      handleReload();
    }
    props?.retry?.();
    setRC(rc + 1);
  };

  return (
    <div className={classes.root}>
      {props.isLoading ? (
        <CircularProgress className={classes.loader} />
      ) : (
        <>
          {errorReason === pageMissing ? (
            <>
              <Typography align="center" variant="h3">
                {!isSmallerScreen ? (
                  <Icons.SystemUpdateAlt />
                ) : (
                  <Icons.SystemUpdate />
                )}
              </Typography>
              <Typography align="center" gutterBottom>
                O sistema precisa ser atualizado
              </Typography>
              <Button variant="contained" size="small" onClick={handleReload}>
                Atualizar agora
              </Button>
            </>
          ) : null}

          {errorReason === pageError ? (
            <>
              <Typography align="center" variant="h3">
                <Icons.SyncProblemOutlined />
              </Typography>
              <Typography align="center" gutterBottom>
                Verifique sua conexão e tente novamente
              </Typography>
              <Button variant="contained" size="small" onClick={handleRetry}>
                Recarregar
              </Button>
            </>
          ) : null}
        </>
      )}
    </div>
  );
};

export default PageLoader;
