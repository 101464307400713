import axios from "axios";
import { deriveErrorMessage } from "@udok/lib/internal/util";
import { CollectDocument } from "@udok/lib/api/models";

export const fetchCollectDocuments = async (token: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/collect-documents`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.items as CollectDocument[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "CD1");
      return Promise.reject(err);
    });
};

export const createCollectDocument = async (
  token: string,
  collectDocument: CollectDocument
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/collect-documents`,
      collectDocument,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as CollectDocument;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "CD2");
      return Promise.reject(err);
    });
};

export const fetchCollectDocument = async (token: string, codoID: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/collect-documents/${codoID}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as CollectDocument;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "CD3");
      return Promise.reject(err);
    });
};

export const editCollectDocument = async (
  token: string,
  collectDocument: CollectDocument
) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_PATH}/collect-documents/${collectDocument.codoID}`,
      collectDocument,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as CollectDocument;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "CD4");
      return Promise.reject(err);
    });
};

export type FileUploadResult = { filename: string };
export const uploadFile = async (token: string, file: File) => {
  const data = new FormData();
  data.append("file", file);

  return axios
    .post(`${process.env.REACT_APP_API_PATH}/files`, data, {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((r) => {
      return r.data.data.item as FileUploadResult;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "cli3");
      return Promise.reject(err);
    });
};

export const deleteCollectDocument = async (token: string, codoID: string) => {
  return axios
    .delete(`${process.env.REACT_APP_API_PATH}/collect-documents/${codoID}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as CollectDocument;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "CD5");
      return Promise.reject(err);
    });
};
