import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import { CollectDocument } from "@udok/lib/api/models";
import {
  fetchCollectDocuments,
  fetchCollectDocument,
  createCollectDocument,
  editCollectDocument,
  deleteCollectDocument,
  uploadFile,
} from "@udok/lib/api/collectDocument";
import { getToken, UNAUTHORIZED } from "./auth";

import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

export type InitialState = {
  collectDocumentByID: { [codoID: string]: CollectDocument };
};

// Reducers
const initialState: InitialState = {
  collectDocumentByID: {},
};

class DocumentsSlice extends Hen<InitialState> {
  collectDocumentsLoaded(v: CollectDocument[]) {
    v.forEach((s) => {
      this.state.collectDocumentByID[String(s.codoID)] = s;
    });
  }

  collectDocumentLoaded(v: CollectDocument) {
    this.state.collectDocumentByID[String(v.codoID)] = v;
  }

  removCollectDocument(v: CollectDocument) {
    delete this.state.collectDocumentByID[String(v.codoID)];
  }

  collectDocumentRemoved(v: CollectDocument) {
    delete this.state.collectDocumentByID[String(v.codoID)];
  }
}

export const [Reducer, actions] = hen(new DocumentsSlice(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
const mainSelector = (state: RootState) => state.collectDocument;

export const collectDocumentListView = createSelector(mainSelector, (state) => {
  return {
    list: Object.keys(state.collectDocumentByID).map(
      (codoID) => state.collectDocumentByID[codoID]
    ),
  };
});

export const oneCollectDocumentView = (
  state: RootState,
  props: { codoID: string }
) =>
  createSelector([mainSelector], (state) => {
    return {
      collectDocument: state.collectDocumentByID[props.codoID],
    };
  });

export const collectDocumentsView = (
  state: RootState,
  props: { codoIDList?: string[] }
) =>
  createSelector([mainSelector], (state) => {
    return {
      collectDocuments: (props?.codoIDList ?? []).map(
        (codoID) => state.collectDocumentByID[codoID] ?? {}
      ),
    };
  });

// Actions
export function loadAllClinicCollectDocuments(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchCollectDocuments(apiToken)
      .then((r) => {
        dispatch(actions.collectDocumentsLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function createOneCollectDocument(
  collectDocument: CollectDocument
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createCollectDocument(apiToken, collectDocument)
      .then((r) => {
        dispatch(actions.collectDocumentLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadOneCollectDocument(
  codoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchCollectDocument(apiToken, codoID)
      .then((r) => {
        dispatch(actions.collectDocumentLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function uploadOneFile(f: File): AppThunk<Promise<string>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return uploadFile(apiToken, f)
      .then((r) => {
        return r.filename;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function editOneCollectDocument(
  collectDocument: CollectDocument
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return editCollectDocument(apiToken, collectDocument)
      .then((r) => {
        dispatch(actions.collectDocumentLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function removeCollectDocument(codoID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return deleteCollectDocument(apiToken, codoID)
      .then((r) => {
        dispatch(actions.collectDocumentRemoved(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}
