import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2m0 2v14H5V5h14m-1.1 8.5c-.3-.5-1-.7-2.2-.7-.4 0-.8 0-1.2.1-.3-.2-.6-.4-.8-.6-.6-.5-1.2-1.4-1.5-2.5v-.1c.3-1.3.6-2.8 0-3.5-.3-.1-.5-.2-.7-.2h-.2c-.4 0-.7.4-.8.7-.4 1.3-.1 2 .2 3.2-.2.9-.6 1.8-1 2.8-.4.7-.7 1.3-1 1.8-.4.2-.7.3-.9.5-1.1.7-1.7 1.5-1.8 2v.5l.5.3c.2.2.3.2.5.2.8 0 1.7-.9 2.9-3h.1c1-.3 2.2-.5 3.9-.7 1 .5 2.2.7 2.9.7.4 0 .7-.1.9-.3.2-.2.3-.4.3-.6 0-.3 0-.5-.1-.6M6.8 17.3c0-.4.5-1 1.2-1.6.1-.1.3-.2.5-.3-.7 1.1-1.3 1.8-1.7 1.9m4.5-10.6s0-.1.1-.1h.1c.2.2.2.5.1 1.1v.2c-.1.2-.1.5-.2.8-.3-.9-.3-1.6-.1-2m-1.2 7.6H10c.1-.3.3-.6.5-1 .4-.8.8-1.6 1-2.3.4.9.9 1.6 1.5 2.1.1.1.3.2.4.3-.9.1-2.1.4-3.3.9m7.2-.1h-.2c-.4 0-1.1-.2-1.8-.5.1-.1.2-.1.2-.1 1.4 0 1.7.2 1.8.3l.1.1c0 .2 0 .2-.1.2z" />
    </svg>
  );
}

export default SvgComponent;
