import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M2.444 0A2.455 2.455 0 000 2.444v11.112A2.455 2.455 0 002.444 16h11.112A2.455 2.455 0 0016 13.556V2.444A2.455 2.455 0 0013.556 0H2.444zm0 1.333h11.112c.621 0 1.11.49 1.11 1.111v11.112c0 .621-.489 1.11-1.11 1.11H2.444c-.621 0-1.11-.489-1.11-1.11V2.444c0-.621.489-1.11 1.11-1.11zm1.778 9.778a.668.668 0 00-.624.924.668.668 0 00.624.41h7.556a.665.665 0 00.624-.925.666.666 0 00-.624-.409H4.222z" />
    </svg>
  );
}

export default SvgComponent;
