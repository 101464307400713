import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        isolation: "isolate",
      }}
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      fill="currentColor"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path
          d="M17.772 4.99h4v14.03h-3v-.01h-1V4.99zm-2 0h-8V9h-2V5.01c0-1.1.9-2 2-2h14a2 2 0 012 2v14c0 1.1-.9 1.98-2 1.98h-14c-1.1 0-2-.88-2-1.98V15h2v4.02h8.001l-.001-.03v-14zM10.228 16l4-4-4-4v3h-10v2h10v3z"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
