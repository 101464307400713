import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height={512}
      viewBox="0 0 64 64"
      width={512}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M18 2v10H8v38h44V2z" fill="#e6e9ed" />
      <path
        d="M12 56v6H8c-3.31 0-6-2.69-6-6 0-1.66.67-3.16 1.76-4.24A5.944 5.944 0 018 50h4zM61.15 54.47a1.803 1.803 0 010 3.06L52 62V50z"
        fill="#aab2bd"
      />
      <path d="M12 56h40v6H12z" fill="#69d6f4" />
      <path d="M12 50h40v6H12z" fill="#ff826e" />
      <path d="M18 2v10H8z" fill="#b4dd7f" />
      <path
        d="M62 23v6l-2.54.81c-.3 1.06-.72 2.07-1.24 3.02l1.22 2.36-4.25 4.24-2.36-1.21c-.27.15-.55.29-.83.42-.7.33-1.43.61-2.19.82L49 42h-6l-.81-2.54c-1.06-.3-2.07-.72-3.02-1.24l-2.36 1.21-4.25-4.24 1.22-2.36c-.52-.95-.94-1.96-1.24-3.02L30 29v-6l2.54-.81c.3-1.06.72-2.07 1.24-3.02l-1.22-2.36 4.25-4.25 2.36 1.22c.95-.52 1.96-.94 3.02-1.24L43 10h6l.81 2.54c.76.21 1.49.49 2.19.82.28.13.56.27.83.42l2.36-1.22 4.25 4.25-1.22 2.36c.52.95.94 1.96 1.24 3.02zm-6 3c0-5.52-4.48-10-10-10s-10 4.48-10 10 4.48 10 10 10 10-4.48 10-10z"
        fill="#ffc729"
      />
      <path
        d="M46 16c5.52 0 10 4.48 10 10s-4.48 10-10 10-10-4.48-10-10 4.48-10 10-10zm4 10c0-2.21-1.79-4-4-4s-4 1.79-4 4 1.79 4 4 4 4-1.79 4-4z"
        fill="#fcd770"
      />
      <g>
        <path d="M46 15c-6.065 0-11 4.935-11 11s4.935 11 11 11 11-4.935 11-11-4.935-11-11-11zm0 20c-4.962 0-9-4.037-9-9s4.038-9 9-9 9 4.037 9 9-4.038 9-9 9z" />
        <path d="M46 21c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5zm0 8c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3z" />
        <path d="M63 29.729V22.27l-2.734-.876a14.935 14.935 0 00-.919-2.229l1.312-2.549-5.274-5.274L53 12.568V1H17.586L7 11.586V49.08c-3.387.488-6 3.401-6 6.92 0 3.859 3.14 7 7 7h44.231l9.36-4.571.091-.051C62.507 57.862 63 56.973 63 56s-.493-1.862-1.318-2.378L53 49.376v-9.945l2.384 1.226 5.274-5.273-1.312-2.549c.363-.709.67-1.455.919-2.229zm-12-17.85c-.132-.047-.26-.101-.394-.144L49.729 9H42.27l-.876 2.734c-.774.249-1.52.557-2.229.919l-2.549-1.312-5.274 5.274 1.312 2.549a14.979 14.979 0 00-.919 2.229L29 22.271v7.459l2.734.876c.249.774.556 1.521.919 2.229l-1.312 2.549 5.274 5.273 2.548-1.311c.709.362 1.456.67 2.229.919L42.271 43h7.459l.876-2.734c.134-.043.262-.098.394-.144V49H9V13h10V3h32zM51 51v4H13v-4zm-38 6h38v4H13zm-2.586-46L17 4.414V11zM3 56c0-2.757 2.243-5 5-5h3v10H8c-2.757 0-5-2.243-5-5zm58 0a.798.798 0 01-.343.659L53 60.399v-8.798l7.657 3.74c.216.15.343.394.343.659zm-2.356-26.976l-.146.517a12.84 12.84 0 01-1.153 2.796l-.263.469 1.13 2.195-3.211 3.211-2.195-1.129-.468.263a13.01 13.01 0 01-2.796 1.152l-.517.146L48.271 41H43.73l-.755-2.356-.517-.146a13.01 13.01 0 01-2.796-1.152l-.468-.263-2.195 1.129-3.211-3.211 1.13-2.195-.263-.469a12.89 12.89 0 01-1.153-2.796l-.146-.517L31 28.271V23.73l2.356-.754.146-.517c.278-.986.667-1.927 1.153-2.797l.262-.469-1.129-2.194 3.211-3.212 2.195 1.13.468-.263a13.01 13.01 0 012.796-1.152l.517-.146.754-2.356h4.541l.755 2.356.517.146c.989.28 1.929.668 2.796 1.152l.468.263 2.195-1.13 3.211 3.212-1.129 2.194.262.469c.487.87.875 1.811 1.153 2.797l.146.517 2.356.753v4.541z" />
        <path d="M11 18c0 1.654 1.346 3 3 3h10a1.001 1.001 0 010 2h-5c-1.654 0-3 1.346-3 3s1.346 3 3 3h2a1.001 1.001 0 010 2h-7c-1.654 0-3 1.346-3 3s1.346 3 3 3h14v-2H14a1.001 1.001 0 010-2h7c1.654 0 3-1.346 3-3s-1.346-3-3-3h-2a1.001 1.001 0 010-2h5c1.654 0 3-1.346 3-3s-1.346-3-3-3H14a1.001 1.001 0 010-2h15v-2H14c-1.654 0-3 1.346-3 3zM11 41h2v2h-2zM15 41h14v2H15zM11 45h18v2H11zM35 45h2v2h-2zM31 45h2v2h-2zM39 45h2v2h-2zM47 5h2v2h-2zM39 5h2v2h-2zM43 5h2v2h-2z" />
      </g>
    </svg>
  );
}

export default SvgComponent;
