import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      isolation: "isolate",
    }}
    viewBox="0 0 18 18"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#a)" fillRule="evenodd">
      <path d="M2.75 8C1.24 8 0 9.24 0 10.75v4.5C0 16.76 1.24 18 2.75 18h4.5C8.76 18 10 16.76 10 15.25v-4.5C10 9.24 8.76 8 7.25 8h-4.5Zm0 1.5h4.5c.699 0 1.25.551 1.25 1.25v4.5a1.24 1.24 0 0 1-1.25 1.25h-4.5a1.24 1.24 0 0 1-1.25-1.25v-4.5c0-.699.551-1.25 1.25-1.25Z" />
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx={17.238}
        cy={0.75}
        rx={0.762}
        ry={0.75}
      />
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx={17.238}
        cy={17.25}
        rx={0.762}
        ry={0.75}
      />
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx={11.594}
        cy={17.25}
        rx={0.762}
        ry={0.75}
      />
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx={14.416}
        cy={17.25}
        rx={0.762}
        ry={0.75}
      />
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx={17.238}
        cy={9}
        rx={0.762}
        ry={0.75}
      />
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx={7.823}
        cy={0.75}
        rx={0.762}
        ry={0.75}
      />
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx={17.238}
        cy={14.5}
        rx={0.762}
        ry={0.75}
      />
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx={10.177}
        cy={0.75}
        rx={0.762}
        ry={0.75}
      />
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx={12.53}
        cy={0.75}
        rx={0.762}
        ry={0.75}
      />
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx={14.884}
        cy={0.75}
        rx={0.762}
        ry={0.75}
      />
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx={17.238}
        cy={3.5}
        rx={0.762}
        ry={0.75}
      />
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx={17.238}
        cy={6.25}
        rx={0.762}
        ry={0.75}
      />
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx={0.762}
        cy={6.25}
        rx={0.762}
        ry={0.75}
      />
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx={0.762}
        cy={3.5}
        rx={0.762}
        ry={0.75}
      />
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx={0.762}
        cy={0.75}
        rx={0.762}
        ry={0.75}
      />
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx={17.238}
        cy={11.75}
        rx={0.762}
        ry={0.75}
      />
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx={3.116}
        cy={0.75}
        rx={0.762}
        ry={0.75}
      />
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx={5.47}
        cy={0.75}
        rx={0.762}
        ry={0.75}
      />
    </g>
  </svg>
);

export default SvgComponent;
