import React from "react";
import {
  createStyles,
  useTheme,
  Theme,
  makeStyles,
} from "@material-ui/core/styles";
import FullscreenDialog from "components/FullscreenDialog";
import {
  ActiveChatUserContext,
  ChatPosition,
} from "@udok/lib/components/UI/UIContext";
import { DirectMessageSimple } from "screens/admin/conversations";
import { IconButton, useMediaQuery, Slide, Tooltip } from "@material-ui/core";
import Icons from "@udok/lib/components/Icon";
import clsx from "clsx";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    chatWindow: {
      position: "fixed",
      zIndex: 1300,
      maxWidth: 600,
      width: "100%",
      padding: 0,
      bottom: 0,
      boxShadow: theme.shadows[6],
      height: 60,
    },
    chatPositionRight: {
      right: theme.spacing(3),
    },
    chatPositionLeft: {
      left: theme.spacing(9),
    },
    chatWindowOpen: {
      height: "65vh",
    },
    toolbar: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      display: "flex",
    },
    returnBtnActive: {
      display: "none",
    },
    iconstyle: {
      color: theme.palette.primary.light,
    },
  })
);

export interface FloatingChatProps {}

const FloatingChat = (props: FloatingChatProps) => {
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const [chatOpen, setChatOpen] = React.useState(true);
  const { chat, setChat } = React.useContext(ActiveChatUserContext);
  const userID = chat?.userID;
  const chatPosition = chat?.chatPosition ?? ChatPosition.right;
  const classes = useStyles();

  const chatMessages = userID ? (
    <DirectMessageSimple
      key={userID}
      userID={userID}
      megrID={chat?.megrID}
      apscID={chat?.apscID}
      rightToolbar={
        chat?.appoID || !isSmallerScreen ? (
          <div className={classes.toolbar}>
            {chat?.appoID ? (
              <Tooltip title="Voltar ao agendamento">
                <IconButton
                  component={NavLink}
                  to={`/appointments/${chat.appoID}`}
                  className={classes.iconstyle}
                  activeClassName={classes.returnBtnActive}
                >
                  <Icons.GoBackOutlined />
                </IconButton>
              </Tooltip>
            ) : null}
            {!isSmallerScreen ? (
              <>
                <IconButton
                  className={classes.iconstyle}
                  onClick={() => setChatOpen(!chatOpen)}
                >
                  {chatOpen ? (
                    <Icons.MinimizeOutlined />
                  ) : (
                    <Icons.ExpandOutlined />
                  )}
                </IconButton>
                <IconButton
                  className={classes.iconstyle}
                  onClick={() => setChat(null)}
                >
                  <Icons.Close />
                </IconButton>
              </>
            ) : null}
          </div>
        ) : null
      }
    />
  ) : null;

  if (isSmallerScreen) {
    return (
      <FullscreenDialog open={!!userID} onClose={() => setChat(null)}>
        {chatMessages}
      </FullscreenDialog>
    );
  }

  return (
    <Slide direction="up" in={!!userID}>
      <div
        className={clsx(chat?.classes?.chatWindow, classes.chatWindow, {
          [classes.chatPositionRight]: chatPosition === ChatPosition.right,
          [classes.chatPositionLeft]: chatPosition === ChatPosition.left,
          [classes.chatWindowOpen]: chatOpen,
        })}
      >
        {chatMessages}
      </div>
    </Slide>
  );
};

export default FloatingChat;
