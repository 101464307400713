import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 64 64"
    height={512}
    width={512}
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    {...props}
  >
    <path d="M59.506 27.903A27.322 27.322 0 0 0 51.47 9.43C46.267 4.226 39.352 1.361 32 1.361c-14.849 0-26.982 11.819-27.505 26.542a6.474 6.474 0 0 0-3.852 5.911v7.262a6.475 6.475 0 0 0 6.468 6.468 4.19 4.19 0 0 0 4.185-4.185V31.53c0-2.167-1.662-3.934-3.775-4.144C8.301 14.559 18.979 4.361 32 4.361c6.552 0 12.713 2.554 17.35 7.191 4.258 4.258 6.75 9.808 7.125 15.835-2.111.212-3.77 1.978-3.77 4.143v11.828c0 2.185 1.688 3.963 3.826 4.148v2.989a6.111 6.111 0 0 1-6.105 6.104h-4.521a4.497 4.497 0 0 0-4.267-3.038h-4.66c-.669 0-1.311.142-1.899.416a4.547 4.547 0 0 0-2.64 4.122c0 1.214.473 2.354 1.33 3.207a4.504 4.504 0 0 0 3.209 1.332h4.66c1.932 0 3.635-1.249 4.27-3.039h4.518c5.021 0 9.105-4.084 9.105-9.104v-3.523a6.473 6.473 0 0 0 3.826-5.898v-7.262c0-2.634-1.586-4.902-3.851-5.909zM8.295 31.53v11.828c0 .653-.531 1.185-1.185 1.185a3.472 3.472 0 0 1-3.468-3.468v-7.262a3.472 3.472 0 0 1 3.468-3.468c.654 0 1.185.532 1.185 1.185zm34.848 26.891a1.546 1.546 0 0 1-1.506 1.219h-4.66c-.41 0-.795-.16-1.089-.454a1.523 1.523 0 0 1-.45-1.085 1.542 1.542 0 0 1 1.539-1.538h4.66c.41 0 .795.159 1.088.453.29.289.45.675.45 1.085.001.11-.011.22-.032.32zm17.214-17.346a3.472 3.472 0 0 1-3.468 3.468 1.186 1.186 0 0 1-1.185-1.185V31.53c0-.653.531-1.185 1.185-1.185a3.472 3.472 0 0 1 3.468 3.468z" />
    <path d="M41.713 41.592a6.363 6.363 0 0 0 6.356-6.356V22.285c0-1.694-.662-3.29-1.864-4.492s-2.797-1.864-4.492-1.864H22.287a6.363 6.363 0 0 0-6.356 6.356v12.951a6.363 6.363 0 0 0 6.356 6.356h.119v3.356a3.109 3.109 0 0 0 3.112 3.124 3.05 3.05 0 0 0 2.196-.927l5.583-5.553zm-10.091-2.564-6.042 6.009c-.027.028-.047.049-.104.024-.069-.028-.069-.073-.069-.113v-4.856a1.5 1.5 0 0 0-1.5-1.5h-1.619a3.36 3.36 0 0 1-3.356-3.356V22.285a3.36 3.36 0 0 1 3.356-3.356h19.426c.894 0 1.735.35 2.371.985.636.636.985 1.478.985 2.371v12.951a3.36 3.36 0 0 1-3.356 3.356H32.68c-.397 0-.777.157-1.058.436z" />
    <path d="M24.713 26.787c-1.22 0-2.213.994-2.213 2.213s.994 2.213 2.213 2.213c1.221 0 2.215-.994 2.215-2.213s-.993-2.213-2.215-2.213zM31.999 26.787c-1.22 0-2.213.994-2.213 2.213s.994 2.213 2.213 2.213c1.222 0 2.215-.994 2.215-2.213s-.993-2.213-2.215-2.213zM39.285 26.787c-1.22 0-2.213.994-2.213 2.213s.994 2.213 2.213 2.213c1.221 0 2.215-.994 2.215-2.213s-.994-2.213-2.215-2.213z" />
  </svg>
);

export default SvgComponent;
