import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ isolation: "isolate" }}
    viewBox="0 0 1200 1000"
    width="1em"
    height="1em"
    fill="currentColor"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M883.1 332v421.5c0 32.2-26.2 58.4-58.5 58.4H392.7c-32.3 0-58.4-26.2-58.4-58.4V332c0-32.3 26.1-58.4 58.4-58.4h7.6v-34c0-6.6 5.4-12 12-12h6c4.5 0 8.4 2.4 10.5 6.1 1 1.7 1.6 3.7 1.6 5.9v34H497v-34c0-6.6 5.4-12 12-12h6c4.5 0 8.4 2.5 10.5 6.1 1 1.7 1.5 3.8 1.5 5.9v34h66.6v-34c0-6.6 5.4-12 12.1-12h6c4.4 0 8.2 2.4 10.3 5.9 1.1 1.8 1.7 3.9 1.7 6.1v34h66.6v-34c0-6.6 5.4-12 12-12h6c4.5 0 8.3 2.4 10.4 5.9 1 1.8 1.7 3.9 1.7 6.1v34H787v-34c0-6.6 5.3-12 12-12h6c4.5 0 8.4 2.4 10.4 6.1 1 1.7 1.6 3.7 1.6 5.9v34h7.6c32.3 0 58.5 26.1 58.5 58.4Z"
        fill="#D1D3D4"
      />
      <path
        d="M862 321.5v410.9c0 32.3-26.1 58.5-58.4 58.5H371.7c-32.3 0-58.4-26.2-58.4-58.5V321.5c0-32.3 26.1-58.4 58.4-58.4h431.9c32.3 0 58.4 26.1 58.4 58.4Z"
        fill="#D1D3D4"
      />
      <path
        d="M862 311v410.9c0 32.3-26.1 58.4-58.4 58.4H371.7c-32.3 0-58.4-26.1-58.4-58.4V311c0-32.3 26.1-58.4 58.4-58.4h431.9c32.3 0 58.4 26.1 58.4 58.4Z"
        fill="#FFF"
      />
      <path
        d="M862 311v94.7H313.3V311c0-32.3 26.1-58.4 58.4-58.4h431.9c32.3 0 58.4 26.1 58.4 58.4Z"
        fill="#0345B7"
      />
      <path
        d="M491 302.7c-9.1 0-16.6-7.4-16.6-16.6 0-9.1 7.5-16.5 16.6-16.5 9.1 0 16.5 7.4 16.5 16.5 0 9.2-7.4 16.6-16.5 16.6Z"
        fill="#414042"
      />
      <path
        d="M506 218.6v66.3c0 6.6-5.4 12-12 12h-3v-90.3h3c6.6 0 12 5.4 12 12Z"
        fill="#F1F2F2"
      />
      <path
        d="M506 284.9c0 6.6-5.4 12-12 12h-6c-6.7 0-12-5.4-12-12v-66.3c0-6.6 5.3-12 12-12h6c6.6 0 12 5.4 12 12v66.3Z"
        fill="#546885"
      />
      <path
        d="M394.3 302.7c-9.1 0-16.5-7.4-16.5-16.6 0-9.1 7.4-16.5 16.5-16.5s16.5 7.4 16.5 16.5c0 9.2-7.4 16.6-16.5 16.6Z"
        fill="#414042"
      />
      <path
        d="M409.3 218.6v66.3c0 6.6-5.3 12-12 12h-3v-90.3h3c6.7 0 12 5.4 12 12Z"
        fill="#F1F2F2"
      />
      <path
        d="M409.3 284.9c0 6.6-5.3 12-12 12h-6c-6.6 0-12-5.4-12-12v-66.3c0-6.6 5.4-12 12-12h6c6.7 0 12 5.4 12 12v66.3Z"
        fill="#546885"
      />
      <path
        d="M587.6 302.7c-9.1 0-16.5-7.4-16.5-16.6 0-9.1 7.4-16.5 16.5-16.5 9.2 0 16.6 7.4 16.6 16.5 0 9.2-7.4 16.6-16.6 16.6Z"
        fill="#414042"
      />
      <path
        d="M602.7 218.6v66.3c0 6.6-5.4 12-12 12h-3.1v-90.3h3.1c6.6 0 12 5.4 12 12Z"
        fill="#F1F2F2"
      />
      <path
        d="M602.7 284.9c0 6.6-5.4 12-12 12h-6.1c-6.6 0-12-5.4-12-12v-66.3c0-6.6 5.4-12 12-12h6.1c6.6 0 12 5.4 12 12v66.3Z"
        fill="#546885"
      />
      <path
        d="M684.3 302.7c-9.1 0-16.5-7.4-16.5-16.6 0-9.1 7.4-16.5 16.5-16.5s16.5 7.4 16.5 16.5c0 9.2-7.4 16.6-16.5 16.6Z"
        fill="#414042"
      />
      <path
        d="M699.3 218.6v66.3c0 6.6-5.3 12-12 12h-3v-90.3h3c6.7 0 12 5.4 12 12Z"
        fill="#F1F2F2"
      />
      <path
        d="M699.3 284.9c0 6.6-5.3 12-12 12h-6c-6.6 0-12-5.4-12-12v-66.3c0-6.6 5.4-12 12-12h6c6.7 0 12 5.4 12 12v66.3Z"
        fill="#546885"
      />
      <path
        d="M781 302.7c-9.2 0-16.6-7.4-16.6-16.6 0-9.1 7.4-16.5 16.6-16.5 9.1 0 16.5 7.4 16.5 16.5 0 9.2-7.4 16.6-16.5 16.6Z"
        fill="#414042"
      />
      <path
        d="M796 218.6v66.3c0 6.6-5.4 12-12 12h-3v-90.3h3c6.6 0 12 5.4 12 12Z"
        fill="#F1F2F2"
      />
      <path
        d="M796 284.9c0 6.6-5.4 12-12 12h-6c-6.7 0-12.1-5.4-12.1-12v-66.3c0-6.6 5.4-12 12.1-12h6c6.6 0 12 5.4 12 12v66.3Z"
        fill="#546885"
      />
      <path
        d="M862 396.5v9.2H313.3v-9.2H862ZM862 405.7v9.2H313.3v-9.2H862Z"
        fill="#E6E7E8"
      />
      <path
        d="M406.3 513.9h-23.9c-8.6 0-15.6-6.9-15.6-15.5v-23.9c0-8.6 7-15.5 15.6-15.5h23.8c8.6 0 15.6 6.9 15.6 15.5v23.9c0 8.6-7 15.5-15.6 15.5h.1ZM502.9 513.9H479c-8.5 0-15.5-6.9-15.5-15.5v-23.9c0-8.6 7-15.5 15.5-15.5h23.9c8.6 0 15.6 6.9 15.6 15.5v23.9c0 8.6-7 15.5-15.6 15.5ZM599.6 513.9h-23.9c-8.6 0-15.5-6.9-15.5-15.5v-23.9c0-8.6 6.9-15.5 15.5-15.5h23.9c8.6 0 15.5 6.9 15.5 15.5v23.9c0 8.6-6.9 15.5-15.5 15.5ZM696.2 513.9h-23.8c-8.6 0-15.6-6.9-15.6-15.5v-23.9c0-8.6 7-15.5 15.6-15.5h23.8c8.6 0 15.6 6.9 15.6 15.5v23.9c0 8.6-7 15.5-15.6 15.5ZM792.9 513.9H769c-8.5 0-15.5-6.9-15.5-15.5v-23.9c0-8.6 7-15.5 15.5-15.5h23.9c8.6 0 15.6 6.9 15.6 15.5v23.9c0 8.6-7 15.5-15.6 15.5ZM406.3 611.7h-23.9c-8.6 0-15.6-7-15.6-15.6v-23.8c0-8.6 7-15.6 15.6-15.6h23.8c8.6 0 15.6 7 15.6 15.6v23.8c0 8.6-7 15.6-15.6 15.6h.1ZM502.9 611.7H479c-8.5 0-15.5-7-15.5-15.6v-23.8c0-8.6 7-15.6 15.5-15.6h23.9c8.6 0 15.6 7 15.6 15.6v23.8c0 8.6-7 15.6-15.6 15.6ZM599.6 611.7h-23.9c-8.6 0-15.5-7-15.5-15.6v-23.8c0-8.6 6.9-15.6 15.5-15.6h23.9c8.6 0 15.5 7 15.5 15.6v23.8c0 8.6-6.9 15.6-15.5 15.6ZM696.2 611.7h-23.8c-8.6 0-15.6-7-15.6-15.6v-23.8c0-8.6 7-15.6 15.6-15.6h23.8c8.6 0 15.6 7 15.6 15.6v23.8c0 8.6-7 15.6-15.6 15.6ZM792.9 611.7H769c-8.5 0-15.5-7-15.5-15.6v-23.8c0-8.6 7-15.6 15.5-15.6h23.9c8.6 0 15.6 7 15.6 15.6v23.8c0 8.6-7 15.6-15.6 15.6ZM406.3 709.4h-23.9c-8.6 0-15.6-7-15.6-15.5V670c0-8.6 7-15.6 15.6-15.6h23.8c8.6 0 15.6 7 15.6 15.6v23.9c0 8.5-7 15.5-15.6 15.5h.1Z"
        fill="#B2BDCF"
      />
      <path
        d="M502.9 709.4H479c-8.5 0-15.5-7-15.5-15.5V670c0-8.6 7-15.6 15.5-15.6h23.9c8.6 0 15.6 7 15.6 15.6v23.9c0 8.5-7 15.5-15.6 15.5Z"
        fill="#0B93D8"
      />
      <path
        d="M599.6 709.4h-23.9c-8.6 0-15.5-7-15.5-15.5V670c0-8.6 6.9-15.6 15.5-15.6h23.9c8.6 0 15.5 7 15.5 15.6v23.9c0 8.5-6.9 15.5-15.5 15.5ZM696.2 709.4h-23.8c-8.6 0-15.6-7-15.6-15.5V670c0-8.6 7-15.6 15.6-15.6h23.8c8.6 0 15.6 7 15.6 15.6v23.9c0 8.5-7 15.5-15.6 15.5ZM792.9 709.4H769c-8.5 0-15.5-7-15.5-15.5V670c0-8.6 7-15.6 15.5-15.6h23.9c8.6 0 15.6 7 15.6 15.6v23.9c0 8.5-7 15.5-15.6 15.5Z"
        fill="#B2BDCF"
      />
      <path
        d="m459.8 655.9-52.4-16.6c-14.1 6.8-29.7 10.4-45.4 10.4-58.3 0-105.8-47.4-105.8-105.7 0-58.2 47.5-105.6 105.8-105.6 58.2 0 105.6 47.4 105.6 105.6 0 17.5-4.4 34.7-12.6 49.9l12.6 54.8c1.1 4.6-3.3 8.6-7.8 7.2Z"
        fill="#D1D3D4"
      />
      <path
        d="M447.3 647.5 395 630.9c-14.1 6.8-29.7 10.4-45.5 10.4-58.3 0-105.7-47.4-105.7-105.7 0-58.2 47.4-105.6 105.7-105.6 58.3 0 105.7 47.4 105.7 105.6 0 17.5-4.5 34.7-12.6 49.9l12.6 54.8c1.1 4.6-3.3 8.6-7.9 7.2Z"
        fill="#0345B7"
      />
      <path
        d="m343.9 555.1-3.3-49.6q-.9-14.5-.9-20.9 0-8.6 4.5-13.4 4.5-4.8 11.9-4.8 8.9 0 11.9 6.2 3 6.1 3 17.7 0 6.9-.8 13.9l-4.4 51.1q-.7 9.1-3.1 14-2.4 4.8-7.9 4.8-5.6 0-7.8-4.7-2.1-4.7-3.1-14.3ZM355.4 616.5q-6.3 0-11-4-4.7-4.1-4.7-11.5 0-6.4 4.5-10.9t11-4.5q6.6 0 11.2 4.5T371 601q0 7.3-4.7 11.4-4.6 4.1-10.9 4.1Z"
        fill="#D1D3D4"
      />
      <path
        d="m338.7 549.8-3.3-49.6q-1-14.5-1-20.8 0-8.6 4.5-13.4 4.5-4.8 11.9-4.8 8.9 0 11.9 6.1 3 6.2 3 17.8 0 6.8-.7 13.9l-4.5 51q-.7 9.1-3.1 14-2.4 4.9-7.8 4.9-5.6 0-7.8-4.7t-3.1-14.4ZM350.2 611.3q-6.3 0-11.1-4.1-4.7-4.1-4.7-11.4 0-6.5 4.5-11t11.1-4.5q6.5 0 11.1 4.5t4.6 11q0 7.2-4.6 11.3-4.7 4.2-10.9 4.2Z"
        fill="#FFF"
      />
      <path
        d="M1028.7 685.6c0 87.4-70.8 158.3-158.3 158.3-87.4 0-158.2-70.9-158.2-158.3S783 527.3 870.4 527.3c87.5 0 158.3 70.9 158.3 158.3Z"
        fill="#D1D3D4"
      />
      <path
        d="M1020.3 677.2c0 87.4-70.8 158.3-158.3 158.3-43.7 0-83.2-17.7-111.9-46.4L974 565.3c28.6 28.6 46.3 68.2 46.3 111.9Z"
        fill="#D1D3D4"
      />
      <path
        d="M862 835.5c-87.4 0-158.3-70.9-158.3-158.3S774.6 518.9 862 518.9c87.5 0 158.3 70.9 158.3 158.3S949.5 835.5 862 835.5Z"
        fill="#546885"
      />
      <path
        d="M992.6 677.2c0 72.1-58.5 130.5-130.6 130.5s-130.5-58.4-130.5-130.5S789.9 546.7 862 546.7s130.6 58.4 130.6 130.5Z"
        fill="#F1F2F2"
      />
      <path
        d="M748.3 741.2c-35.1-62.3-13.5-141.2 48.5-177 62-35.8 141.1-15 177.5 46.5-32.5-54.5-108.4-69.8-170.3-34-62 35.7-86.7 109.1-55.7 164.5Z"
        fill="#E6E7E8"
      />
      <path
        d="M869.3 566.8c0 4-3.2 7.3-7.3 7.3-4 0-7.2-3.3-7.2-7.3s3.2-7.3 7.2-7.3c4.1 0 7.3 3.3 7.3 7.3ZM813.1 577.9c2.1 3.5.9 8-2.6 10s-8 .8-10-2.7-.8-7.9 2.7-9.9 7.9-.8 9.9 2.6ZM770.1 615.7c3.4 2 4.6 6.5 2.6 10-2 3.4-6.4 4.6-9.9 2.6-3.5-2-4.7-6.5-2.7-9.9 2-3.5 6.5-4.7 10-2.7ZM751.6 669.9c4 0 7.3 3.3 7.3 7.3s-3.3 7.3-7.3 7.3-7.3-3.3-7.3-7.3 3.3-7.3 7.3-7.3ZM762.8 726.1c3.5-2 7.9-.8 9.9 2.7s.8 7.9-2.6 9.9c-3.5 2.1-8 .9-10-2.6s-.8-8 2.7-10ZM800.5 769.2c2-3.5 6.5-4.7 10-2.7s4.7 6.5 2.6 10c-2 3.5-6.4 4.7-9.9 2.7-3.5-2.1-4.7-6.5-2.7-10ZM854.8 787.6c0-4 3.2-7.3 7.2-7.3 4.1 0 7.3 3.3 7.3 7.3 0 4.1-3.2 7.3-7.3 7.3-4 0-7.2-3.2-7.2-7.3ZM910.9 776.5c-2-3.5-.8-8 2.7-10s8-.8 10 2.7.8 7.9-2.7 10c-3.5 2-7.9.8-10-2.7ZM954 738.7c-3.5-2-4.7-6.4-2.6-9.9 2-3.5 6.4-4.7 9.9-2.7s4.7 6.5 2.7 10-6.5 4.7-10 2.6ZM972.5 684.5c-4.1 0-7.3-3.3-7.3-7.3s3.2-7.3 7.3-7.3c4 0 7.3 3.3 7.3 7.3s-3.3 7.3-7.3 7.3ZM961.3 628.3c-3.5 2-7.9.8-9.9-2.6-2.1-3.5-.9-8 2.6-10s8-.8 10 2.7c2 3.4.8 7.9-2.7 9.9ZM923.6 585.2c-2 3.5-6.5 4.7-10 2.7s-4.7-6.5-2.7-10c2.1-3.4 6.5-4.6 10-2.6 3.5 2 4.7 6.4 2.7 9.9ZM862 700.7c-2.7 0-4.9-2.2-4.9-5V590.1c0-2.7 2.2-5 4.9-5 2.8 0 5 2.3 5 5v105.6c0 2.8-2.2 5-5 5Z"
        fill="#475870"
      />
      <path
        d="M933.3 683.6h-89.8c-3.5 0-6.4-2.8-6.4-6.4 0-3.5 2.9-6.4 6.4-6.4h89.8c3.6 0 6.4 2.9 6.4 6.4 0 3.6-2.8 6.4-6.4 6.4Z"
        fill="#475870"
      />
      <path
        d="M876 677.2c0 7.7-6.2 14-14 14-7.7 0-13.9-6.3-13.9-14s6.2-14 13.9-14c7.8 0 14 6.3 14 14Z"
        fill="#475870"
      />
    </g>
  </svg>
);

export default SvgComponent;
