import React from "react";
import {
  createStyles,
  useTheme,
  Theme,
  makeStyles,
} from "@material-ui/core/styles";
import AlertButton from "./AlertButton";
import { ActiveChatUserContext } from "@udok/lib/components/UI/UIContext";
import { Conversations } from "screens/admin/conversations";
import { useMediaQuery } from "@material-ui/core";
import ItemComponent from "containers/Chat/Conversations/ItemComponent";
import Popover from "@udok/lib/components/ResponsivePopover";

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    popover: {
      height: 400,
      width: 400,
      [theme.breakpoints.down("sm")]: {
        height: "100%",
        width: "100%",
      },
    },
    chatWindow: {
      position: "fixed",
      zIndex: 999999,
      maxWidth: 600,
      width: "100%",
      padding: 0,
      bottom: 0,
      right: theme.spacing(3),
      boxShadow: theme.shadows[6],
      height: 60,
    },
    chatWindowOpen: {
      height: "65vh",
    },
    toolbar: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      display: "flex",
    },
    returnBtn: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      flex: 1,
      width: 200,
      backgroundColor: theme.palette.primary.lightest,
      color: theme.palette.primary.main,
    },
    returnBtnActive: {
      display: "none",
    },
  })
);

export interface AlertMenuProps {
  id: string;
  actionComponent?: (
    props:
      | { onClick: (event: React.MouseEvent<HTMLButtonElement>) => void }
      | any
  ) => React.ReactNode | React.ReactNodeArray | null;
}

function AlertMenu(props: AlertMenuProps) {
  const { actionComponent, id } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const ActionButton = (actionComponent ?? AlertButton) as any;
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });
  const { setChat } = React.useContext(ActiveChatUserContext);
  const classes = useStyles();

  const handleUserIDChange = React.useCallback(
    (userID: string) => {
      if (!isSmallerScreen) {
        handleClose();
      }
      setChat({ userID });
    },
    [isSmallerScreen, setChat]
  );

  const itemComponent = React.useCallback(
    (props: any) => {
      return <ItemComponent {...props} onClick={handleUserIDChange} />;
    },
    [handleUserIDChange]
  );

  return (
    <div>
      <ActionButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        small={isSmallerScreen}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className={classes.popover}>
          <Conversations listItemComponent={itemComponent} />
        </div>
      </Popover>
    </div>
  );
}

export default AlertMenu;
