import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 42 42"
    fill="currentColor"
    {...props}
  >
    <path
      fill="#DE3838"
      d="M20 18h-2v4h4v-2l19.46 19.46L38.92 42 26 29.08V38H4v-4h4V11.08l-8-8L2.54.54 20 18Zm6 .98L9.02 2H26v2h10v24.98l-4-4V8h-6v10.98Z"
    />
  </svg>
);
export default SvgComponent;
