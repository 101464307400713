import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={512}
      height={512}
      viewBox="0 0 512 512"
      {...props}
    >
      <g xmlns="http://www.w3.org/2000/svg">
        <ellipse cx={256} cy={256} rx={248.459} ry={248.5} fill="#043d8d" />
        <path
          d="M408.378 59.721c32.719 42.085 52.213 94.964 52.213 152.403 0 137.243-111.239 248.5-248.46 248.5-57.429 0-110.3-19.497-152.378-52.221C105.212 466.874 176.209 504.5 256 504.5c137.22 0 248.46-111.257 248.46-248.5 0-79.804-37.62-150.813-96.082-196.279z"
          fill="#072788"
        />
        <path
          d="M360.874 155.623H151.126c-13.267 0-24.022 10.757-24.022 24.026v134.413c0 13.269 10.755 24.026 24.022 24.026h51.991a13.39 13.39 0 019.466 3.921l36.702 36.708c5.297 5.298 13.884 5.298 19.181 0l36.701-36.708a13.383 13.383 0 019.466-3.921h46.242c13.267 0 24.022-10.757 24.022-24.026V179.649c-.001-13.269-10.756-24.026-24.023-24.026z"
          fill="#c5e4ff"
        />
        <path
          d="M271.494 342.008l-29.456 29.461 7.246 7.247c5.297 5.297 13.884 5.297 19.181 0l36.701-36.708a13.383 13.383 0 019.466-3.921H280.96a13.387 13.387 0 00-9.466 3.921zM360.874 155.623h-33.673c13.267 0 24.022 10.757 24.022 24.026v134.413c0 13.269-10.755 24.026-24.022 24.026h33.673c13.267 0 24.022-10.757 24.022-24.026V179.649c0-13.269-10.755-24.026-24.022-24.026z"
          fill="#97d5f3"
        />
        <g>
          <path
            d="M273.467 292.101h-76.383c-9.094 0-16.466-7.372-16.466-16.466v-55.354c0-9.094 7.372-16.466 16.466-16.466h76.383c9.094 0 16.466 7.372 16.466 16.466v55.354c.001 9.094-7.372 16.466-16.466 16.466z"
            fill="#1149b5"
          />
          <path
            d="M273.469 203.815h-20.781c9.093 0 16.465 7.373 16.465 16.467v55.352c0 9.095-7.371 16.467-16.465 16.467h20.781c9.093 0 16.465-7.373 16.465-16.467v-55.352c0-9.094-7.372-16.467-16.465-16.467z"
            fill="#06398d"
          />
          <path
            d="M325.141 285.727l-35.207-16.759v-42.019l35.207-16.759c2.896-1.379 6.24.734 6.24 3.942v67.654c0 3.207-3.344 5.319-6.24 3.941z"
            fill="#1149b5"
          />
          <path
            d="M325.141 210.189l-5.558 2.646c.125.406.2.838.2 1.296v67.654c0 .458-.075.89-.2 1.296l5.558 2.646c2.896 1.379 6.241-.734 6.241-3.942v-67.654c-.001-3.208-3.345-5.321-6.241-3.942z"
            fill="#06398d"
          />
        </g>
      </g>
      <g xmlns="http://www.w3.org/2000/svg">
        <path
          d="M436.991 74.98C388.646 26.629 324.369 0 256 0S123.354 26.629 75.009 74.98C26.665 123.333.041 187.62.041 256s26.624 132.667 74.968 181.02C123.354 485.371 187.631 512 256 512c55.984 0 109.156-17.748 153.767-51.325a7.498 7.498 0 001.482-10.502 7.498 7.498 0 00-10.502-1.482C358.757 480.295 308.704 497 256 497c-64.362 0-124.873-25.068-170.384-70.587S15.041 320.374 15.041 256 40.105 131.105 85.616 85.587 191.638 15 256 15s124.873 25.068 170.384 70.587S496.959 191.626 496.959 256c0 62.888-24.074 122.401-67.788 167.58a7.497 7.497 0 00.175 10.604 7.498 7.498 0 0010.604-.175c46.436-47.99 72.009-111.209 72.009-178.01 0-68.379-26.624-132.666-74.968-181.019z"
          fill="#001567"
        />
        <path
          d="M220.08 148.123h-68.954c-17.381 0-31.521 14.143-31.521 31.525v134.413c0 17.383 14.141 31.525 31.521 31.525h51.991c1.572 0 3.05.612 4.162 1.725l36.701 36.707c3.979 3.979 9.268 6.171 14.895 6.171s10.916-2.191 14.894-6.171l36.704-36.709a5.844 5.844 0 014.159-1.723h46.242c17.381 0 31.521-14.143 31.521-31.525V179.648c0-17.383-14.141-31.525-31.521-31.525H256c-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5h104.874c9.11 0 16.521 7.413 16.521 16.525v134.413c0 9.112-7.411 16.525-16.521 16.525h-46.242a20.744 20.744 0 00-14.769 6.119l-36.703 36.707c-1.145 1.146-2.666 1.776-4.285 1.776s-3.142-.631-4.287-1.776l-36.7-36.707a20.748 20.748 0 00-14.771-6.119h-51.991c-9.11 0-16.521-7.413-16.521-16.525V179.648c0-9.112 7.411-16.525 16.521-16.525h68.954c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5z"
          fill="#001567"
        />
        <path
          d="M173.118 220.281v55.354c0 13.216 10.751 23.967 23.967 23.967h76.383c11.514 0 21.155-8.163 23.446-19.005l25.003 11.902a11.868 11.868 0 005.107 1.161 11.834 11.834 0 006.327-1.842 11.804 11.804 0 005.53-10.032v-67.654c0-4.096-2.067-7.846-5.53-10.032a11.809 11.809 0 00-11.435-.681l-25.003 11.902c-2.292-10.843-11.932-19.005-23.446-19.005h-76.383c-13.215-.002-23.966 10.749-23.966 23.965zm150.764-1.186v57.727l-26.448-12.59v-32.547l26.448-12.59zm-41.448 1.186V275.635c0 4.944-4.022 8.967-8.966 8.967h-76.383c-4.944 0-8.967-4.022-8.967-8.967v-55.354c0-4.944 4.022-8.967 8.967-8.967h76.383c4.943 0 8.966 4.023 8.966 8.967z"
          fill="#001567"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
