import { createMuiTheme } from "@material-ui/core/styles";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import themestyles from "./themestyles.json";

declare module "@material-ui/core/styles/createPalette" {
  export interface PaletteColor {
    lightest?: string;
    darkest?: string;
    ultralight?: string;
  }
  export interface PaletteOptions {
    neutral?: PaletteColorOptions;
  }

  interface Palette {
    neutral: PaletteColor;
  }
}

const importedPalette = Object.keys(themestyles.colors).reduce((a, b) => {
  const addr = b.split(".");
  const paletteOption = addr?.[1];
  const colorOption = addr?.[2];
  return {
    ...a,
    [paletteOption]: {
      // @ts-ignore
      ...(a[paletteOption] ?? {}),
      [colorOption]: themestyles.colors[b as keyof typeof themestyles.colors],
    },
  };
}, {} as PaletteOptions);

let theme = createMuiTheme({
  palette: {
    primary: {
      light: "#82C3FE",
      main: "#0345B7",
      dark: "#003A8E",
    },
    secondary: {
      main: "#E84052",
      contrastText: "#fff",
    },
    ...importedPalette,
  },
  typography: {
    h1: {
      fontWeight: 500,
      fontSize: 48,
    },
    h2: {
      fontWeight: 500,
      fontSize: 40,
    },
    h3: {
      fontWeight: 500,
      fontSize: 32,
    },
    h4: {
      fontWeight: 500,
      fontSize: 24,
    },
    h5: {
      fontWeight: 500,
      fontSize: 20,
    },
    h6: {
      fontWeight: 500,
      fontSize: 18,
    },
    body1: {
      fontFamily: "Inter, sans-serif",
    },
    body2: {
      fontFamily: "Inter, sans-serif",
    },
    fontFamily: "'Poppins', sans-serif",
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: "#fff",
      },
    },
    MuiButton: {
      label: {
        textTransform: "none",
      },
      contained: {
        boxShadow: "none",
        "&:active": {
          boxShadow: "none",
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: "none",
        margin: "0 16px",
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up("md")]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiListItemIcon: {
      root: {
        color: "inherit",
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};

export default theme;
