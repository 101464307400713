import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { Popover, useMediaQuery, PopoverProps } from "@material-ui/core";
import FullscreenDialog from "@udok/lib/components/Dialog/FullscreenDialog";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popoverHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  })
);

export type ResponsivePopoverProps = {
  title?: React.ReactNode;
  popoverHeaderClassName?: string;
  actionItems?: React.ReactNode;
  dialogCloseOnGoBack?: boolean;
} & Omit<PopoverProps, "title">;

const ResponsivePopover = (props: ResponsivePopoverProps) => {
  const {
    title,
    actionItems,
    popoverHeaderClassName,
    open,
    children,
    onClose,
    dialogCloseOnGoBack,
    ...others
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  if (isSmallerScreen) {
    return (
      <FullscreenDialog
        title={title}
        actionItems={actionItems}
        open={open}
        onClose={onClose}
        closeOnGoBack={dialogCloseOnGoBack}
      >
        {children}
      </FullscreenDialog>
    );
  }

  return (
    <Popover open={open} onClose={onClose} {...others}>
      {title ? (
        <div className={clsx(classes.popoverHeader, popoverHeaderClassName)}>
          {title} <div>{actionItems}</div>
        </div>
      ) : null}
      {children}
    </Popover>
  );
};

export default ResponsivePopover;
