import ReactGA from "react-ga4";

export enum AnalyticsCategories {
  Appointment = "Appointment",
}

export enum AnalyticsEvents {
  AppointmentCreated = "appointment_created",
  AppointmentCallStartButtonPressed = "appointment_call_start_button_pressed",
}

export function emitAppointmentCreated(appoID: string) {
  ReactGA.event({
    category: AnalyticsCategories.Appointment,
    action: AnalyticsEvents.AppointmentCreated,
    label: appoID,
  });
}

export function registerLoggedInUserID(userID: string) {
  ReactGA.set({ userId: userID });
}

export function emitCallInitiated(appoID: string) {
  ReactGA.event({
    category: AnalyticsCategories.Appointment,
    action: AnalyticsEvents.AppointmentCallStartButtonPressed,
    label: appoID,
  });
}
