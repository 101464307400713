import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import { EventAlert, FilterEventAlert } from "@udok/lib/api/models";
import { fetchEventAlerts, addEventAlertReadDate } from "@udok/lib/api/events";
import { getToken, UNAUTHORIZED } from "ducks/auth";

export type InitialState = {
  eventAlerts: EventAlert[];
};

// Reducers
const initialState: InitialState = {
  eventAlerts: [],
};

class Events extends Hen<InitialState> {
  eventAlertsLoaded(eves: EventAlert[]) {
    this.state.eventAlerts = eves;
  }
  eventAlertLoaded(eve: EventAlert) {
    let eveList = this.state.eventAlerts;
    const ind = eveList.findIndex((e) => e.notiID === eve.notiID);
    if (ind !== -1) {
      eveList[ind] = eve;
    } else {
      eveList = [...eveList, eve];
    }
    this.state.eventAlerts = eveList;
  }
  readEventAlertsLoaded(eves: EventAlert[]) {
    let eveList = this.state.eventAlerts;
    eves.forEach((eve) => {
      const ind = eveList.findIndex((e) => e.notiID === eve.notiID);
      if (ind !== -1) {
        eveList[ind] = eve;
      } else {
        eveList = [...eveList, eve];
      }
    });
    this.state.eventAlerts = eveList;
  }
}

export const [Reducer, actions] = hen(new Events(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
const mainSelector = (state: RootState) => state.events;

export const getEventAlertsList = createSelector(mainSelector, (state) => {
  return {
    alerts: state.eventAlerts,
  };
});

export const getAppointmentEvents = (props: { appoID?: string }) =>
  createSelector([mainSelector], (state) => {
    return {
      alerts: state.eventAlerts.filter(
        (eve) => eve?.data?.appoID === props?.appoID
      ),
    };
  });

// Actions
export function loadEventAlerts(
  filter?: FilterEventAlert
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchEventAlerts(apiToken, filter)
      .then((r) => {
        dispatch(actions.eventAlertsLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function readEventAlert(notiID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return addEventAlertReadDate(apiToken, notiID)
      .then((r) => {
        dispatch(actions.eventAlertLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function readEventAlertList(notiIDs: string[]): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return Promise.all(
      notiIDs.map((notiID) => addEventAlertReadDate(apiToken, notiID))
    )
      .then((r) => {
        dispatch(actions.readEventAlertsLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}
