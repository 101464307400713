import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1.0625em"
      viewBox="0 0 16 17"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M15.256 4.601a.727.727 0 00-.506.223l-6.752 6.751-6.75-6.75A.727.727 0 10.22 5.85l7.265 7.265a.727.727 0 001.027 0l7.265-7.265a.727.727 0 00-.52-1.25z" />
    </svg>
  );
}

export default SvgComponent;
