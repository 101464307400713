import {
  StartCareForm,
  CareRecipient,
  ImmediateCareSession,
  ImmediateCareSessionForm,
  EntryToImmediateCareSession,
} from "@udok/lib/api/models";
import { makeItemPost, makeItemPut, makeItemDelete } from "@udok/lib/api/axios";

export const createImmediateCareSession = async (
  token: string,
  data: ImmediateCareSessionForm
) =>
  makeItemPost<ImmediateCareSession, ImmediateCareSessionForm>({
    url: `/api/immediate-care-session`,
    errLabel: "ICS1",
    token,
    data,
  });

export const finishImmediateCareSession = async (
  token: string,
  imcsID: string
) =>
  makeItemPut<ImmediateCareSession, {}>({
    url: `/api/immediate-care-session/${imcsID}/finish`,
    errLabel: "ICS2",
    token,
    data: {},
  });

export const entryToImmediateCareSession = async (
  token: string,
  imcsID: string,
  appoID?: string
) =>
  makeItemPost<EntryToImmediateCareSession, { appoID?: string }>({
    url: `/api/immediate-care-session/${imcsID}/enter`,
    errLabel: "ICS3",
    token,
    data: { appoID },
  });

export const createImmediateCareSessionView = async (
  token: string,
  imcsID: string
) =>
  makeItemPost<string, {}>({
    url: `/api/immediate-care-session/${imcsID}/view`,
    errLabel: "ICS4",
    token,
    data: {},
  });

export const exitToImmediateCareSession = async (
  token: string,
  imcsID: string
) =>
  makeItemDelete<string>({
    url: `/api/immediate-care-session/${imcsID}/exit`,
    errLabel: "ICS5",
    token,
  });

export const callCareRecipientToImmediateCareSession = async (
  token: string,
  imcsID: string,
  data: CareRecipient
) =>
  makeItemPost<string, {}>({
    url: `/api/immediate-care-session/${imcsID}/call`,
    errLabel: "ICS6",
    token,
    data,
  });

export const cancelCallCareRecipient = async (token: string, imcsID: string) =>
  makeItemDelete<string>({
    url: `/api/immediate-care-session/${imcsID}/call`,
    errLabel: "ICS7",
    token,
  });

export const startCareToImmediateCareSession = async (
  token: string,
  imcsID: string,
  data: StartCareForm
) =>
  makeItemPost<string, {}>({
    url: `/api/immediate-care-session/${imcsID}/start-care`,
    errLabel: "ICS8",
    token,
    data,
  });

export const completeCareToImmediateCareSession = async (
  token: string,
  imcsID: string,
  appoID: string
) =>
  makeItemPost<string, { appoID: string }>({
    url: `/api/immediate-care-session/${imcsID}/complete-care`,
    errLabel: "ICS9",
    token,
    data: { appoID },
  });
