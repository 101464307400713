import axios from "axios";
import { deriveErrorMessage, toQueryString } from "@udok/lib/internal/util";
import {
  InvoiceView,
  CreditCard,
  Payment,
  PaymentMethod,
  SupportedPaymentMethod,
  InvoiceVoucher,
  Voucher,
  SendMailVoucherData,
  VoucherValidateStatus,
  ValidateVoucherBy,
} from "@udok/lib/api/models";
import { makeItemFetch, makeItemsFetch } from "@udok/lib/api/axios";

export const fetchInvoicesByAppoID = (token: string, appoID: string) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_PATH}/appointments/${appoID}/invoices?appoID=${appoID}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.items as InvoiceView[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "bill1");
      return Promise.reject(new Error(err));
    });
};

export type FetchInvoicesFilter = {
  appoID?: string;
  limit?: number;
  offset?: number;
};

export const fetchInvoices = (token: string, filter: FetchInvoicesFilter) => {
  const f = filter ? toQueryString(filter ?? { limit: 1000 }) : "";
  return makeItemsFetch<InvoiceView[]>({
    url: `/api/invoices${f}`,
    errLabel: "bill10",
    token,
  });
};

export const fetchInvoice = (token: string, invoID: string) =>
  makeItemFetch<InvoiceView>({
    url: `/api/invoices/${invoID}`,
    errLabel: "bill10",
    token,
  });

export const createCreditCard = (
  token: string,
  cc: Omit<CreditCard, "createdAt" | "crcaID" | "userID">
) => {
  return axios
    .post(`${process.env.REACT_APP_API_PATH}/credit-cards`, cc, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as CreditCard;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "bill2");
      return Promise.reject(new Error(err));
    });
};

export const fetchCreditCards = (token: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/credit-cards`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.items as CreditCard[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "bill3");
      return Promise.reject(new Error(err));
    });
};

export const fetchCreditCard = (token: string, crcaID: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/credit-cards/${crcaID}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as CreditCard;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "bill4");
      return Promise.reject(new Error(err));
    });
};

export const deleteCreditCard = async (token: string, crcaID: string) => {
  return axios
    .delete(`${process.env.REACT_APP_API_PATH}/credit-cards/${crcaID}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as CreditCard;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "bill5");
      return Promise.reject(new Error(err));
    });
};

export type PaymentForm = {
  referenceKey: string;
  method: SupportedPaymentMethod;
  invoID: string;
  crcaID?: string;
  cvv?: string;
  customer: PaymentFormCustomer;
};

export type PaymentFormCustomer = {
  name: string;
  email: string;
  phone: string;
  cpf: string;
  dob: string;
  address: {
    cep: string;
    city: string;
    street: string;
    streetNumber: string;
    neighborhood: string;
    state: string;
  };
};

export const createPayment = (token: string, p: PaymentForm) => {
  return axios
    .post(`${process.env.REACT_APP_API_PATH}/marketplace/payments`, p, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as Payment;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "bill6");
      if (err.indexOf("payment by credit card is blocked") !== -1) {
        return Promise.reject(e?.response?.data?.error);
      }
      return Promise.reject(new Error(err));
    });
};

export const refundPayment = (token: string, paymID: string) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/marketplace/payments/${paymID}/refund`,
      { paymID },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as Payment;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "bill7");
      return Promise.reject(new Error(err));
    });
};

export const fetchPayment = (token: string, paymID: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/marketplace/payments/${paymID}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as Payment;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "bill8");
      return Promise.reject(new Error(err));
    });
};

export const fetchPaymentMethods = (token: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/marketplace/payment-methods`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.items as PaymentMethod[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "bill9");
      return Promise.reject(new Error(err));
    });
};

export const createInvoiceVoucher = async (
  token: string,
  invoID: string,
  voucherCode: string
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/invoices/${invoID}/voucher`,
      { voucherCode },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as InvoiceVoucher;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "bill9");
      return Promise.reject(new Error(err));
    });
};

export const createVoucher = async (token: string, expiredAt: string) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/voucher`,
      { expiredAt },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as Voucher;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "bill8");
      return Promise.reject(new Error(err));
    });
};

export const sendVoucherEmail = async (
  token: string,
  mail: SendMailVoucherData
) => {
  return axios
    .post(`${process.env.REACT_APP_API_PATH}/mailer-voucher`, mail, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.item as string;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "bill9");
      return Promise.reject(new Error(err));
    });
};

export const voucherVerification = (
  code: string,
  checkBy: ValidateVoucherBy
) => {
  const qs = toQueryString(checkBy);
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/voucher-verification/${code}${qs}`)
    .then((r) => {
      return r.data.data.item as VoucherValidateStatus;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "bill11");
      return Promise.reject(new Error(err));
    });
};
