import React from "react";
import { Typography, TypographyProps, Card } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CardLogoText, {
  CardLogoTextProps,
} from "@udok/lib/components/AdCopy/CardLogoText";
import clsx from "clsx";

const Breakpoint = 700;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.breakpoints.down(Breakpoint)]: {
        flex: 1,
      },
    },
    card: {
      borderRadius: 16,
      boxShadow: "none",
      minWidth: 300,
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down(Breakpoint)]: {
        flexDirection: "column-reverse",
        flex: 1,
        borderRadius: 0,
      },
    },
    container: {
      padding: theme.spacing(3),
      [theme.breakpoints.down(Breakpoint)]: {
        flex: 1,
      },
    },
    childrenContainer: {
      maxWidth: 400,
      marginTop: theme.spacing(3.5),
    },
    underlay: {
      [theme.breakpoints.down(Breakpoint)]: {
        width: "100%",
        maxHeight: 100,
        minHeight: 0,
        justifyContent: "center",
        padding: theme.spacing(1),
        backgroundSize: "100%",
        "&>svg": {
          width: "50%",
          height: "100%",
          marginBottom: theme.spacing(1),
        },
      },
    },
    title: {
      marginBottom: theme.spacing(3),
    },
    logo: {
      [theme.breakpoints.down(Breakpoint)]: {
        margin: 0,
        height: `calc(100px - ${theme.spacing(2)}px * 2)`,
        "&> img": {
          height: `calc(100px - ${theme.spacing(2)}px * 2)`,
        },
      },
    },
    logoText: {
      padding: theme.spacing(1),
      minWidth: 300,
      [theme.breakpoints.down(Breakpoint)]: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        maxWidth: 250,
        fontSize: theme.spacing(2),
        padding: 0,
        minWidth: 0,
      },
    },
  })
);

export type AuthCardProps = {
  children?: React.ReactNode;
  title: string;
  titleProps?: TypographyProps;
  cardLogoTextProps?: CardLogoTextProps;
  calsses?: {
    root?: string;
    container?: string;
    childrenContainer?: string;
  };
};

const AuthCard = (props: AuthCardProps) => {
  const {
    children,
    title,
    calsses: calssesProps,
    titleProps,
    cardLogoTextProps,
  } = props;
  const classes = useStyles();
  return (
    <div className={clsx(calssesProps?.root, classes.root)}>
      <Card className={classes.card}>
        <div className={clsx(calssesProps?.container, classes.container)}>
          <div
            className={clsx(
              calssesProps?.childrenContainer,
              classes.childrenContainer
            )}
          >
            <Typography variant="h5" className={classes.title} {...titleProps}>
              {title}
            </Typography>
            {children}
          </div>
        </div>
        <CardLogoText
          classes={{
            underlay: classes.underlay,
            logo: classes.logo,
            text: classes.logoText,
          }}
          {...cardLogoTextProps}
        />
      </Card>
    </div>
  );
};

export default AuthCard;
