import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M2.6 0A2.61 2.61 0 000 2.6v8.814c0 .78.956 1.268 1.587.81h.001l1.728-1.258A2.612 2.612 0 005.8 12.8h5.005l3.607 2.623c.632.459 1.588-.028 1.588-.809V5.8a2.61 2.61 0 00-2.6-2.6h-.6v-.6A2.61 2.61 0 0010.2 0H2.6zm0 1.2h7.6c.78 0 1.4.62 1.4 1.4V7c0 .78-.62 1.4-1.4 1.4H5a.6.6 0 00-.353.115L1.2 11.022V2.6c0-.78.62-1.4 1.4-1.4zm10.2 3.2h.6c.78 0 1.4.62 1.4 1.4v8.422l-3.447-2.507A.6.6 0 0011 11.6H5.8c-.78 0-1.4-.62-1.4-1.4v-.022l.795-.578H10.2A2.61 2.61 0 0012.8 7V4.4z" />
    </svg>
  );
}

export default SvgComponent;
