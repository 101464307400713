import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width="1em"
      height="1em"
      fill="currentColor"
      {...props}
    >
      <path
        d="M408.2 89.405v311.611c0 .622-.01 1.244-.042 1.866-.975 21.34-18.592 38.356-40.192 38.356H141.789c-22.222 0-40.233-18.012-40.233-40.223V40.233c0-19.1 13.304-35.09 31.15-39.207A40.44 40.44 0 01141.789 0h179.66c6.108 0 12.07 1.39 17.472 4.003 10.869 5.231 13.93 11.842 33.462 31.813 22.471 24.399 27.836 26.9 32.415 37.465a39.79 39.79 0 013.402 16.124z"
        fill="#ffc477"
      />
      <path
        d="M408.2 89.405v311.611c0 .622-.01 1.244-.042 1.866a40.384 40.384 0 01-9.084 1.027H172.897c-22.222 0-40.233-18.012-40.233-40.223V2.903c0-.633.01-1.255.041-1.877A40.455 40.455 0 01141.789 0h179.66c6.108 0 12.07 1.39 17.472 4.003a40.208 40.208 0 0111.925 8.773l21.537 23.041 24.658 25.799a39.652 39.652 0 017.756 11.666 39.765 39.765 0 013.403 16.123z"
        fill="#ffdaaa"
      />
      <path
        d="M511.417 228.946v226.198c0 12.578-4.086 24.213-11.012 33.628C490.066 502.854 473.382 512 454.562 512H306.818c-31.399 0-56.855-25.457-56.855-56.855V228.946c0-23.466 14.227-43.614 34.52-52.293a56.602 56.602 0 0122.336-4.563h147.743c31.409.001 56.855 25.458 56.855 56.856z"
        fill="#4cba68"
      />
      <path
        d="M511.417 228.946v226.198c0 12.578-4.086 24.213-11.012 33.628a56.602 56.602 0 01-22.336 4.563H330.326c-31.399 0-56.855-25.457-56.855-56.855V210.281c0-12.578 4.086-24.213 11.012-33.628a56.602 56.602 0 0122.336-4.563h147.743c31.409.001 56.855 25.458 56.855 56.856z"
        fill="#7ccc92"
      />
      <path
        d="M402.476 377.664v26.566c0 4.697-3.806 8.503-8.493 8.503h-26.577a8.502 8.502 0 01-8.503-8.503v-26.566a8.502 8.502 0 018.503-8.503h26.577c4.688 0 8.493 3.806 8.493 8.503z"
        fill="#2c733f"
      />
      <path
        d="M472.345 223.616v28.464c0 10.121-8.202 18.333-18.333 18.333H307.368c-10.121 0-18.333-8.213-18.333-18.333v-28.464c0-10.121 8.213-18.333 18.333-18.333h146.644c10.131 0 18.333 8.213 18.333 18.333z"
        fill="#1e81ce"
      />
      <path
        d="M472.345 223.616v28.464c0 10.121-8.202 18.333-18.333 18.333H338.476c-10.121 0-18.333-8.213-18.333-18.333v-28.464c0-10.121 8.213-18.333 18.333-18.333h115.536c10.131 0 18.333 8.213 18.333 18.333z"
        fill="#3d9ae2"
      />
      <g fill="#2c733f">
        <path d="M332.617 377.664v26.566a8.502 8.502 0 01-8.503 8.503h-26.577c-4.687 0-8.503-3.806-8.503-8.503v-26.566c0-4.697 3.816-8.503 8.503-8.503h26.577a8.501 8.501 0 018.503 8.503zM402.476 308.189v26.566c0 4.697-3.806 8.503-8.493 8.503h-26.577a8.502 8.502 0 01-8.503-8.503v-26.566a8.502 8.502 0 018.503-8.503h26.577c4.688 0 8.493 3.806 8.493 8.503z" />
      </g>
      <path
        d="M473.185 308.189v26.566a8.502 8.502 0 01-8.503 8.503h-26.566a8.502 8.502 0 01-8.503-8.503v-26.566a8.502 8.502 0 018.503-8.503h26.566a8.501 8.501 0 018.503 8.503z"
        fill="#ee8700"
      />
      <g fill="#2c733f">
        <path d="M332.617 308.189v26.566a8.502 8.502 0 01-8.503 8.503h-26.577c-4.687 0-8.503-3.806-8.503-8.503v-26.566c0-4.697 3.816-8.503 8.503-8.503h26.577a8.501 8.501 0 018.503 8.503zM402.476 443.728v26.577c0 4.697-3.806 8.503-8.493 8.503h-26.577a8.502 8.502 0 01-8.503-8.503v-26.577c0-4.687 3.806-8.493 8.503-8.493h26.577c4.688 0 8.493 3.806 8.493 8.493zM332.617 443.728v26.577a8.502 8.502 0 01-8.503 8.503h-26.577c-4.687 0-8.503-3.806-8.503-8.503v-26.577c0-4.687 3.816-8.493 8.503-8.493h26.577c4.698 0 8.503 3.806 8.503 8.493z" />
      </g>
      <path
        d="M473.185 381.791V464.3c0 8.016-6.491 14.507-14.496 14.507H444.11c-8.005 0-14.496-6.491-14.496-14.507v-82.509c0-6.979 5.662-12.63 12.63-12.63h18.312c6.978 0 12.629 5.652 12.629 12.63z"
        fill="#ee8700"
      />
      <g fill="#317f46">
        <path d="M402.476 377.664v7.901c0 4.697-3.806 8.503-8.493 8.503h-26.577a8.502 8.502 0 01-8.503-8.503v-7.901a8.502 8.502 0 018.503-8.503h26.577c4.688 0 8.493 3.806 8.493 8.503zM332.617 377.664v7.901a8.502 8.502 0 01-8.503 8.503h-26.577c-4.687 0-8.503-3.806-8.503-8.503v-7.901c0-4.697 3.816-8.503 8.503-8.503h26.577a8.501 8.501 0 018.503 8.503zM402.476 308.189v7.901c0 4.697-3.806 8.503-8.493 8.503h-26.577a8.502 8.502 0 01-8.503-8.503v-7.901a8.502 8.502 0 018.503-8.503h26.577c4.688 0 8.493 3.806 8.493 8.503z" />
      </g>
      <path
        d="M473.185 308.189v7.901a8.502 8.502 0 01-8.503 8.503h-26.566a8.502 8.502 0 01-8.503-8.503v-7.901a8.502 8.502 0 018.503-8.503h26.566a8.501 8.501 0 018.503 8.503z"
        fill="#ff9811"
      />
      <g fill="#317f46">
        <path d="M332.617 308.189v7.901a8.502 8.502 0 01-8.503 8.503h-26.577c-4.687 0-8.503-3.806-8.503-8.503v-7.901c0-4.697 3.816-8.503 8.503-8.503h26.577a8.501 8.501 0 018.503 8.503zM402.476 443.728v7.912c0 4.697-3.806 8.503-8.493 8.503h-26.577a8.502 8.502 0 01-8.503-8.503v-7.912c0-4.687 3.806-8.493 8.503-8.493h26.577c4.688 0 8.493 3.806 8.493 8.493zM332.617 443.728v7.912a8.502 8.502 0 01-8.503 8.503h-26.577c-4.687 0-8.503-3.806-8.503-8.503v-7.912c0-4.687 3.816-8.493 8.503-8.493h26.577c4.698 0 8.503 3.806 8.503 8.493z" />
      </g>
      <path
        d="M473.185 381.791v63.844c0 8.016-6.491 14.507-14.496 14.507H444.11c-8.005 0-14.496-6.491-14.496-14.507v-63.844c0-6.979 5.662-12.63 12.63-12.63h18.312c6.978 0 12.629 5.652 12.629 12.63z"
        fill="#ff9811"
      />
      <path
        d="M404.801 73.28h-50.415c-8.542 0-15.467-6.925-15.467-15.467V4.003a40.19 40.19 0 0111.925 8.767l21.545 23.043 24.655 25.803a39.83 39.83 0 017.757 11.664z"
        fill="#ffb655"
      />
      <g fill="#4e901e">
        <path d="M192.446 217.608c-9.483-3.484-16.333-6.001-16.333-14.786 0-8.006 5.317-12.066 15.804-12.066 7.047 0 11.021 2.045 13.657 3.4 1.564.804 2.915 1.499 4.394 1.499 4.268 0 6.629-4.529 6.629-7.661 0-6.479-10.568-9.692-20.016-10.539v-5.842c0-4.064-3.306-7.37-7.37-7.37s-7.37 3.306-7.37 7.37v6.771c-12.918 3.067-20.016 11.99-20.016 25.21 0 18.101 13.326 23.108 24.035 27.134 10.486 3.94 18.769 7.052 18.769 19.024 0 9.888-5.491 14.902-16.32 14.902-9.808 0-13.716-4.212-16.568-7.287-1.7-1.833-3.167-3.415-5.477-3.415-3.744 0-6.76 4.191-6.76 7.661 0 6.128 9.127 14.183 22.338 16.275v5.645c0 4.064 3.306 7.37 7.37 7.37s7.37-3.306 7.37-7.37v-5.796c14.21-2.674 22.338-13.109 22.338-28.759-.003-21.644-14.681-27.036-26.474-31.37zM329.56 113.539h19.535c4.295 0 7.777-3.481 7.777-7.777s-3.482-7.777-7.777-7.777H329.56c-4.295 0-7.777 3.481-7.777 7.777s3.482 7.777 7.777 7.777zM160.539 113.539h137.913c4.295 0 7.777-3.481 7.777-7.777s-3.482-7.777-7.777-7.777H160.539c-4.295 0-7.777 3.481-7.777 7.777s3.482 7.777 7.777 7.777zM185.259 152.141c4.295 0 7.777-3.481 7.777-7.777s-3.482-7.777-7.777-7.777h-24.72c-4.295 0-7.777 3.481-7.777 7.777s3.482 7.777 7.777 7.777h24.72zM349.095 136.587H216.367c-4.295 0-7.777 3.481-7.777 7.777s3.482 7.777 7.777 7.777h132.728c4.295 0 7.777-3.481 7.777-7.777s-3.482-7.777-7.777-7.777z" />
      </g>
      <path
        d="M223.427 487.549c0 5.423-2.219 10.338-5.786 13.916-3.577 3.567-8.493 5.786-13.916 5.786h-120.7c-10.836 0-19.702-8.866-19.702-19.702 0-5.423 2.219-10.338 5.786-13.916 3.577-3.567 8.493-5.786 13.916-5.786h120.7c10.837 0 19.702 8.866 19.702 19.702z"
        fill="#aa6100"
      />
      <path
        d="M223.427 448.145c0 5.423-2.219 10.338-5.786 13.916-3.577 3.567-8.493 5.786-13.916 5.786h-120.7c-10.836 0-19.702-8.866-19.702-19.702 0-5.423 2.219-10.338 5.786-13.916 3.577-3.567 8.493-5.786 13.916-5.786h120.7c10.837 0 19.702 8.866 19.702 19.702z"
        fill="#cc7400"
      />
      <path
        d="M223.427 408.741c0 5.423-2.219 10.338-5.786 13.916-3.577 3.567-8.493 5.786-13.916 5.786h-120.7c-10.836 0-19.702-8.866-19.702-19.702 0-5.423 2.219-10.338 5.786-13.916 3.577-3.567 8.493-5.786 13.916-5.786h120.7c10.837.001 19.702 8.866 19.702 19.702z"
        fill="#ee8700"
      />
      <path
        d="M223.427 369.338c0 5.423-2.219 10.338-5.786 13.916-3.577 3.567-8.493 5.786-13.916 5.786h-120.7c-10.836 0-19.702-8.866-19.702-19.702 0-3.857 1.13-7.466 3.069-10.515a19.533 19.533 0 012.717-3.401c3.577-3.567 8.493-5.786 13.916-5.786h120.7c10.837 0 19.702 8.866 19.702 19.702z"
        fill="#ff9811"
      />
      <path
        d="M203.722 349.637H83.027c-10.836 0-19.702-8.866-19.702-19.702 0-10.836 8.866-19.702 19.702-19.702h120.696c10.836 0 19.702 8.866 19.702 19.702-.001 10.836-8.866 19.702-19.703 19.702z"
        fill="#ffa733"
      />
      <path
        d="M87.306 381.232v128.261H67.679c-17.331.745-33.36-5.471-45.377-16.153-43.612-38.703-17.066-109.639 39.885-112.108h25.119z"
        fill="#cc7400"
      />
      <path
        d="M154.124 447.684c-1.155 33.169-27.685 60.363-61.395 61.806-35.414 1.514-65.351-25.968-66.865-61.382-.006-.144-.013-.281-.013-.424-1.28-35.237 26.125-64.939 61.395-66.447 35.414-1.514 65.351 25.968 66.865 61.389.072 1.702.078 3.386.013 5.058z"
        fill="#ffc477"
      />
      <path
        d="M100.608 492.204c-.783.176-1.561.333-2.343.47-25.061 4.413-49.429-11.606-55.122-36.693-5.862-25.871 10.353-51.59 36.223-57.459a47.964 47.964 0 0118.983-.47c18.461 3.277 34.07 17.26 38.476 36.693 5.868 25.87-10.347 51.597-36.217 57.459z"
        fill="#cc7400"
      />
      <path
        d="M100.608 492.204c-.783.176-1.561.333-2.343.47-18.454-3.277-34.069-17.266-38.476-36.693-5.862-25.871 10.353-51.59 36.223-57.459a49.804 49.804 0 012.337-.47c18.461 3.277 34.07 17.26 38.476 36.693 5.868 25.87-10.347 51.597-36.217 57.459z"
        fill="#ee8700"
      />
      <path
        d="M154.124 447.684c-1.155 33.169-27.685 60.363-61.395 61.806-35.414 1.514-65.351-25.968-66.865-61.382-.006-.144-.013-.281-.013-.424H42a48.512 48.512 0 001.143 8.297c5.869 25.871 31.595 42.092 57.465 36.223 21.523-4.877 36.36-23.494 37.359-44.52h16.157z"
        fill="#ffa733"
      />
      <g fill="#aa6100">
        <path d="M95.751 435.611l-1.205-.445c-6.83-2.51-7.525-3.306-7.525-5.621 0-1.949.718-2.509 1.391-2.882 1.048-.58 2.693-.9 4.631-.9 3.388 0 5.094.879 6.614 1.66 1.163.598 2.482 1.275 4.198 1.275 4.436 0 7.416-4.154 7.416-8.034 0-4.729-4.229-7.988-11.988-9.323v-.488c0-4.333-3.526-7.859-7.859-7.859s-7.859 3.526-7.859 7.859v1.491c-7.656 2.777-11.988 9.093-11.988 17.663 0 13.227 9.61 16.838 16.629 19.476 6.692 2.515 9.007 3.616 9.007 8.169 0 2.846-.766 5.479-6.329 5.479-4.372 0-5.91-1.658-7.399-3.264-1.161-1.253-2.918-3.144-5.805-3.144-4.289 0-7.494 4.241-7.494 8.033 0 5.373 5.587 10.472 13.38 12.541v.588c0 4.333 3.526 7.859 7.859 7.859s7.859-3.526 7.859-7.859v-.794c8.431-2.711 13.379-9.97 13.379-19.902-.005-15.274-11.002-19.375-16.912-21.578zM36.428 409.992a63.976 63.976 0 00-7.487 15.512H3.895c1.784-5.618 4.485-11.008 7.476-15.512h25.057zM26.304 453.414H1.247a64.562 64.562 0 01-.073-15.512h25.067c-.601 5.037-.588 10.273.063 15.512zM37.307 482.028H12.24a63.676 63.676 0 01-7.921-15.512h25.057a63.272 63.272 0 007.931 15.512z" />
      </g>
    </svg>
  );
}

export default SvgComponent;
