import axios from "axios";
import { AxiosRequestConfig } from "axios";
import { deriveErrorMessage, APIError } from "@udok/lib/internal/util";

axios.defaults.headers.get = {
  "Content-Type": "application/json",
};
axios.defaults.headers.post = {
  "Content-Type": "application/json",
};
axios.defaults.headers.put = {
  "Content-Type": "application/json",
};
axios.defaults.headers.delete = {
  "Content-Type": "application/json",
};
axios.defaults.headers.patch = {
  "Content-Type": "application/json",
};

export type ItemResponse<T> = { data: { item: T } };
export type ItemsResponse<T> = { data: { items: T } };

export async function makeItemFetch<T>({
  url,
  errLabel,
  token,
  params,
}: {
  url: string;
  errLabel: string;
  token?: string;
  params?: AxiosRequestConfig["params"];
}) {
  return axios
    .get<ItemResponse<T>>(`${process.env.REACT_APP_BASE_PATH}${url}`, {
      ...(token
        ? {
            headers: {
              Authorization: token,
            },
          }
        : {}),
      params,
    })
    .then((r) => {
      return r.data.data.item;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, errLabel);
      const errors = (e?.response?.data?.error || e)?.errors;
      return Promise.reject(new APIError(err, errors));
    });
}

export async function makeItemsFetch<T>({
  url,
  errLabel,
  token,
  params,
}: {
  url: string;
  errLabel: string;
  token?: string;
  params?: AxiosRequestConfig["params"];
}) {
  return axios
    .get<ItemsResponse<T>>(`${process.env.REACT_APP_BASE_PATH}${url}`, {
      ...(token
        ? {
            headers: {
              Authorization: token,
            },
          }
        : {}),
      params,
    })
    .then((r) => {
      return r.data.data.items;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, errLabel);
      const errors = (e?.response?.data?.error || e)?.errors;
      return Promise.reject(new APIError(err, errors));
    });
}

export async function makeItemPost<T, D>({
  url,
  errLabel,
  token,
  params,
  data,
}: {
  url: string;
  errLabel: string;
  token?: string;
  params?: AxiosRequestConfig["params"];
  data: D;
}) {
  return axios
    .post<ItemResponse<T>>(`${process.env.REACT_APP_BASE_PATH}${url}`, data, {
      ...(token
        ? {
            headers: {
              Authorization: token,
            },
          }
        : {}),
      params,
    })
    .then((r) => {
      return r.data.data.item;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, errLabel);
      const errors = (e?.response?.data?.error || e)?.errors;
      return Promise.reject(new APIError(err, errors));
    });
}

export async function makeItemPut<T, D>({
  url,
  errLabel,
  token,
  params,
  data,
}: {
  url: string;
  errLabel: string;
  token?: string;
  params?: AxiosRequestConfig["params"];
  data: D;
}) {
  return axios
    .put<ItemResponse<T>>(`${process.env.REACT_APP_BASE_PATH}${url}`, data, {
      ...(token
        ? {
            headers: {
              Authorization: token,
            },
          }
        : {}),
      params,
    })
    .then((r) => {
      return r.data.data.item;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, errLabel);
      const errors = (e?.response?.data?.error || e)?.errors;
      return Promise.reject(new APIError(err, errors));
    });
}

export async function makeItemDelete<T>({
  url,
  errLabel,
  token,
  params,
}: {
  url: string;
  errLabel: string;
  token?: string;
  params?: AxiosRequestConfig["params"];
}) {
  return axios
    .delete<ItemResponse<T>>(`${process.env.REACT_APP_BASE_PATH}${url}`, {
      ...(token
        ? {
            headers: {
              Authorization: token,
            },
          }
        : {}),
      params,
    })
    .then((r) => {
      return r.data.data.item;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, errLabel);
      const errors = (e?.response?.data?.error || e)?.errors;
      return Promise.reject(new APIError(err, errors));
    });
}
