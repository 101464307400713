import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={14}
      viewBox="0 0 14 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4.5.667c-.71 0-1.326.407-1.63 1h8.63c.46 0 .833.373.833.833v8.63c.593-.303 1-.92 1-1.63v-7c0-1.01-.822-1.833-1.833-1.833h-7zm-2 2A1.841 1.841 0 00.667 4.5v7A1.84 1.84 0 002.5 13.333h7a1.84 1.84 0 001.833-1.833v-7A1.84 1.84 0 009.5 2.667h-7zm0 1h7c.466 0 .833.367.833.833v7a.826.826 0 01-.833.833h-7a.826.826 0 01-.833-.833v-7c0-.466.367-.833.833-.833z" />
    </svg>
  );
}

export default SvgComponent;
