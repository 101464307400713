import React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="Capa_1"
      enableBackground="new 0 0 497 497"
      height="512"
      viewBox="0 0 497 497"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <g>
          <path
            d="m0 364.467v66.267c0 18.299 14.834 33.133 33.132 33.133h364.941c18.298 0 33.132-14.834 33.132-33.133v-66.267z"
            fill="#b5dbff"
          />
          <path
            d="m408.207 364.466v66.268c0 18.298-14.834 33.133-33.132 33.133h23c18.299 0 33.133-14.834 33.133-33.133v-66.268z"
            fill="#b5dbff"
          />
          <path
            d="m0 132.533v265.067c0 18.299 14.834 33.132 33.132 33.132h364.941c18.299 0 33.133-14.834 33.133-33.132v-265.067z"
            fill="#edf5ff"
          />
          <path
            d="m408.207 132.533v265.067c0 18.299-14.834 33.132-33.133 33.132h23c18.299 0 33.133-14.834 33.133-33.132v-265.067z"
            fill="#d5e8fe"
          />
          <path
            d="m431.207 149.1v-82.834c0-18.299-14.834-33.132-33.133-33.132h-364.941c-18.299-.001-33.133 14.833-33.133 33.132v82.834z"
            fill="#ff435b"
          />
          <g>
            <path
              d="m82.833 73.767h-16.567c-4.142 0-7.5-3.358-7.5-7.5s3.358-7.5 7.5-7.5h16.567c4.142 0 7.5 3.358 7.5 7.5s-3.358 7.5-7.5 7.5z"
              fill="#e3374e"
            />
          </g>
          <g>
            <path
              d="m132.532 73.767h-16.566c-4.142 0-7.5-3.358-7.5-7.5s3.358-7.5 7.5-7.5h16.566c4.142 0 7.5 3.358 7.5 7.5s-3.358 7.5-7.5 7.5z"
              fill="#e3374e"
            />
          </g>
          <g>
            <path
              d="m314.766 73.767h-16.566c-4.142 0-7.5-3.358-7.5-7.5s3.358-7.5 7.5-7.5h16.566c4.142 0 7.5 3.358 7.5 7.5s-3.358 7.5-7.5 7.5z"
              fill="#e3374e"
            />
          </g>
          <g>
            <path
              d="m364.466 73.767h-16.567c-4.142 0-7.5-3.358-7.5-7.5s3.358-7.5 7.5-7.5h16.567c4.142 0 7.5 3.358 7.5 7.5s-3.358 7.5-7.5 7.5z"
              fill="#e3374e"
            />
          </g>
          <path d="m0 108.467h431.206v15h-431.206z" fill="#e3374e" />
          <path
            d="m82.832 66.267c0 9.149 7.417 16.567 16.567 16.567 9.149 0 16.567-7.417 16.567-16.567v-49.7c0-9.15-7.417-16.567-16.567-16.567s-16.567 7.417-16.567 16.567z"
            fill="#596c76"
          />
          <path
            d="m314.766 66.267c0 9.149 7.417 16.567 16.567 16.567s16.567-7.417 16.567-16.567v-49.7c0-9.15-7.417-16.567-16.567-16.567-9.149 0-16.567 7.417-16.567 16.567z"
            fill="#596c76"
          />
          <path
            d="m398.074 33.133h-23c18.299 0 33.133 14.834 33.133 33.132v82.835h23v-82.834c0-18.299-14.834-33.133-33.133-33.133z"
            fill="#e3374e"
          />
        </g>
        <path
          d="m124.532 248.5h-83.4c-4.418 0-8-3.582-8-8v-50.267c0-4.418 3.582-8 8-8h83.4c4.418 0 8 3.582 8 8v50.267c0 4.418-3.581 8-8 8z"
          fill="#c3ea21"
        />
        <g>
          <g>
            <path
              d="m115.966 289.133h-66.267c-4.142 0-7.5-3.358-7.5-7.5s3.358-7.5 7.5-7.5h66.267c4.142 0 7.5 3.358 7.5 7.5s-3.358 7.5-7.5 7.5z"
              fill="#596c76"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="m99.399 322.267h-33.133c-4.142 0-7.5-3.358-7.5-7.5s3.358-7.5 7.5-7.5h33.133c4.142 0 7.5 3.358 7.5 7.5s-3.358 7.5-7.5 7.5z"
              fill="#596c76"
            />
          </g>
        </g>
        <path
          d="m257.066 248.5h-83.4c-4.418 0-8-3.582-8-8v-50.267c0-4.418 3.582-8 8-8h83.4c4.418 0 8 3.582 8 8v50.267c0 4.418-3.582 8-8 8z"
          fill="#76e2f8"
        />
        <g>
          <g>
            <path
              d="m248.499 289.133h-66.267c-4.142 0-7.5-3.358-7.5-7.5s3.358-7.5 7.5-7.5h66.267c4.142 0 7.5 3.358 7.5 7.5s-3.358 7.5-7.5 7.5z"
              fill="#596c76"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="m231.933 322.267h-33.133c-4.142 0-7.5-3.358-7.5-7.5s3.358-7.5 7.5-7.5h33.133c4.142 0 7.5 3.358 7.5 7.5s-3.358 7.5-7.5 7.5z"
              fill="#596c76"
            />
          </g>
        </g>
        <path
          d="m390.074 248.5h-83.4c-4.418 0-8-3.582-8-8v-50.267c0-4.418 3.582-8 8-8h83.4c4.418 0 8 3.582 8 8v50.267c0 4.418-3.581 8-8 8z"
          fill="#c3ea21"
        />
        <g>
          <g>
            <path
              d="m381.507 289.133h-66.267c-4.142 0-7.5-3.358-7.5-7.5s3.358-7.5 7.5-7.5h66.267c4.142 0 7.5 3.358 7.5 7.5s-3.358 7.5-7.5 7.5z"
              fill="#596c76"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="m364.941 322.267h-33.133c-4.142 0-7.5-3.358-7.5-7.5s3.358-7.5 7.5-7.5h33.133c4.142 0 7.5 3.358 7.5 7.5s-3.358 7.5-7.5 7.5z"
              fill="#596c76"
            />
          </g>
        </g>
        <ellipse
          cx="414.166"
          cy="414.167"
          fill="#c3ea21"
          rx="82.833"
          ry="82.833"
          transform="matrix(.707 -.707 .707 .707 -171.554 414.166)"
        />
        <path
          d="m455.583 397.6h-24.85v-24.85c0-4.575-3.709-8.283-8.283-8.283h-16.567c-4.575 0-8.283 3.708-8.283 8.283v24.85h-24.85c-4.575 0-8.283 3.709-8.283 8.283v16.567c0 4.575 3.708 8.283 8.283 8.283h24.85v24.85c0 4.575 3.709 8.283 8.283 8.283h16.567c4.575 0 8.283-3.708 8.283-8.283v-24.85h24.85c4.575 0 8.283-3.708 8.283-8.283v-16.567c0-4.574-3.708-8.283-8.283-8.283z"
          fill="#edf5ff"
        />
        <path
          d="m390.074 182.233h-23c4.418 0 8 3.582 8 8v50.267c0 4.418-3.582 8-8 8h23c4.418 0 8-3.582 8-8v-50.267c0-4.418-3.581-8-8-8z"
          fill="#9cdd05"
        />
        <path
          d="m257.066 182.233h-23c4.418 0 8 3.582 8 8v50.267c0 4.418-3.582 8-8 8h23c4.418 0 8-3.582 8-8v-50.267c0-4.418-3.582-8-8-8z"
          fill="#5bccf5"
        />
        <g fill="#9cdd05">
          <path d="m124.532 182.233h-23c4.418 0 8 3.582 8 8v50.267c0 4.418-3.582 8-8 8h23c4.418 0 8-3.582 8-8v-50.267c0-4.418-3.581-8-8-8z" />
          <path d="m414.166 331.333c-3.904 0-7.74.29-11.5.813 40.302 5.601 71.334 40.177 71.334 82.021 0 41.843-31.032 76.42-71.333 82.021 3.76.523 7.596.813 11.5.813 45.747-.001 82.833-37.087 82.833-82.834s-37.086-82.834-82.834-82.834z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
