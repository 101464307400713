import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      isolation: "isolate",
    }}
    viewBox="952 492 509 536"
    width="509pt"
    height="536pt"
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path d="M952 492h509v536H952z" />
      </clipPath>
    </defs>
    <g clipPath="url(#a)">
      <path
        d="M1361.736 651.517H985.678c-14.457 0-26.177 11.72-26.177 26.177v171.194h428.41V677.694c.001-14.457-11.718-26.177-26.175-26.177Z"
        fill="#FF9A00"
      />
      <path
        d="M1025.707 499.502c-18.445 0-33.397 14.953-33.397 33.397V963.1c0 18.445 14.953 33.397 33.397 33.397h296c18.445 0 33.397-14.953 33.397-33.397V602.487c0-6.71-2.666-13.146-7.41-17.89l-77.684-77.684a25.3 25.3 0 0 0-17.89-7.41h-226.413v-.001Z"
        fill="#F5F5F5"
      />
      <path
        d="m1347.694 584.597-51.494-51.494-.183 86.377c-.012 5.422 4.381 9.824 9.803 9.824 5.414 0 9.803 4.389 9.803 9.803V963.1c0 18.445-14.953 33.398-33.397 33.398h39.481c18.445 0 33.397-14.953 33.397-33.397V602.487a25.3 25.3 0 0 0-7.41-17.89Z"
        fill="#EAEAEA"
      />
      <path
        d="m1347.694 584.597-77.684-77.684a25.28 25.28 0 0 0-7.41-5.131v56.828c0 18.445 14.953 33.398 33.397 33.398h56.829a25.295 25.295 0 0 0-5.132-7.411Z"
        fill="#A8D0D5"
      />
      <path
        d="m1109.597 747.521-6.904-15.625a26.176 26.176 0 0 0-23.944-15.597h-93.071c-14.457 0-26.177 11.72-26.177 26.177v227.846c0 14.457 11.72 26.177 26.177 26.177h376.057c14.457 0 26.177-11.72 26.177-26.177V789.295c0-14.457-11.72-26.177-26.177-26.177H1133.54a26.176 26.176 0 0 1-23.943-15.597Z"
        fill="#FFB541"
      />
      <g fill="#FFA812">
        <path d="m1062.864 731.896 6.904 15.625a26.176 26.176 0 0 0 23.944 15.597h39.827a26.178 26.178 0 0 1-23.942-15.597l-6.904-15.625a26.176 26.176 0 0 0-23.944-15.597h-39.827a26.178 26.178 0 0 1 23.942 15.597ZM1361.736 763.118h-39.827c14.456.001 26.174 11.72 26.174 26.177v181.027c0 14.457-11.72 26.177-26.177 26.177h39.829c14.457 0 26.177-11.72 26.177-26.177V789.295c0-14.457-11.719-26.177-26.176-26.177Z" />
      </g>
      <path d="M1112.91 554.48h-74.185a7.502 7.502 0 0 1-7.502-7.502 7.502 7.502 0 0 1 7.502-7.502h74.185a7.502 7.502 0 0 1 7.502 7.502 7.502 7.502 0 0 1-7.502 7.502ZM1112.91 599.593h-74.185a7.502 7.502 0 0 1-7.502-7.502 7.502 7.502 0 0 1 7.502-7.502h74.185a7.502 7.502 0 0 1 7.502 7.502 7.502 7.502 0 0 1-7.502 7.502Z" />
      <path d="M1362.606 644.038v-41.55c0-8.762-3.412-16.999-9.608-23.196l-77.684-77.683c-6.196-6.197-14.433-9.609-23.195-9.609h-226.412c-22.552 0-40.899 18.347-40.899 40.9v111.138C966.64 644.503 952 659.416 952 677.694v292.627c0 18.571 15.108 33.679 33.678 33.679h376.058c18.57 0 33.678-15.108 33.678-33.679V677.694c0-18.278-14.64-33.192-32.808-33.656Zm-25.613-59.533h-40.996c-14.279 0-25.896-11.616-25.896-25.895v-40.996l66.892 66.891Zm-352.185 74.558v22.163a7.502 7.502 0 0 0 7.502 7.502 7.502 7.502 0 0 0 7.502-7.502V532.9c0-14.279 11.617-25.896 25.895-25.896h226.412c1.01 0 2.002.101 2.978.265v51.341c0 22.551 18.348 40.899 40.9 40.899h51.341c.164.977.265 1.969.265 2.979v125.557a7.502 7.502 0 0 0 7.502 7.502 7.502 7.502 0 0 0 7.502-7.502v-68.982c9.892.459 17.804 8.627 17.804 18.631v83.591a33.475 33.475 0 0 0-18.674-5.668H1133.54a18.684 18.684 0 0 1-17.082-11.127c-3.97-5.812-8.655-35.692-37.709-35.692h-93.071a33.483 33.483 0 0 0-18.674 5.668v-36.772c0-10.004 7.912-18.171 17.804-18.631Zm395.602 311.258c0 10.297-8.378 18.675-18.674 18.675H985.678c-10.297 0-18.674-8.378-18.674-18.675V742.475c0-10.297 8.378-18.674 18.674-18.674h93.071c17.403 0 19.288 19.181 23.985 26.753a33.696 33.696 0 0 0 30.806 20.066h228.195c10.297 0 18.674 8.378 18.674 18.675v181.026h.001Z" />
      <path
        d="M1351.5 809c-60.381 0-109.5 49.119-109.5 109.5s49.119 109.5 109.5 109.5S1461 978.881 1461 918.5 1411.881 809 1351.5 809Z"
        fill="#F44336"
      />
      <path d="M1351.5 1028c-60.381 0-109.5-49.119-109.5-109.5S1291.119 809 1351.5 809 1461 858.119 1461 918.5s-49.119 109.5-109.5 109.5Zm0-205.313c-52.834 0-95.812 42.979-95.812 95.813s42.978 95.812 95.812 95.812c52.834 0 95.813-42.978 95.813-95.812 0-52.834-42.979-95.813-95.813-95.813Z" />
      <path d="M1317.629 959.225a6.79 6.79 0 0 1-4.837-2.008 6.844 6.844 0 0 1 0-9.681l67.752-67.753a6.846 6.846 0 1 1 9.683 9.681l-67.754 67.753a6.857 6.857 0 0 1-4.844 2.008Z" />
      <path d="M1385.371 959.225a6.789 6.789 0 0 1-4.835-2.008l-67.753-67.753a6.842 6.842 0 0 1 0-9.681 6.842 6.842 0 0 1 9.681 0l67.753 67.753a6.842 6.842 0 0 1 0 9.681 6.825 6.825 0 0 1-4.846 2.008Z" />
    </g>
  </svg>
);

export default SvgComponent;
