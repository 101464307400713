class Websocket {
  instance: any;
  interval: any;
  timeout: any;
  token: string;
  url: string;

  onclose?: (f: any) => void;
  onmessage?: (f: any) => void;
  onerror?: (f: any) => void;
  onopen?: (f: any) => void;
  onstatus?: (f: number) => void;

  constructor(token: string, url: string) {
    this.token = token;
    this.url = url;
    this.interval = setInterval(() => {
      this.checkConnect();
    }, 3000);
  }

  reconnect = () => {
    if (this.timeout) {
      return;
    }
    this.timeout = setTimeout(() => {
      if (!this.instance || this.instance?.readyState === 3) {
        this.connect();
        clearTimeout(this.timeout);
        delete this.timeout;
      }
    }, 4000);
  };

  checkConnect = () => {
    this.onstatus?.(this.instance?.readyState);
    if (!this.instance || this.instance?.readyState === 3) {
      this.onclose?.({});
      this.reconnect();
    }
  };

  connect = () => {
    delete this.instance;

    this.instance = new (WebSocket as any)(this.url);
    this.instance.binaryType = "arraybuffer";
    this.instance.onclose = (e: any) => this.onclose && this.onclose(e);
    this.instance.onopen = (e: any) => this.onopen && this.onopen(e);
    this.instance.onmessage = (e: any) => this.onmessage && this.onmessage(e);
    this.instance.onerror = (e: any) => this.onerror && this.onerror(e);
  };

  send = (a: any): boolean => {
    if (this.instance) {
      if (this.instance.readyState === 1) {
        try {
          const action = {
            token: this.token,
            ...a,
          };
          this.instance.send(JSON.stringify(action));
        } catch (e) {
          console.warn("failed to send", e);
          return false;
        }
        return true;
      }
    }
    return false;
  };

  getStatus = () => {
    return this.instance.readyState;
  };

  close = () => {
    this.instance?.close?.();
    clearInterval(this.interval);
  };
}

export default Websocket;
