import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import ReactGA from "react-ga4";

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE ?? "dev", {
  gaOptions: {
    cookieDomain: process.env.REACT_APP_GA_COOKIE_DOMAIN ?? "localhost",
    cookieFlags: "SameSite=None; Secure",
  },
});
ReactGA.set({ anonymize_ip: true });

Sentry.init({
  dsn: process.env.REACT_APP_DSN_SENTRY,
  integrations: [new Integrations.BrowserTracing()],
  enabled: ["development", "test"].indexOf(process.env.NODE_ENV) === -1,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
