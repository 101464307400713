import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        isolation: "isolate",
      }}
      viewBox="-652 -267 512.666 512"
      width={683.555}
      height="512pt"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M-652-267h512.666v512H-652z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path
          d="M-652 108.467v68.267c0 18.851 15.282 34.133 34.132 34.133h375.955c18.851 0 34.132-15.282 34.132-34.133v-68.267H-652z"
          fill="#B5DBFF"
        />
        <path
          d="M-231.473 108.466v68.268c0 18.85-15.282 34.133-34.132 34.133h23.694c18.852 0 34.133-15.282 34.133-34.133v-68.268h-23.695z"
          fill="#B5DBFF"
        />
        <path
          d="M-652-130.467V142.6c0 18.851 15.282 34.132 34.132 34.132h375.955c18.852 0 34.133-15.282 34.133-34.132v-273.067H-652z"
          fill="#EDF5FF"
        />
        <path
          d="M-231.473-130.467V142.6c0 18.851-15.282 34.132-34.133 34.132h23.694c18.852 0 34.133-15.282 34.133-34.132v-273.067h-23.694z"
          fill="#D5E8FE"
        />
        <path
          d="M-207.779-113.4v-85.334c0-18.851-15.281-34.132-34.133-34.132h-375.955c-18.851-.001-34.133 15.281-34.133 34.132v85.334h444.221z"
          fill="#FF435B"
        />
        <path
          d="M-566.667-191.007h-17.067a7.726 7.726 0 110-15.452h17.067a7.726 7.726 0 110 15.452zM-515.468-191.007h-17.066a7.726 7.726 0 110-15.452h17.066a7.726 7.726 0 110 15.452zM-327.734-191.007H-344.8a7.726 7.726 0 110-15.452h17.066a7.726 7.726 0 110 15.452zM-276.534-191.007h-17.067a7.726 7.726 0 110-15.452h17.067a7.726 7.726 0 110 15.452zM-652-155.259h444.22v15.452H-652v-15.452z"
          fill="#E3374E"
        />
        <path
          d="M-566.668-198.733c0 9.425 7.641 17.067 17.067 17.067 9.425 0 17.067-7.641 17.067-17.067v-51.2c0-9.426-7.641-17.067-17.067-17.067s-17.067 7.641-17.067 17.067v51.2zM-327.734-198.733c0 9.425 7.641 17.067 17.067 17.067s17.067-7.641 17.067-17.067v-51.2c0-9.426-7.641-17.067-17.067-17.067-9.425 0-17.067 7.641-17.067 17.067v51.2z"
          fill="#596C76"
        />
        <path
          d="M-241.912-232.867h-23.694c18.851 0 34.133 15.282 34.133 34.132v85.335h23.694v-85.334c0-18.851-15.281-34.133-34.133-34.133z"
          fill="#E3374E"
        />
        <path
          d="M-523.709-11h-85.918a8.241 8.241 0 01-8.241-8.241v-51.785a8.241 8.241 0 018.241-8.241h85.918a8.241 8.241 0 018.241 8.241v51.785A8.24 8.24 0 01-523.709-11z"
          fill="#C3EA21"
        />
        <g>
          <path
            d="M-532.534 30.859h-68.267a7.726 7.726 0 110-15.452h68.267a7.726 7.726 0 110 15.452z"
            fill="#596C76"
          />
        </g>
        <g>
          <path
            d="M-549.601 64.993h-34.133a7.726 7.726 0 110-15.452h34.133a7.726 7.726 0 110 15.452z"
            fill="#596C76"
          />
        </g>
        <path
          d="M-387.175-11h-85.918a8.241 8.241 0 01-8.241-8.241v-51.785a8.241 8.241 0 018.241-8.241h85.918a8.241 8.241 0 018.241 8.241v51.785A8.241 8.241 0 01-387.175-11z"
          fill="#76E2F8"
        />
        <g>
          <path
            d="M-396.001 30.859h-68.267a7.726 7.726 0 110-15.452h68.267a7.726 7.726 0 110 15.452z"
            fill="#596C76"
          />
        </g>
        <g>
          <path
            d="M-413.067 64.993H-447.2a7.726 7.726 0 110-15.452h34.133a7.726 7.726 0 110 15.452z"
            fill="#596C76"
          />
        </g>
        <path
          d="M-250.153-11h-85.917a8.242 8.242 0 01-8.242-8.241v-51.785a8.242 8.242 0 018.242-8.241h85.917a8.241 8.241 0 018.241 8.241v51.785A8.24 8.24 0 01-250.153-11z"
          fill="#C3EA21"
        />
        <g>
          <path
            d="M-258.979 30.859h-68.267a7.726 7.726 0 110-15.452h68.267a7.727 7.727 0 110 15.452z"
            fill="#596C76"
          />
        </g>
        <g>
          <path
            d="M-276.045 64.993h-34.133a7.726 7.726 0 110-15.452h34.133a7.727 7.727 0 110 15.452z"
            fill="#596C76"
          />
        </g>
        <path
          d="M-250.153-79.267h-23.694a8.241 8.241 0 018.241 8.241v51.785A8.241 8.241 0 01-273.847-11h23.694a8.241 8.241 0 008.241-8.241v-51.785a8.24 8.24 0 00-8.241-8.241z"
          fill="#9CDD05"
        />
        <path
          d="M-387.175-79.267h-23.695a8.242 8.242 0 018.242 8.241v51.785A8.242 8.242 0 01-410.87-11h23.695a8.241 8.241 0 008.241-8.241v-51.785a8.241 8.241 0 00-8.241-8.241z"
          fill="#5BCCF5"
        />
        <g>
          <path
            d="M-523.709-79.267h-23.695a8.242 8.242 0 018.242 8.241v51.785A8.242 8.242 0 01-547.404-11h23.695a8.241 8.241 0 008.241-8.241v-51.785a8.24 8.24 0 00-8.241-8.241z"
            fill="#9CDD05"
          />
        </g>
        <g>
          <circle
            vectorEffect="non-scaling-stroke"
            cx={-224.667}
            cy={159.667}
            r={85.333}
            fill="#FF435B"
          />
          <path
            d="M-224.667 74.333c-4.022 0-7.974.299-11.847.838 41.517 5.77 73.486 41.389 73.486 84.496 0 43.106-31.969 78.726-73.486 84.495 3.873.539 7.825.838 11.847.838 47.128 0 85.333-38.205 85.333-85.333 0-47.129-38.205-85.334-85.333-85.334z"
            fill="#E3374E"
          />
          <path
            d="M-206.566 117.429l-18.102 18.102-18.102-18.102a8.532 8.532 0 00-12.068 0l-12.067 12.067a8.532 8.532 0 000 12.068l18.102 18.102-18.102 18.102a8.532 8.532 0 000 12.068l12.067 12.067a8.532 8.532 0 0012.068 0l18.102-18.102 18.102 18.101a8.532 8.532 0 0012.068 0l12.067-12.067a8.532 8.532 0 000-12.068l-18.102-18.101 18.102-18.102a8.532 8.532 0 000-12.068l-12.068-12.067a8.531 8.531 0 00-12.067 0z"
            fill="#EDF5FF"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
