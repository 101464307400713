import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 60 60"
    fill="currentColor"
    {...props}
  >
    <path
      fill="currentColor"
      d="M53.69 49.75h-5.92V6.31H12.23v43.44H6.31v3.95h8.06l25.51-4.25V12.56l-12.7-2.31h16.65v43.44h9.87v-3.94zm-25.66-23.7h3.95v7.9h-3.95v-7.9z"
    />
  </svg>
);
export default SvgComponent;
