import * as React from "react";

function SvgComponent(
  props: React.SVGProps<SVGSVGElement> & {
    variant?:
      | "h-start"
      | "h-end"
      | "h-center"
      | "v-start"
      | "v-end"
      | "v-center";
  }
) {
  const tranformTR: { [k: string]: string | undefined } = {
    ["h-start"]: "translate(-3.5 0)",
    ["h-end"]: "translate(3.5 0)",
    ["v-start"]: "translate(-3.5 0)",
    ["v-end"]: "translate(3.5 0)",
  };
  const tranformRot: { [k: string]: string | undefined } = {
    ["v-start"]: "rotate(90 9 9)",
    ["v-end"]: "rotate(90 9 9)",
    ["v-center"]: "rotate(90 9 9)",
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        isolation: "isolate",
      }}
      viewBox="0 0 18 18"
      width="1em"
      height="1em"
      {...props}
    >
      <g
        fillRule="evenodd"
        transform={tranformRot[props?.variant ?? "h-center"]}
      >
        <path d="M2.75 0C1.24 0 0 1.24 0 2.75v12.5C0 16.76 1.24 18 2.75 18h12.5c1.51 0 2.75-1.24 2.75-2.75V2.75C18 1.24 16.76 0 15.25 0H2.75Zm0 1.5h12.5c.699 0 1.25.551 1.25 1.25v12.5a1.24 1.24 0 0 1-1.25 1.25H2.75a1.24 1.24 0 0 1-1.25-1.25V2.75c0-.699.551-1.25 1.25-1.25Z" />
        <path
          d="M7.5 2.5a.747.747 0 0 0-.53.22.747.747 0 0 0-.22.53v11.5c0 .199.079.39.22.53.14.141.331.22.53.22h3c.199 0 .39-.079.53-.22a.747.747 0 0 0 .22-.53V3.25a.747.747 0 0 0-.22-.53.747.747 0 0 0-.53-.22h-3ZM8.25 4h1.5v10h-1.5V4Z"
          transform={tranformTR[props?.variant ?? "h-center"]}
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
