import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width="1em"
      height="1em"
      fill="currentColor"
      {...props}
    >
      <path d="M29.918 4.79c-2.896 0-5.793 1.1-7.994 3.3L6.09 23.924c-4.402 4.401-4.402 11.585 0 15.986 4.401 4.402 11.585 4.402 15.986 0l2.815-2.816C26.46 40.567 29.954 43 34 43c5.505 0 10-4.495 10-10 0-4.051-2.439-7.547-5.918-9.115 4.22-4.416 4.168-11.455-.172-15.795-2.2-2.2-5.096-3.3-7.992-3.3zm-.002 2.98a8.28 8.28 0 015.873 2.44 8.283 8.283 0 010 11.745l-.918.916a1.5 1.5 0 00-.146.166c-.24-.017-.48-.037-.725-.037a9.965 9.965 0 00-7.002 2.877l-9.31-9.31 6.357-6.356a8.276 8.276 0 015.871-2.441zm-14.35 10.918l9.616 9.615A9.914 9.914 0 0024 33c0 .25.02.496.04.742a1.5 1.5 0 00-.237.197l-3.848 3.85a8.283 8.283 0 01-11.744 0 8.283 8.283 0 010-11.744l7.355-7.358zM34 26c3.884 0 7 3.116 7 7s-3.116 7-7 7-7-3.116-7-7 3.116-7 7-7zm2.47 2.986a1.5 1.5 0 00-1.03.453l-5 5a1.5 1.5 0 102.12 2.122l5-5a1.5 1.5 0 00-1.09-2.575z" />
    </svg>
  );
}

export default SvgComponent;
