import React from "react";
import { Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      width: "fit-content",
    },
    status: {
      color: theme.palette.grey[400],
    },
    statusIcon: {
      height: 10,
      width: 10,
      marginRight: 5,
      borderRadius: 100,
    },
    on: {
      backgroundColor: "#32CD32",
    },
    off: {
      backgroundColor: "#ff0909",
    },
  })
);

export type UserStatusProps = {
  status: boolean;
  size?: "small" | "large";
  classes?: {
    root?: string;
    statusIcon?: string;
    status?: string;
  };
};

const StatusView = (props: UserStatusProps) => {
  const { status, size = "large", classes: classesProps } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, classesProps?.root)}>
      <div
        className={clsx(classes.statusIcon, classesProps?.statusIcon, {
          [classes.on]: status,
          [classes.off]: !status,
        })}
      />
      {size === "large" ? (
        <Typography className={clsx(classes.status, classesProps?.status)}>
          {status ? "online" : "offline"}
        </Typography>
      ) : null}
    </div>
  );
};

export default StatusView;
