import * as React from "react";

function SvgComponent(
  props: React.SVGProps<SVGSVGElement> & { logoColor?: string }
) {
  const { logoColor, ...svgProps } = props;
  return (
    <svg width={98} height={43} viewBox="0 0 98 43" fill="none" {...svgProps}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.886 32.175c1.803-1.435 2.705-3.57 2.705-6.406V11.15c0-1.37-1.4-1.37-1.4 0v14.62c0 1.94-.616 3.723-1.72 4.75-1.102 1.06-2.955 2.276-5.732 2.276-2.728 0-3.63-.78-4.706-1.71l-.268-.23c-1.26-1.108-2.03-3.196-2.03-5.086V11.15c0-1.37-1.426-1.37-1.426 0v14.62c0 2.82.857 4.954 2.574 6.406 1.558 1.368 3.536 2.137 5.936 2.137 2.433 0 4.456-.77 6.067-2.137zM0 21.035C0 9.417 9.417 0 21.034 0c11.617 0 21.034 9.417 21.034 21.035 0 11.616-9.417 21.033-21.034 21.033C9.417 42.068 0 32.651 0 21.035zm58.658 9.497c.84-.732 1.524-1.715 2.05-2.95.525-1.236.791-2.789.798-4.66.005-1.468-.14-2.726-.432-3.773-.292-1.047-.678-1.928-1.155-2.644-.478-.716-1.028-1.281-1.65-1.696a8.224 8.224 0 00-1.928-.958 9.511 9.511 0 00-1.992-.444 17.148 17.148 0 00-1.793-.112l-5.612-.02-.068 19.251 5.887.021c1.022.004 2.05-.149 3.084-.457a7.52 7.52 0 002.811-1.559zm-.996-17.46c1.295.608 2.33 1.416 3.104 2.425a9.135 9.135 0 011.628 3.445c.312 1.29.466 2.617.46 3.985-.005 1.529-.21 2.952-.612 4.268-.403 1.316-1.026 2.456-1.867 3.417-.842.963-1.903 1.719-3.182 2.268-1.28.548-2.799.819-4.556.813l-7.114-.025.076-21.544 7.175.025c1.962.007 3.591.315 4.888.923zm25.294 13.366v1.982c.503-.91.888-1.903 1.13-2.955l-.9.838a.636.636 0 01-.23.135zm1.433-3.633c0 .225-.007.45-.02.671l-1.413 1.313v-7.6a11.578 11.578 0 011.433 5.616zM73.978 11.72c-5.75 0-10.412 4.963-10.412 11.086S68.227 33.89 73.978 33.89c3.058 0 5.809-1.404 7.713-3.64V15.358c-1.904-2.236-4.655-3.64-7.713-3.64zm8.346.337c.348 0 .632.284.632.632v4.501a11.048 11.048 0 00-1.264-1.83v-2.67c0-.349.284-.633.632-.633zm-.633 20.95a.633.633 0 001.264 0V28.42c-.362.657-.786 1.27-1.264 1.83v2.756zm6.86-11.687l9.306 11.34a.634.634 0 01-.088.89.634.634 0 01-.89-.088l-9.257-11.28-3.534 3.283c.149-.643.245-1.307.282-1.989l11.954-11.103a.634.634 0 01.86.927l-8.633 8.02z"
        fill={logoColor ? logoColor : "url(#paint0_linear)"}
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1={126.519}
          y1={-39.9304}
          x2={146.433}
          y2={-39.9304}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0345B7" />
          <stop offset={1} stopColor="#003A8E" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgComponent;

export const WithBackgroundColor = (
  props: React.SVGProps<SVGSVGElement> & { backgroundColor?: string }
) => {
  const { backgroundColor, ...otherProps } = props;
  return (
    <div style={{ backgroundColor }}>
      <SvgComponent {...otherProps} />
    </div>
  );
};
