import React from "react";

function Icon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      data-name="Layer 3"
      viewBox="0 0 64 64"
      {...props}
    >
      <path
        fill="#c0dafc"
        d="M24.015 21.8A10.013 10.013 0 0031.8 10H58a4 4 0 014 4v16a4 4 0 01-4 4h-1v8l-10-8H28a4 4 0 01-4-4v-8.2z"
      ></path>
      <path
        fill="#d7ecff"
        d="M24.015 21.8H24V30a4 4 0 004 4h19l10 8v-8h.047V14H31.792a10.007 10.007 0 01-7.777 7.8z"
      ></path>
      <path
        fill="#78a0d4"
        d="M41.578 34H28a4 4 0 01-4-4v-3.983A30.132 30.132 0 0023 26C11.4 26 2 32.716 2 41c0 6.261 5.37 11.626 13 13.873V62l10.127-6.076h.02C35.737 55.154 44 48.767 44 41a11.7 11.7 0 00-2.422-7z"
      ></path>
      <circle cx="22" cy="12" r="10" fill="#f0ae42"></circle>
      <path
        fill="#fce797"
        d="M20.8 12.019l-.769-3.842A1.862 1.862 0 0120 7.82 1.82 1.82 0 0121.82 6h.36A1.82 1.82 0 0124 7.82a1.862 1.862 0 01-.035.357l-.765 3.842A1.22 1.22 0 0122 13a1.22 1.22 0 01-1.2-.981z"
      ></path>
      <circle cx="22" cy="17" r="2" fill="#fce797"></circle>
      <g fill="#78a0d4">
        <circle cx="35" cy="24" r="2"></circle>
        <circle cx="43" cy="24" r="2"></circle>
        <circle cx="51" cy="24" r="2"></circle>
      </g>
      <g fill="#c0dafc">
        <path d="M15 45a2 2 0 112-2 2 2 0 01-2 2zM23 45a2 2 0 112-2 2 2 0 01-2 2zM31 45a2 2 0 112-2 2 2 0 01-2 2z"></path>
      </g>
    </svg>
  );
}

export default Icon;
