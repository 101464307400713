import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import { Drug } from "@udok/lib/api/models";
import { fetchDrugs } from "@udok/lib/api/drug";
import { getToken } from "./auth";
import axios from "axios";

export type InitialState = {
  drugByID: { [patiID: string]: Drug };
  lastFetchAt: number;
};

// Reducers
const initialState: InitialState = {
  drugByID: {},
  lastFetchAt: 0,
};

class DrugSlice extends Hen<InitialState> {
  drugsLoaded(v: Drug[], n: number) {
    this.state.lastFetchAt = n;
    v.forEach((s) => {
      this.state.drugByID[String(s.drugID)] = s;
    });
  }
}

export const [Reducer, actions] = hen(new DrugSlice(initialState));

// Selectors
const mainSelector = (state: RootState) => state.drug;
const lastSelector = createSelector(mainSelector, (state) => {
  return state.lastFetchAt;
});
export const drugRepository = (state: RootState) =>
  mainSelector(state).drugByID;

export const drugListView = createSelector(mainSelector, (state) => {
  return {
    list: Object.keys(state.drugByID).map((patiID) => state.drugByID[patiID]),
  };
});

// Actions
export function loadDrugs(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    const last = lastSelector(state);
    const now = new Date().getTime();

    if (now - last < 5 * 60 * 1000) {
      return Promise.resolve();
    }

    return fetchDrugs(apiToken)
      .then((r) => {
        dispatch(actions.drugsLoaded(r, now));
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return;
        }
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}
