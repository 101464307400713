import axios from "axios";
import { deriveErrorMessage, toQueryString } from "@udok/lib/internal/util";
import { EventAlert, FilterEventAlert } from "@udok/lib/api/models";

export const fetchEventAlerts = (token: string, filter?: FilterEventAlert) => {
  const f = filter ? toQueryString(filter) : "";
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/notifications${f}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.items as EventAlert[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "ev1");
      return Promise.reject(new Error(err));
    });
};

export const addEventAlertReadDate = (token: string, notiID: string) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/notifications/${notiID}/read`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as EventAlert;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "ev2");
      return Promise.reject(new Error(err));
    });
};
