import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import {
  Doctor,
  SerarchAppointmentResult,
  SearchAppointmentsFilter,
  DoctorPublicProfile,
} from "@udok/lib/api/models";
import { fetchDoctor, fetchDoctorPublicProfile } from "@udok/lib/api/doctor";
import { searchDoctor } from "@udok/lib/api/search";
import { getToken } from "./auth";
import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

export type InitialState = {
  doctorByID: { [doctID: string]: Doctor };
  doctorsSearch: SerarchAppointmentResult[];
  doctorProfileByID: { [doctID: string]: DoctorPublicProfile };
};

// Reducers
const initialState: InitialState = {
  doctorByID: {},
  doctorsSearch: [],
  doctorProfileByID: {},
};

class Doctors extends Hen<InitialState> {
  doctorLoaded(d: Doctor) {
    this.state.doctorByID[String(d.doctID)] = d;
  }
  doctorsSearchLoaded(ds: SerarchAppointmentResult[]) {
    this.state.doctorsSearch = ds;
  }
  doctorProfileLoaded(d: DoctorPublicProfile) {
    this.state.doctorProfileByID[d.doctID] = d;
  }
}

export const [Reducer, actions] = hen(new Doctors(initialState));

// Selectors
const mainSelector = (state: RootState) => state.doctor;
const searchSelector = (state: RootState) => state.doctor.doctorsSearch;

export const makeDoctorProfileView = (props: { slug: string }) =>
  createSelector(mainSelector, (state) => {
    return {
      doctor: Object.keys(state.doctorProfileByID)
        .map((doctID) => state.doctorProfileByID[doctID])
        .find((d) => d.slug === props.slug),
    };
  });

export const doctorView = (props: { doctID?: string }) =>
  createSelector(mainSelector, (state) => {
    return {
      doctor: props?.doctID ? state.doctorByID[props.doctID] : undefined,
    };
  });

export const getDoctorSearchBySescID = (props: { sescID?: string }) =>
  createSelector([searchSelector], (doctorsSearch) => {
    const doctor = props?.sescID
      ? doctorsSearch.filter((d) =>
          Object.keys(d?.schedules ?? {}).indexOf(props.sescID ?? "")
        )?.[0]
      : undefined;
    return {
      doctor,
    };
  });

export const getDoctorSearchByDoctID = (
  state: RootState,
  props: { doctID: string }
) =>
  createSelector([searchSelector, getToken], (doctorsSearch) => {
    const doctor = doctorsSearch.filter((d) => d?.doctID === props.doctID)?.[0];
    return {
      doctor,
    };
  });

export const getDoctorSearch = createSelector(
  [searchSelector, getToken],
  (doctorsSearch) => {
    return { doctorsSearch };
  }
);

// Actions
export function fetchOneDoctor(doctID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchDoctor(apiToken, doctID)
      .then((r) => {
        dispatch(actions.doctorLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function fetchCachedDoctor(doctID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const doctorExist = Boolean(state.doctor.doctorByID[doctID]);
    if (doctorExist) {
      return Promise.resolve();
    }
    return dispatch(fetchOneDoctor(doctID));
  };
}

export function fetchSearchDoctors(
  filter: SearchAppointmentsFilter
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    return searchDoctor(filter)
      .then((r) => {
        dispatch(actions.doctorsSearchLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function fetchCachedDoctorPublicProfile(
  slug: string
): AppThunk<Promise<DoctorPublicProfile>> {
  return async (dispatch, getState) => {
    const state = getState();
    const doctID = Object.keys(state.doctor.doctorProfileByID).find(
      (d) => state.doctor.doctorProfileByID[d].slug === slug
    );
    if (!!doctID) {
      return Promise.resolve(state.doctor.doctorProfileByID[doctID]);
    }
    return fetchDoctorPublicProfile(slug)
      .then((r) => {
        dispatch(actions.doctorProfileLoaded(r));
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}
