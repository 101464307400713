import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import Logo from "@udok/lib/components/Illustrations/UdokLogoLarge";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    underlay: {
      flex: 1,
      backgroundColor: theme.palette.primary.main,
      background: `url(/static/img/signinundererlay.png) center center no-repeat`,
      width: 480,
      minHeight: 400,
      backgroundSize: "480px 100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    text: {
      color: "#fff",
      textAlign: "center",
    },
    logo: {
      margin: theme.spacing(4),
    },
    logoContainer: {
      backgroundColor: theme.palette.neutral.lightest,
      height: 100,
      border: `4px solid ${theme.palette.primary.main}`,
      borderRadius: 15,
      marginBottom: 15,
    },
  })
);

export type CardLogoTextProps = {
  className?: string;
  text?: string;
  logoSRC?: string;
  logoALT?: string;
  classes?: {
    underlay?: string;
    logo?: string;
    text?: string;
  };
};

const defaultText = `Consultório Digital UDOK`;
export default (props: CardLogoTextProps) => {
  const {
    className,
    text = defaultText,
    logoSRC,
    logoALT,
    classes: classesProps,
  } = props;
  const classes = useStyles();
  return (
    <div className={clsx(classes.underlay, classesProps?.underlay, className)}>
      <CustomLogo
        src={logoSRC}
        alt={logoALT}
        logoProps={{
          width: 300,
          height: 100,
          logoColor: "#ffffff",
        }}
        classes={{
          logo: classesProps?.logo,
        }}
      />
      <Typography
        variant="h4"
        className={clsx(classes.text, classesProps?.text)}
      >
        {text}
      </Typography>
    </div>
  );
};

type CustomLogoProps = {
  src?: string;
  alt?: string;
  classes?: { logo?: string };
  logoProps?: {
    width?: number;
    height?: number;
    logoColor?: string;
  };
};

export const CustomLogo = (props: CustomLogoProps) => {
  const { src, alt, logoProps, classes: classesProps } = props;
  const classes = useStyles();

  if (src) {
    return (
      <div className={clsx(classes.logoContainer, classesProps?.logo)}>
        <img
          style={{ borderRadius: 10 }}
          src={src}
          height={logoProps?.height}
          alt={alt ?? "Udok"}
        />
      </div>
    );
  }
  return (
    <Logo
      logoColor={logoProps?.logoColor}
      width={logoProps?.width}
      height={logoProps?.height}
      className={clsx(classes.logo, classesProps?.logo)}
    />
  );
};
