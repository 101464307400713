import axios from "axios";
import { deriveErrorMessage, toQueryString } from "@udok/lib/internal/util";
import {
  FileData,
  AxiosProgressEvent,
  signedDownloadURL,
  downloadUrlParams,
} from "./models";
import { makeItemFetch } from "@udok/lib/api/axios";

export const fetchFile = async (token: string, fileID: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/private-files/${fileID}/download`, {
      responseType: "blob",
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data as unknown;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "prf1");
      return Promise.reject(err);
    });
};

export const fetchFileMetadata = async (token: string, fileID: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/private-files/${fileID}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as FileData;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "prf2");
      return Promise.reject(err);
    });
};

export async function uploadFile(file: File, apiToken: string) {
  var formData = new FormData();
  formData.append("file", file);
  return axios
    .post(`${process.env.REACT_APP_API_PATH}/private-files`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: apiToken,
      },
    })
    .then((r) => {
      return r.data.data.item as FileData;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "prf3");
      return Promise.reject(err);
    });
}

export async function deleteFile(apiToken: string, fileID: string) {
  return axios
    .delete(`${process.env.REACT_APP_API_PATH}/private-files/${fileID}`, {
      headers: { Authorization: apiToken },
    })
    .then((r) => {
      return r.data.data.item as FileData;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "prf3");
      return Promise.reject(err);
    });
}

export type FileUploadResult = { filename: string };
export const uploadOneFile = async (
  token: string,
  file: File,
  saveTo?: "s3" | "local",
  cb?: (e: AxiosProgressEvent) => void
) => {
  const data = new FormData();
  data.append("file", file);
  data.append("saveTo", saveTo ?? "local");

  return axios
    .post(`${process.env.REACT_APP_API_PATH}/files`, data, {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: cb,
    })
    .then((r) => {
      return r.data.data.item as FileUploadResult;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "cli3");
      return Promise.reject(err);
    });
};

export const getDownloadLink = (token: string, data: downloadUrlParams) => {
  return makeItemFetch<signedDownloadURL>({
    url: `/api/files/download-link${toQueryString(data)}`,
    errLabel: "prf4",
    token,
  });
};
