import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={512} height={512} viewBox="0 0 512 512" {...props}>
      <g xmlns="http://www.w3.org/2000/svg" data-name="Filled stroke cut Ex">
        <path
          d="M24 288v44.457a10 10 0 007.436 9.666l103.728 27.517a24 24 0 0146.883 10.3l256.211 65.57a10 10 0 0012.181-7.263L488 288z"
          fill="#e4f3f4"
          data-original="#fac850"
        />
        <path
          d="M206.634 64a24 24 0 11-45.268 0H34a10 10 0 00-10 10v238h136a24 24 0 0148 0h270a10 10 0 0010-10V74a10 10 0 00-10-10z"
          fill="#64CACC"
          data-original="#fa5d3f"
        />
        <path
          d="M176 152h16v16h-16zM176 184h16v16h-16zM176 216h16v16h-16z"
          fill="#0b5956"
          data-original="#000000"
        />
        <path
          d="M488 312H344l144-128z"
          fill="#e4f3f4"
          data-original="#fac850"
        />
        <path
          d="M478 56H206.634a8 8 0 00-7.542 10.666A16 16 0 01185.853 87.9a16.017 16.017 0 01-16.944-21.231A8 8 0 00161.366 56H34a18.021 18.021 0 00-18 18v22h16V74a2 2 0 012-2h118a31.957 31.957 0 0024 30.97V136h16v-33.007a32.2 32.2 0 0023.756-27A32.945 32.945 0 00216 72h262a2 2 0 012 2v106.407L340.958 304h-125.97A32.118 32.118 0 00192 281.013V248h-16v33.013A32.118 32.118 0 00153.012 304H32V112H16v220.457a18.017 18.017 0 0013.385 17.4l103.728 27.518a8 8 0 009.865-6.017 16 16 0 1131.255 6.865 8 8 0 005.83 9.466l256.212 65.57a18.127 18.127 0 004.5.57 18.024 18.024 0 0017.43-13.642l25.556-102.247-15.522-3.88-25.561 102.24a2 2 0 01-2.436 1.453L190.605 373.87a32.011 32.011 0 00-60.4-13.824L33.487 334.39A2 2 0 0132 332.457V320h128a8 8 0 008-8 16 16 0 0132 0 8 8 0 008 8h280a8 8 0 008-8V74a18.021 18.021 0 00-18-18zm2 246a2 2 0 01-2 2H365.042L480 201.815z"
          fill="#0b5956"
          data-original="#000000"
        />
        <path
          d="M232 96h204a20 20 0 0120 20v20H252a20 20 0 01-20-20V96z"
          fill="#e4f3f4"
          data-original="#fac850"
        />
        <path
          d="M456 144H252a28.032 28.032 0 01-28-28V96a8 8 0 018-8h204a28.032 28.032 0 0128 28v20a8 8 0 01-8 8zm-216-40v12a12.013 12.013 0 0012 12h196v-12a12.013 12.013 0 00-12-12zM224 168h72v16h-72zM312 168h144v16H312zM224 208h120v16H224zM360 208h48v16h-48zM224 248h16v40h-16zM256 248h16v40h-16zM288 248h16v40h-16zM320 248h16v40h-16z"
          fill="#0b5956"
          data-original="#000000"
        />
        <circle
          cx={72}
          cy={120}
          fill="#e4f3f4"
          r={16}
          data-original="#fac850"
        />
        <path
          d="M72 144a24 24 0 1124-24 24.027 24.027 0 01-24 24zm0-32a8 8 0 108 8 8.009 8.009 0 00-8-8z"
          fill="#0b5956"
          data-original="#000000"
        />
        <circle
          cx={136}
          cy={176}
          fill="#e4f3f4"
          r={16}
          data-original="#fac850"
        />
        <path
          d="M136 200a24 24 0 1124-24 24.027 24.027 0 01-24 24zm0-32a8 8 0 108 8 8.009 8.009 0 00-8-8z"
          fill="#0b5956"
          data-original="#000000"
        />
        <path
          d="M55.834 140h96.333v16H55.834z"
          transform="rotate(-48.37 104.06 148.083)"
          fill="#0b5956"
          data-original="#000000"
        />
        <path
          d="M136 264h24v16h-24zM48 264h72v16H48zM48 232h16v16H48zM80 232h80v16H80zM424 272h40v16h-40z"
          fill="#0b5956"
          data-original="#000000"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
