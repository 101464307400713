import React from "react";
import { connect } from "react-redux";
import { getToken } from "ducks/auth";
import { RouteComponentProps } from "react-router";
import SigninContainer from "containers/Auth/SigninContainer";
import moment from "moment";
moment.locale("pt-br");

export interface SecureContainerProps extends RouteComponentProps<void> {
  token: any;
  onLogin?: (r: any) => any;
}

export class SecureContainer extends React.Component<SecureContainerProps> {
  render() {
    let exp = this.props.token?.payload?.exp ?? 0;
    if (moment.utc().unix() < exp) {
      return this.props.children;
    }
    return <SigninContainer onLogin={this.props.onLogin} />;
  }
}

export default connect(
  getToken as (state: any) => Pick<SecureContainerProps, "token">,
  (dispatch: any) => ({})
)(SecureContainer) as any;
