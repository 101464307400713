import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height={512}
      viewBox="0 0 511.999 511.999"
      width={512}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M165.005 291.011H15c-8.284 0-15 6.716-15 15v140c0 8.284 6.716 15 15 15h150.005c8.284 0 15-6.716 15-15v-140c0-8.284-6.716-15-15-15zM30 431.011v-110h101.947l-37.676 43.057-8.665-8.665c-5.857-5.857-15.355-5.857-21.213 0-5.858 5.858-5.858 15.355 0 21.213l20.002 20.002a15.001 15.001 0 0021.894-.729l43.715-49.957v85.079zM496.999 481.02H285.012a14.997 14.997 0 01-12.48-6.68l-60.004-90.009a15 15 0 010-16.641l60.004-90.009a14.998 14.998 0 0112.48-6.68h211.987c8.284 0 15 6.716 15 15V466.02c0 8.284-6.716 15-15 15zm-203.959-30h188.959V301.003H293.04l-50.005 75.009z" />
      <path d="M436.993 361.008h-91.976c-8.284 0-15-6.715-15-15 0-8.284 6.716-15 15-15h91.976c8.284 0 15 6.716 15 15 0 8.285-6.716 15-15 15zM436.993 421.014H325.016c-8.284 0-15-6.716-15-15s6.716-15 15-15h111.978c8.284 0 15 6.716 15 15-.001 8.284-6.717 15-15.001 15zM496.999 240.997H285.012a14.997 14.997 0 01-12.48-6.68l-60.004-90.009a14.998 14.998 0 010-16.64l60.004-90.01a14.998 14.998 0 0112.48-6.68h211.987c8.284 0 15 6.716 15 15v180.018c0 8.285-6.716 15.001-15 15.001zm-203.959-30h188.959V60.979H293.04l-50.005 75.009z" />
      <path d="M436.993 120.985h-91.976c-8.284 0-15-6.716-15-15s6.716-15 15-15h91.976c8.284 0 15 6.716 15 15s-6.716 15-15 15zM436.993 180.991H325.016c-8.284 0-15-6.716-15-15s6.716-15 15-15h111.978c8.284 0 15 6.716 15 15-.001 8.284-6.717 15-15.001 15zM165.005 50.988H15c-8.284 0-15 6.716-15 15v140c0 8.284 6.716 15 15 15h150.005c8.284 0 15-6.716 15-15v-140c0-8.284-6.716-15-15-15zM30 190.988v-110h101.947l-37.676 43.057-8.665-8.665c-5.857-5.857-15.355-5.857-21.213 0s-5.858 15.355 0 21.213l20.002 20.002a15.001 15.001 0 0021.894-.729l43.715-49.957v85.079z" />
    </svg>
  );
}

export default SvgComponent;
