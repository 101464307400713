import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1.0625em"
      viewBox="0 0 16 17"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0)">
        <path d="M7 .601c-.919 0-1.678.707-1.78 1.6H3.8c-.987 0-1.8.813-1.8 1.8v10.8c0 .987.813 1.8 1.8 1.8H13c.987 0 1.8-.813 1.8-1.8v-10.8c0-.987-.813-1.8-1.8-1.8h-1.42A1.804 1.804 0 009.8.601H7zm0 1.2h2.8c.339 0 .6.261.6.6 0 .339-.261.6-.6.6H7a.591.591 0 01-.6-.6c0-.339.261-.6.6-.6zm-3.2 1.6h1.708c.324.48.874.8 1.492.8h2.8c.618 0 1.167-.32 1.492-.8H13c.339 0 .6.261.6.6v10.8c0 .339-.261.6-.6.6H3.8a.591.591 0 01-.6-.6v-10.8c0-.339.261-.6.6-.6zm4.59 2.391a.6.6 0 00-.59.61v.6h-.6a.6.6 0 100 1.2h.6v.6a.6.6 0 101.2 0v-.6h.6a.6.6 0 100-1.2H9v-.6a.6.6 0 00-.61-.61zm-2.59 4.41a.6.6 0 100 1.2H11a.601.601 0 00.232-1.158.6.6 0 00-.232-.043H5.8zm0 2a.6.6 0 100 1.2H11a.601.601 0 00.232-1.158.6.6 0 00-.232-.043H5.8z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" transform="translate(0 .601)" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
