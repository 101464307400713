import React from "react";
import { connect } from "react-redux";
import { connectWS, fetchActivities, fetchActivitiesGroup } from "ducks/socket";

const Register = (p: {
  connectWS: () => Promise<void>;
  fetchActivities: () => void;
  fetchActivitiesGroup: () => void;
}) => {
  const { connectWS, fetchActivities, fetchActivitiesGroup } = p;
  React.useEffect(() => {
    connectWS();
  }, [connectWS]);

  React.useEffect(() => {
    const t = setInterval(() => {
      fetchActivities();
      fetchActivitiesGroup();
    }, 12000);
    return () => {
      clearInterval(t);
    };
  }, [fetchActivities, fetchActivitiesGroup]);
  return null;
};

export default connect(null, (dispatch: any) => ({
  connectWS: () => dispatch(connectWS()),
  fetchActivities: () => dispatch(fetchActivities()),
  fetchActivitiesGroup: () => dispatch(fetchActivitiesGroup()),
}))(Register);
