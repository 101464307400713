import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 53 53"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M25.669 4.227h-8.207v-2.25c0-.725.589-1.314 1.313-1.314h5.58c.725 0 1.314.59 1.314 1.314zm-7.207-1h6.207v-1.25a.314.314 0 0 0-.314-.314h-5.58a.314.314 0 0 0-.313.314z"
      fill="#474646"
    />
    <path
      d="M40.95 9.098V47.24a5.096 5.096 0 0 1-5.097 5.096H7.278a5.096 5.096 0 0 1-5.096-5.096V9.098a5.096 5.096 0 0 1 5.096-5.096h28.575a5.096 5.096 0 0 1 5.096 5.096z"
      fill="#7979f7"
    />
    <path
      d="M40.95 9.097v38.14c0 2.82-2.28 5.1-5.1 5.1H7.28c-1.87 0-3.51-1-4.39-2.51.85.64 1.91 1.01 3.05 1.01h28.57c2.82 0 5.1-2.28 5.1-5.09V7.597c0-.93-.25-1.81-.7-2.57 1.24.92 2.04 2.41 2.04 4.07z"
      fill="#5e5eef"
    />
    <path
      d="M8.962 49.183a3.425 3.425 0 0 1-3.426-3.425V10.582a3.426 3.426 0 0 1 3.427-3.426h25.205a3.426 3.426 0 0 1 3.426 3.426v35.176a3.425 3.425 0 0 1-3.425 3.425z"
      fill="#fff"
    />
    <path
      d="M37.59 29.887v15.87c0 1.89-1.53 3.43-3.42 3.43H8.96c-1.89 0-3.42-1.54-3.42-3.43v-1.31c6.21.63 13.28-.75 19.94-4.32 5.03-2.7 9.14-6.28 12.11-10.24z"
      fill="#e4e3ff"
    />
    <path
      d="M30.51 4.945v2.952c0 .997-.807 1.804-1.803 1.804H14.424a1.804 1.804 0 0 1-1.804-1.804V4.945c0-.996.808-1.804 1.804-1.804h14.283c.996 0 1.804.808 1.804 1.804z"
      fill="#474646"
    />
    <path
      d="M30.51 4.947v2.95c0 1-.81 1.8-1.8 1.8H14.42c-.69 0-1.29-.39-1.58-.96.19.07.39.11.6.11h14.28c1 0 1.81-.81 1.81-1.8v-2.95c0-.31-.08-.6-.22-.85.7.24 1.2.91 1.2 1.7z"
      fill="#1b1b1b"
    />
    <path d="M21.421 18.223h1v21.118h-1z" fill="#474646" />
    <path
      d="M32.786 29.682h-1v-4.37c0-.714-.58-1.294-1.294-1.294h-9.137v-1h9.137a2.297 2.297 0 0 1 2.294 2.294z"
      fill="#474646"
    />
    <path
      d="M12.055 29.682h-1v-4.37a2.297 2.297 0 0 1 2.294-2.294h9.136v1H13.35c-.714 0-1.294.58-1.294 1.294z"
      fill="#474646"
    />
    <path
      d="M26.609 16.168v3.074a.899.899 0 0 1-.899.899h-7.58a.899.899 0 0 1-.898-.899v-3.074c0-.496.402-.898.899-.898h7.58c.495 0 .898.402.898.898z"
      fill="#b75f25"
    />
    <path
      d="M26.609 27.802v3.073a.899.899 0 0 1-.899.899h-7.58a.899.899 0 0 1-.898-.899v-3.073c0-.497.402-.899.899-.899h7.58c.495 0 .898.402.898.899z"
      fill="#f9b938"
    />
    <path
      d="M26.609 37.435v3.074a.899.899 0 0 1-.899.898h-7.58a.899.899 0 0 1-.898-.898v-3.074c0-.496.402-.899.899-.899h7.58c.495 0 .898.403.898.899z"
      fill="#5bc658"
    />
    <path
      d="M34.975 27.802v3.073a.899.899 0 0 1-.899.899h-3.58a.899.899 0 0 1-.898-.899v-3.073c0-.497.402-.899.899-.899h3.58c.495 0 .898.402.898.899z"
      fill="#f4b37d"
    />
    <path
      d="M14.243 27.802v3.073a.899.899 0 0 1-.899.899h-3.58a.899.899 0 0 1-.898-.899v-3.073c0-.497.402-.899.899-.899h3.58c.496 0 .898.402.898.899z"
      fill="#8666c6"
    />
    <path
      d="M26.61 16.167v3.07c0 .5-.4.9-.9.9h-7.58c-.5 0-.9-.4-.9-.9v-.37h7.54c.49 0 .89-.4.89-.9v-2.7h.05c.5 0 .9.4.9.9z"
      fill="#aa5821"
    />
    <path
      d="M26.61 27.797v3.08c0 .49-.4.9-.9.9h-7.58c-.5 0-.9-.41-.9-.9v-.38h7.54c.49 0 .89-.4.89-.89v-2.7h.05c.5 0 .9.4.9.89z"
      fill="#e5a320"
    />
    <path
      d="M26.61 37.437v3.07c0 .5-.4.9-.9.9h-7.58c-.5 0-.9-.4-.9-.9v-.37h7.54c.49 0 .89-.4.89-.9v-2.7h.05c.5 0 .9.4.9.9z"
      fill="#49a247"
    />
    <path
      d="M34.97 27.797v3.08c0 .49-.4.9-.89.9H30.5c-.5 0-.9-.41-.9-.9v-.38h3.53c.5 0 .9-.4.9-.89v-2.7h.05c.49 0 .89.4.89.89z"
      fill="#f2a764"
    />
    <path
      d="M14.24 27.797v3.08c0 .49-.4.9-.9.9H9.76a.9.9 0 0 1-.89-.9v-.38h3.53c.5 0 .9-.4.9-.89v-2.7h.04c.5 0 .9.4.9.89z"
      fill="#7553aa"
    />
    <path
      d="M22.312 4.84a.502.502 0 0 1-.502.503h-4.384a.502.502 0 0 1 0-1.004h4.384c.277 0 .502.224.502.502zM16.078 4.84a.502.502 0 0 1-.503.503h-.663a.502.502 0 0 1 0-1.004h.663c.278 0 .503.224.503.502zM22.47 16.619c0 .19-.154.343-.343.343h-1.999a.344.344 0 0 1 0-.686h1.999c.19 0 .343.153.343.343zM19.205 16.619c0 .19-.153.343-.343.343h-.454a.344.344 0 0 1 0-.686h.454c.19 0 .343.153.343.343zM3.863 34.792a.502.502 0 0 1-.502-.502v-9.384a.502.502 0 0 1 1.004 0v9.384a.502.502 0 0 1-.502.502zM3.863 23.557a.502.502 0 0 1-.502-.502v-.663a.502.502 0 0 1 1.004 0v.663a.502.502 0 0 1-.502.502zM22.362 28.128c0 .19-.154.344-.344.344H20.02a.344.344 0 0 1 0-.687h1.998c.19 0 .344.154.344.343zM19.097 28.128c0 .19-.154.344-.343.344H18.3a.344.344 0 0 1 0-.687h.454c.19 0 .343.154.343.343zM22.362 37.748c0 .19-.154.343-.344.343H20.02a.344.344 0 0 1 0-.687h1.998c.19 0 .344.154.344.344zM19.097 37.748c0 .19-.154.343-.343.343H18.3a.344.344 0 0 1 0-.687h.454c.19 0 .343.154.343.344zM32.968 27.967a.315.315 0 0 1-.315.315h-.917a.315.315 0 0 1 0-.63h.917c.174 0 .315.14.315.315zM30.89 27.967a.315.315 0 0 1-.316.315h-.208a.315.315 0 0 1 0-.63h.208c.174 0 .316.14.316.315zM12.247 27.967a.315.315 0 0 1-.315.315h-.917a.315.315 0 0 1 0-.63h.917c.174 0 .315.14.315.315zM10.168 27.967a.315.315 0 0 1-.315.315h-.209a.315.315 0 0 1 0-.63h.209c.174 0 .315.14.315.315z"
      fill="#fff"
      opacity={0.4}
    />
    <path
      d="m36.15 34.061 7.015-15.926 5.831 2.568-7.015 15.927z"
      fill="#fc6559"
    />
    <path
      d="m48.994 20.68-7.01 15.932-5.835-2.566.673-1.529 1.471.647a3.153 3.153 0 0 0 4.156-1.616l5.068-11.517z"
      fill="#f05543"
    />
    <path
      d="m49.444 19.656 1.104-2.508a3.187 3.187 0 1 0-5.834-2.567L43.61 17.09z"
      fill="#474646"
    />
    <path
      d="m50.548 17.147-1.104 2.509-5.833-2.567.565-1.285 3.583 1.576a1.493 1.493 0 0 0 1.968-.765l.503-1.142a3.1 3.1 0 0 0 .255-1.022c.405.817.458 1.798.063 2.696z"
      fill="#2d2c2c"
    />
    <path
      d="M41.995 17.473c-.288.654.009 1.417.662 1.704l5.893 2.592a1.293 1.293 0 1 0 1.041-2.366l-5.892-2.592a1.293 1.293 0 0 0-1.704.662z"
      fill="#f9b938"
    />
    <path
      d="M50.251 21.106c-.285.65-1.05.948-1.7.663l-5.896-2.59a1.28 1.28 0 0 1-.764-1.121c.13.253.337.453.611.574l5.897 2.59a1.293 1.293 0 0 0 1.7-.663c.08-.183.12-.373.106-.565.182.331.211.737.046 1.112z"
      fill="#ecb021"
    />
    <path
      d="M41.983 36.612 38.4 40.145l-1.907 1.888a.372.372 0 0 1-.633-.278l.101-2.677.19-5.032z"
      fill="#f4b37d"
    />
    <path
      d="m38.399 40.145-1.907 1.888a.372.372 0 0 1-.633-.278l.101-2.677a2.386 2.386 0 0 1 2.439 1.067z"
      fill="#474646"
    />
    <path
      d="M40.613 27.912a.392.392 0 0 1-.2-.516l1.777-4.033a.392.392 0 0 1 .717.315l-1.778 4.033a.392.392 0 0 1-.516.201zM43.267 21.886a.392.392 0 0 1-.2-.516l.248-.565a.392.392 0 0 1 .717.315l-.249.566a.392.392 0 0 1-.516.2zM46.066 18.781a.266.266 0 0 1-.35.137l-1.304-.573a.266.266 0 0 1 .214-.486l1.304.572a.266.266 0 0 1 .136.35zM43.866 17.815a.266.266 0 0 1-.35.136l-.321-.14a.266.266 0 0 1 .213-.487l.322.141a.266.266 0 0 1 .136.35z"
      fill="#fff"
      opacity={0.4}
    />
  </svg>
);

export default SvgComponent;
