import React from "react";
import Icons from "../Icon";

import {
  Dialog,
  DialogProps,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useDialogUrlHash } from "@udok/lib/hooks/layout";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    closeButton: {
      marginLeft: theme.spacing(1),
    },
  })
);

type FullscreenDialogProps = Omit<DialogProps, "title"> & {
  title?: React.ReactNode;
  actionItems?: React.ReactNode;
  closeOnGoBack?: boolean;
};

const FullscreenDialog = (props: FullscreenDialogProps) => {
  const {
    open,
    onClose,
    children,
    title,
    actionItems,
    closeOnGoBack,
    ...other
  } = props;
  const classes = useStyles();

  useDialogUrlHash(
    open,
    React.useCallback(() => {
      onClose?.({}, "backdropClick");
    }, [onClose]),
    !closeOnGoBack
  );

  return (
    <Dialog fullScreen open={open} {...other}>
      <AppBar className={classes.appBar}>
        <Toolbar
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <span>{title ? <Typography>{title}</Typography> : null}</span>
          <div style={{ display: "flex" }}>
            {actionItems}
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => onClose?.(e as any, "backdropClick")}
              aria-label="close"
              className={classes.closeButton}
            >
              <Icons.Close />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {children}
    </Dialog>
  );
};

export default FullscreenDialog;
